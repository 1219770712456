import React, { useEffect, useState } from 'react'
import _ from "lodash"

export default function Pagination(
  {meta, onPageSelected}
) {

  const [pages, setPages] = useState([]);

  useEffect(() => {
    const thePages = []
    if (meta) {
      if (meta.last_page <= 10 || meta.current_page < 10) {
        const lastPageToShow = meta.last_page <= 10 ? meta.last_page : 10; 
        for (let index = 1; index <= lastPageToShow; index++) {
          thePages.push(
            index
          );
        }
      } else {
        let lastPageToShow = meta.last_page;
        if ((meta.currentPage + 5) < meta.last_page) {
          lastPageToShow = meta.currentPage + 5;
        }
        const initPageToShow = meta.current_page - 5;
        for (let index = initPageToShow; index <= lastPageToShow; index++) {
          thePages.push(
            index
          );
        }
      }
      setPages(thePages);
    }
  }, [meta]);

  return (
    <div>
      <nav aria-label="Page navigation example">
        <ul className="inline-flex -space-x-px text-base h-10">
          {
            meta && meta.current_page > 1 &&
            <li>
              <button
                className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-primary text-2xl bg-transparent"
                onClick={() => onPageSelected(parseInt(meta.current_page) - 1)}
              >
                &lt;
              </button>
            </li>
          }
          
          {_.map((pages), (page) => (
            <li key={page}>
              <button
                className={`flex items-center justify-center px-4 h-10 leading-tight ${meta.current_page === page ? 'text-primary' : 'bg-transparent text-black'} `}
                onClick={() => onPageSelected(parseInt(page))}
              >
                {page}
              </button>
            </li>
          ))}

          {
            meta && meta.last_page > meta.current_page &&
            <li>
              <button
                href="#"
                className="flex items-center justify-center px-4 h-10 leading-tight text-primary text-2xl bg-transparent"
                onClick={() => onPageSelected(parseInt(meta.current_page) + 1)}
              >
                &gt;
              </button>
            </li>
          }
        </ul>
      </nav>
    </div>
  )
}
