import React from 'react'
import "./Loading.css"
import loading from '../../assets/images/loader.gif'

const Loading = () => {
  return (
    <div className='Loading'>
      <div className="overlay">
        <div className="loader">
          <img src={loading} alt='loading...' />
        </div>
      </div>
    </div>
  );
};

export default Loading;
