import React, { useState } from 'react'
import "./SectionForm.css"
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { saveSection, updateSection } from '../../../../../../../services/SectionsService';
import { ToastContainer, toast } from "react-toastify";
import Loading from '../../../../../../../components/loading/Loading';

export default function SectionForm(
  {onSuccess, sectionToEdit}
) {

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const {
      register,
      handleSubmit,
      formState: {
        errors
      },
      watch,
      reset
    } = useForm(
      { defaultValues: sectionToEdit }
    )

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const params = {
      name: data.name,
      description: data.description,
      min_price: data.minPrice,
      max_price: data.maxPrice
    };
    try {
      let response = null;
      if (!sectionToEdit) {
        response = await saveSection(params);
      } else {
        response = await updateSection(sectionToEdit.id, params);
      }
      setLoading(false);
      if (response.data && response.data.status === 'success') {
        toast.success(
          sectionToEdit ? t('sectionPage.update') : t('sectionPage.save')
        );
        reset(
          {
            name: '',
            description: '',
            minPrice: '',
            maxPrice: '',
          }
        );
        onSuccess();
      } else {
        toast.error(
          response
        );
      }
    } catch (error) {
      toast.error(
        error.message
      );
    }
  });

  return (
    <div className='SectionForm flex justify-center'>
      { loading && <Loading /> }
      <div className='w-11/12 lg:w-10/12 mt-4 bg-white rounded-md p-0 lg:p-8 text-dark'>
        <div className='text-left'>
          { sectionToEdit ? t('sectionPage.edit') : t('sectionPage.add') }
        </div>
        <div className='border-b border-gray h-4' >&nbsp;</div>
        <div className='mt-4 text-light'>
          <form onSubmit={onSubmit}>
            <div className='w-full'>
              <label
                htmlFor='name'
                className='label'
              >
                {t('sectionFormPage.name')}
              </label>
              <input
                type="text"
                id="name"
                className="p-2 border border-light rounded-md w-full text-black text-sm lg:text-base"
                {
                  ...register(
                    'name', {
                      required: {
                        value: true,
                        message: t('sectionFormPage.errors.name.required')
                      },
                      minLength: {
                        value: 5,
                        message: t('sectionFormPage.errors.name.minLength')
                      }
                    }
                  )
                }
              />
              {
                errors.name && 
                <div className='error-message'>
                  {errors.name.message}
                </div>
              }  
            </div>
            <div className='w-full mt-2'>
              <label
                htmlFor='description'
                className='label'
              >
                {t('sectionFormPage.description')}
              </label>
              <input
                type="text"
                id="description"
                className="p-2 border border-light rounded-md w-full text-black text-sm lg:text-base"
                {
                  ...register(
                    'description', {
                      required: {
                        value: true,
                        message: t('sectionFormPage.errors.description.required')
                      },
                      minLength: {
                        value: 5,
                        message: t('sectionFormPage.errors.description.minLength')
                      }
                    }
                  )
                }
              />
              {
                errors.description && 
                <div className='error-message'>
                  {errors.description.message}
                </div>
              }
            </div>
            <div className='w-full mt-2'>
              <label
                htmlFor='minPrice'
                className='label'
              >
                {t('sectionFormPage.minPrice')}
              </label>
              <input
                type="number"
                id="minPrice"
                className="p-2 border border-light rounded-md w-full text-black text-sm lg:text-base"
                {
                  ...register(
                    'minPrice', {
                      required: {
                        value: true,
                        message: t('sectionFormPage.errors.minPrice.required')
                      }
                    }
                  )
                }
              />
              {
                errors.minPrice && 
                <div className='error-message'>
                  {errors.minPrice.message}
                </div>
              }
            </div>
            <div className='w-full mt-2'>
              <label
                htmlFor='maxPrice'
                className='label'
              >
                {t('sectionFormPage.maxPrice')}
              </label>
              <input
                type="number"
                id="maxPrice"
                className="p-2 border border-light rounded-md w-full text-black text-sm lg:text-base"
                {
                  ...register(
                    'maxPrice', {
                      required: {
                        value: true,
                        message: t('sectionFormPage.errors.maxPrice.required')
                      },
                      validate: (value) => {
                        return parseFloat(watch('minPrice')) <= parseFloat(watch('maxPrice')) ? true : t('sectionFormPage.errors.maxPrice.validate')
                      }
                    }
                  )
                }
              />
              {
                errors.maxPrice && 
                <div className='error-message'>
                  {errors.maxPrice.message}
                </div>
              }
            </div>
            
            <div className='mt-2 flex justify-center'>
              <div className='w-1/2'>
                <button
                  className='pt-1 pb-1 bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo text-white rounded-sm  w-full'
                >
                  {t('global.save')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
