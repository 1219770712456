const RoundedCheck = ({size='120', color="#5EC394"}) => {
    return (
        <>
        <svg width={size} height={size} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M120 60C120 93.1371 93.1371 120 60 120C26.863 120 0 93.1371 0 60C0 26.863 26.863 0 60 0C93.1371 0 120 26.863 120 60ZM25.2688 59.3512L51.8337 86.5646L94.7286 42.893L85.1391 33.3035L51.8337 67.3856L34.8583 49.7613L25.2688 59.3512Z" fill={color}/>
        </svg>
        </>
    )
}

export default RoundedCheck;