export const Manzana14Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1910_4)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M514.359 502.414L543.086 572.692L506.664 586.543L479.477 516.265L514.359 502.414Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M473.321 599.885L506.664 586.548L479.477 516.27L445.107 529.607L473.321 599.885Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M473.835 599.887C478.349 601.939 511.282 620.577 527.185 629.64L571.814 585.01L553.86 568.082L473.835 599.887Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M626.188 538.325L574.377 478.307L550.267 488.053L578.48 557.818L626.188 538.325Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M542.573 572.181L578.994 557.818L550.267 488.053L514.359 502.416L542.573 572.181Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M626.189 538.322L642.604 556.79L594.384 606.549L553.859 567.562L626.189 538.322Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M571.814 585.008L606.183 617.839L569.249 657.851L526.672 629.637L571.814 585.008Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M653.89 660.416C671.947 633.741 701.768 625.02 714.421 623.994L672.357 585.008L625.676 635.28L653.89 660.416Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M642.605 556.275L672.87 585.515L625.163 636.3L594.385 606.547L642.605 556.275Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M637.987 714.787C635.114 690.164 647.392 668.106 653.89 660.411L606.183 617.834L569.248 657.846L637.987 714.787Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M445.5 530L474 600C470 597.6 407.5 569.333 376 555.5L445.5 530Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1910_4" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1910_4" transform="matrix(0.00144289 0 0 0.00187011 -3.4076 -2.56392)"/>
        </pattern>
        <image id="image0_1910_4" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}