export const Manzana26Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="942" viewBox="0 0 1219 942" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="942" fill="url(#pattern0_1881_8852)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M335.492 528.516C327.84 539.174 327.749 560.058 328.66 569.168L355.305 573.609L363.846 533.981L335.492 528.516Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('25'))} d="M328.66 568.826L396.983 581.466L387.418 624.851L374.095 622.118C342.393 615.012 330.596 583.629 328.66 568.826Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M385.709 579.075L415.429 585.224L423.628 544.231L393.566 539.106L385.709 579.075Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M363.844 533.981L393.565 539.106L385.708 579.074L354.962 573.609L363.844 533.981Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('24'))} d="M387.074 624.849L415.77 630.656L424.993 586.93L396.981 581.464L387.074 624.849Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('23'))} d="M415.771 630.657L443.442 635.781L453.349 592.397L424.995 587.272L415.771 630.657Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M415.43 584.881L445.492 591.03L453.69 550.379L423.628 544.229L415.43 584.881Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M475.553 596.495L505.957 602.986L514.155 561.992L484.094 556.185L475.553 596.495Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('22'))} d="M443.441 635.781L472.137 641.247L481.702 597.862L453.348 592.396L443.441 635.781Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M445.492 591.031L475.554 596.497L484.094 556.186L453.69 550.379L445.492 591.031Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M505.957 602.646L535.335 609.137L544.9 568.143L514.155 561.994L505.957 602.646Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('21'))} d="M472.136 641.249L501.173 646.715L510.055 603.672L481.36 597.864L472.136 641.249Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M535.336 608.795L565.739 615.286L574.28 574.634L544.559 568.144L535.336 608.795Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('20'))} d="M501.173 646.713L529.186 652.862L539.434 609.819L510.055 603.67L501.173 646.713Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('18'))} d="M557.882 659.012L586.236 665.502L595.801 621.434L567.447 615.627L557.882 659.012Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('19'))} d="M529.185 652.522L557.881 659.013L567.446 615.628L539.092 609.82L529.185 652.522Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M565.738 615.285L596.142 621.434L604.682 581.465L574.279 574.633L565.738 615.285Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M596.144 621.433L624.839 627.923L634.746 588.296L604.684 581.464L596.144 621.433Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('16'))} d="M614.59 671.995L642.603 678.827L652.509 635.442L624.155 627.585L614.59 671.995Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('17'))} d="M586.235 665.504L614.589 671.994L624.154 627.585L595.801 621.436L586.235 665.504Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M634.403 588.297L664.123 594.788L654.899 636.123L624.837 627.924L634.403 588.297Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('15'))} d="M642.602 679.167L670.614 686.341L680.179 642.273L652.509 635.44L642.602 679.167Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('12'))} d="M679.837 642.613L685.645 643.638L694.868 601.961L664.123 594.787L655.241 636.122L679.837 642.613Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('13'))} d="M728.689 616.65L728.006 610.501L694.527 601.961L685.646 643.638L710.925 648.42C712.291 631.203 723.337 620.066 728.689 616.65Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('14'))} d="M714.68 672.335C710.035 663.043 710.239 652.521 710.923 648.422L680.178 642.614L670.955 685.999H676.42L714.68 672.335Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1881_8852" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1881_8852" transform="matrix(0.000960878 0 0 0.00124405 -1.83813 -0.68993)"/>
        </pattern>
        <image id="image0_1881_8852" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}