import React, { useState } from 'react'
import './ContactForm.css'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from "react-toastify";
import { saveRegisterContact } from '../../../../../services/ContactService';
import Loading from '../../../../../components/loading/Loading';
import { useTranslation } from 'react-i18next';

export default function ContactForm() {

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    reset
  } = useForm()

  /**
   * Handle submit form
   */
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const params = {
      name: data.name,
      last_name: data.lastName,
      country_code: data.countryCode,
      phone: data.phone,
      email: data.email
    };
    try {
      const response = await saveRegisterContact(params);
      
      setLoading(false);
      if (response.data && response.data.status === 'success') {
        toast.success('Gracias por enviarnos tu información, te contactaremos a la brevedad');
        reset(
          {
            name: '',
            lastName: '',
            countryCode: '',
            phone: '',
            email: ''
          }
        );
      } else {
        toast.error(
          response
        );
      }
    } catch (error) {
      toast.error(
        error.message
      );
    }
  });

  return (
    <div className='ContactForm' id="contacto">
      { loading && <Loading /> }
      <div className='flex flex-col justify-center'>
        <div className='text-center contact-form-title'>
          Contacto
        </div>
        <div className='mt-2 contact-form-subtitle'>
        ¿Estás interesado(a)? Queremos platicar contigo.
        </div>
        <div className='mt-2 lg:mt-4'>
          <form onSubmit={onSubmit}>
            <div className='grid w-full grid-cols-1 lg:grid-cols-2 lg:gap-4'>
              <div className='input-div'>
                <label>
                  { t('homePage.contact.name') }
                </label>
                <input
                  type='text'
                  id="name"
                  className='w-full'
                  {
                    ...register(
                      'name', {
                        required: {
                          value: true,
                          message: t('homePage.contact.errors.name.required')
                        },
                        minLength: {
                          value: 3,
                          message: t('homePage.contact.errors.name.minLength')
                        }
                      }
                    )
                  }
                />
                {
                  errors.name &&
                  <div className='error-message'>
                    {errors.name.message}
                  </div>
                }
              </div>
              <div className='input-div'>
                <label>
                  { t('homePage.contact.lastName') }
                </label>
                <input
                  type='text'
                  id="lastName"
                  className='w-full'
                  {
                    ...register(
                      'lastName', {
                        required: {
                          value: true,
                          message: t('homePage.contact.errors.lastName.required')
                        },
                        minLength: {
                          value: 3,
                          message: t('homePage.contact.errors.lastName.minLength')
                        }
                      }
                    )
                  }
                />
                {
                  errors.lastName &&
                  <div className='error-message'>
                    {errors.lastName.message}
                  </div>
                }
              </div>
            </div>
            <div className='grid w-full grid-cols-1 lg:grid-cols-2 lg:gap-4'>
              <div className='input-div'>
                <label>
                  { t('homePage.contact.countryCode') }
                </label>
                <input
                  type='text'
                  id="countryCode"
                  className='w-full'
                  {
                    ...register(
                      'countryCode',
                      {
                        required: {
                          value: true,
                          message: t('homePage.contact.errors.countryCode.required')
                        }
                      }
                    )
                  }
                />
                {
                  errors.countryCode && 
                  <div className='error-message'>
                    {errors.countryCode.message}
                  </div>
                }
              </div>
              <div className='input-div'>
                <label>
                  { t('homePage.contact.phone') }
                </label>
                <input
                  type='text'
                  id="phone"
                  className='w-full'
                  {
                    ...register(
                      'phone',
                      {
                        required: {
                          value: true,
                          message: t('homePage.contact.errors.phone.required')
                        },
                        minLength: {
                          value: 10,
                          message: t('homePage.contact.errors.phone.minLength')
                        },
                        maxLength: {
                          value: 15,
                          message: t('homePage.contact.errors.phone.maxLength')
                        }
                      }
                    )
                  }
                />
                {
                  errors.phone && 
                  <div className='error-message'>
                    {errors.phone.message}
                  </div>
                }
              </div>
            </div>
            <div className='w-full input-div'>
              <label>
                { t('homePage.contact.email') }
              </label>
              <input
                type='text'
                id="email"
                className='w-full'
                {
                  ...register(
                    'email', {
                      required: {
                        value: true,
                        message: t('homePage.contact.errors.email.required')
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,4}$/,
                        message: t('homePage.contact.errors.email.pattern')
                      }
                    }
                  )
                }
              />
              {
                errors.email && 
                <div className='error-message'>
                  {errors.email.message}
                </div>
              }
            </div>
            <div className='flex justify-center mt-4'>
              <button className='px-10 py-2 text-sm text-white uppercase rounded-md bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo'>
                { t('global.send') }
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className='mt-4'>&nbsp;</div>
      <div className='text-center'>
        <a href='www.zihuainvest.com' target='_blank' rel='no-referrer'>
          zihuainvest.com
        </a>
      </div>
      <div className='text-center'>
        <a href='mailto:info@zihuainvest.com' >
          info@zihuainvest.com
        </a>
      </div>
      <div className='bottom-space'>&nbsp;</div>
      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
