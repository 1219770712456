import api from "../api/axios";
import { endpoints } from "../api/endpoints";


export const getLotLands = async (params = {}) => {
    try {
        if (params.download) {
            return await api.get(endpoints.lotLands + `?download=${params.download}`, { responseType: 'blob' });
        }
        return await api.get(endpoints.lotLands, {params});
    } catch (error) {
        return error.message;
    }
};

export const getLotLandsPublic = async (params = {}) => {
    try {
        return await api.get(endpoints.lotLandsPublic, {params});
    } catch (error) {
        return error.message;
    }
};

export const saveLotLand = async (params) => {
    try {
        return await api.post(endpoints.lotLands, params);
    } catch (error) {
        return error.message;
    }
};

export const updateLotLand = async (id, params) => {
    try {
        return await api.put(endpoints.lotLands + `/${id}`, params);
    } catch (error) {
        return error.message;
    }
};

export const deleteLotLand = async (id) => {
    try {
        return await api.delete(endpoints.lotLands + `/${id}`);
    } catch (error) {
        return error.message;
    }
}

export const assignLandToClient = async ({land, params}) => {
    try {
        return await api.post(`lands-assing/${land}`, params)
    } catch (error) {
        return error.message;
    }
}