export const Manzana22Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <rect width="1219" height="941" fill="url(#pattern0_1868_2429)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M505.135 444.514C515.64 445.681 532.855 432.68 540.149 426.034C544.428 434.982 555.224 445 559.601 448.404V481.472H500.759L505.135 444.514Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M497.354 523.294L500.759 481.473H559.601V523.294H497.354Z"
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('3'))}
            d="M559.602 448.406V523.296H601.91V478.071C579.734 469.512 564.465 454.728 559.602 448.406Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('4'))}
            d="M667.073 523.293H601.909V478.067C623.306 487.404 650.863 488.604 661.724 487.793L667.073 498.978V523.293Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('5'))}
            d="M667.074 585.054V523.294H624.279V585.054H667.074Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('6'))}
            d="M624.281 585.054V523.294H580.514V585.054H624.281Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('7'))}
            d="M580.511 585.054V523.294H536.744V585.054H580.511Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('8'))}
            d="M536.746 585.054V523.294H497.355L493.951 585.054H536.746Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1868_2429" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1868_2429" transform="matrix(0.00136785 0 0 0.00177284 -3.1023 -1.57132)"/>
        </pattern>
        <image id="image0_1868_2429" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}