import React, { useCallback, useEffect, useState } from 'react'
import './ContactOrganization.css'
import { getOrganizationContacts } from '../../../services/ContactService';
import Loading from '../../../components/loading/Loading';
import { useTranslation } from 'react-i18next';
import _ from "lodash"
import format from 'date-fns/format'
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import Pagination from '../../../components/pagination/Pagination';

export default function ContactOrganization() {

  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(null);

  const { t } = useTranslation();

  const getContacts = useCallback(async (page) => {
    setLoading(true);
    const response = await getOrganizationContacts(page, 'id', 'desc');
    console.log('response.data.data: ', response.data.data);
    setLoading(false);
    if (response.data.data) {
      response.data.data.forEach(theContact => {
        theContact.show = false;
      });
      setContacts(response.data.data);
      setMeta(response.data.meta);
    }
  }, []);

  /**
   * Handle page selected on pagination
   */
  const onPageSelected = useCallback((pageSelected) => {
    if (meta && meta.current_page !== pageSelected) {
      getContacts(pageSelected);
    }
  }, [getContacts, meta]);

  const handleArrowClick = useCallback((contactSelected, show) => {
    const theIndex = contacts.findIndex((theContact) => theContact.id === contactSelected.id);
    const currentContacts = [...contacts];
    currentContacts[theIndex].show = show;
    setContacts(currentContacts);
  }, [contacts]);

  useEffect(() => {
    getContacts(1);
  }, [getContacts]);

  return (
    <div className='ContactOrganization'>
      { loading && <Loading /> }
      <div className='mt-2 mb-10'>
        <h1 className='title text-left'>
          { t('adminSections.contact') }
        </h1>
      </div>

      {/* mobile */}
      <div className='block lg:hidden'>
        {
          _.map(contacts, (contact, index) => (
            <div
              key={index}
              className='card-info'
            >
              <div className='flex justify-between px-4'>
                <div className='text-sm font-bold'>
                  {contact.name}
                </div>
                <div>
                  {
                    !contact.show &&
                    <BiSolidDownArrow
                      onClick={() => handleArrowClick(contact, true)}
                    />
                  }
                  {
                    contact.show &&
                    <BiSolidUpArrow
                      onClick={() => handleArrowClick(contact, false)}
                    />
                  }
                </div>
              </div>
              {
                contact.show &&
                <div>
                  <div className='grid grid-cols-2 gap-4 my-2 text-sm'>
                    <div className='field-title'>
                      { t('homePage.contact.name') }
                    </div>
                    <div className='text-left field-value'>
                      { contact.name }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2'>
                    <div className='field-title'>
                      { t('homePage.contact.lastName') }
                    </div>
                    <div className='text-left field-value'>
                      { contact.last_name }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2'>
                    <div className='field-title'>
                      { t('homePage.contact.countryCode') }
                    </div>
                    <div className='text-left field-value'>
                      { contact.country_code }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2'>
                    <div className='field-title'>
                      { t('homePage.contact.phone') }
                    </div>
                    <div className='text-left field-value'>
                      { contact.phone }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2'>
                    <div className='field-title'>
                      { t('homePage.contact.email') }
                    </div>
                    <div className='text-left field-value'>
                      { contact.email }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2'>
                    <div className='field-title'>
                      { t('homePage.contact.organization') }
                    </div>
                    <div className='text-left field-value'>
                      {contact.organization_name ? `${contact.organization_name}` : '-'}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2'>
                    <div className='field-title'>
                      { t('homePage.contact.date') }
                    </div>
                    <div className='text-left field-value'>
                      { format(new Date(contact.created_at), 'dd/MM/yyyy H:m:s') }
                    </div>
                  </div>
                </div>
              }
            </div>
            
          ))
        }
      </div>

      {/* web */}
      <div className='hidden lg:block'>
        <div className='w-full'>
          <table className="w-full mt-5 table-auto">
            <thead className='text-black bg-header'>
              <tr>
                <td className='p-2'>ID</td>
                <td className='p-2'>
                  { t('homePage.contact.name') }
                </td>
                <td className='p-2'>
                  { t('homePage.contact.lastName') }
                </td>
                <td className='p-2'>
                  { t('homePage.contact.countryCode') }
                </td>
                <td className='p-2'>
                  { t('homePage.contact.phone') }
                </td>
                <td className='p-2'>
                  { t('homePage.contact.email') }
                </td>
                <td className='p-2'>
                  { t('homePage.contact.organization') }
                </td>
                <td>
                  { t('homePage.contact.date') }
                </td>
              </tr>
            </thead>
            <tbody>
              {
                _.map(contacts, (contact, index) => (
                  <tr
                    className={`border-b border-white text-sm ${index % 2 === 0 ? 'bg-white' : 'bg-header'}`}
                    key={contact.id}
                  >
                    <td className='p-2 text-primary'>
                      {contact.id}
                    </td>
                    <td className='p-2'>
                      {contact.name}
                    </td>
                    <td className='p-2'>
                      {contact.last_name}
                    </td>
                    <td className='p-2'>
                      {contact.country_code}
                    </td>
                    <td className='p-2'>
                      <a href={`tel:${contact.country_code} ${contact.phone}`}>
                        {contact.phone}
                      </a>
                    </td>
                    <td className='p-2'>
                      <a href={`mailto:${contact.email}`}>
                        {contact.email}
                      </a>
                    </td>
                    <td className='p-2'>
                      {contact.organization_name ? `${contact.organization_name}` : '-'}
                    </td>
                    <td className='p-2'>
                    { format(new Date(contact.created_at), 'dd/MM/yyyy H:m:s') }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      <div className='flex justify-center w-full mt-4 mb-4'>
        <Pagination
          meta={meta}
          onPageSelected={onPageSelected}
        />
      </div>

    </div>
  )
}
