import { t } from "i18next";
import { useForm } from "react-hook-form";
import './SendMessage.css';
import { PrimaryButton } from "../../../primary/PrimaryButton";
import { Submitted } from "./Submitted";
import { sendMessageNotification } from "../../../../services/UsersService";

export const SendMessage = () => {

    const {
        register,
        handleSubmit,
        formState
    } = useForm({
        defaultValues: {
            subject: "",
            message: ""
        }
    });

    const {isSubmitted} = formState;

    const submit = async (data) => {
        const payload = {
            ...data,
        }
        const response = await sendMessageNotification(payload);
    }

    if (isSubmitted) {
        return <Submitted/>
    }

    return (
        <div className="px-8 py-2">
            <form onSubmit={handleSubmit(submit)}>
            {/* Subject */}
            <div>
                <div className="label-message">{t("sendMessage.subject")}</div>
                <div className="mt-1">
                    <input type="text" className="w-full p-2 input-message" {...register('subject')}/>
                </div>
            </div>
            {/* Message */}
            <div className="mt-2">
                <div className="label-message">{t("sendMessage.message")}</div>
                <div className="mt-1">
                    <textarea className="w-full p-2 input-message h-36" {...register('message')}></textarea>
                </div>
            </div>
            <div className="flex justify-center mt-5">
                <PrimaryButton type={"submit"}>
                    {t("sendMessage.submit")}
                </PrimaryButton>
            </div>
            </form>
        </div>
    );
}