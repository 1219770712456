export const Manzana6Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1218" height="941" viewBox="0 0 1218 941" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <rect width="1218" height="941" fill="url(#pattern0_1862_3598)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M478.146 553.06L501.123 496.664L543.942 508.674L524.099 567.681L478.146 553.06Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M570.05 581.782L524.098 567.683L543.941 508.676L590.415 523.297L570.05 581.782Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('3'))}
            d="M619.657 534.263L590.415 523.297L570.05 581.782L619.657 599.014L630.623 572.382L619.657 534.263Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('7'))}
            d="M455.169 610.5L478.145 553.06L523.053 567.681L503.732 623.555L455.169 610.5Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('6'))}
            d="M552.296 638.697L503.732 623.553L523.053 567.68L571.094 582.301L552.296 638.697Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('5'))}
            d="M597.204 655.411L552.296 638.701L571.095 582.305L619.658 599.015L597.204 655.411Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('4'))}
            d="M597.204 655.41L630.624 572.383L662.999 671.598C666.133 681.519 656.733 683.608 655.167 682.564C653.913 681.728 616.003 664.113 597.204 655.41Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1862_3598" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1862_3598" transform="matrix(0.00146999 0 0 0.00190367 -3.79187 -2.43583)"/>
        </pattern>
        <image id="image0_1862_3598" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}