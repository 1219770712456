import { useDispatch, useSelector } from "react-redux";
import { FaRegClipboard } from "react-icons/fa";
import { useWizard } from "react-use-wizard";
import { ToastContainer, toast } from 'react-toastify';
import { saveProgress } from "../../../../features/userSlice/paymentSlice";

const LinkPayment = () => {
    const {nextStep} = useWizard();
    const dispatch = useDispatch();
    const {link, clientSelected} = useSelector(state => state.payment);

    return (
        <div className="p-4 bg-white rounded-md">
        Proceder al pago del enganche en el siguiente enlace:
        <div className="flex items-center mt-2">
            <span className="cursor-pointer">
            <FaRegClipboard size={25} onClick={() => {
                toast.info('Texto copiado');
                navigator.clipboard.writeText(link);
                }}/>
            </span>
            <input className="w-full p-2 ml-2 border border-black rounded-md" type="text" onChange={v => v} value={link}/>
        </div>
        <div className="mt-3">
            Se ha enviado el enlace de pago al correo: <span className="text-primary">{clientSelected.email}</span>
        </div>
        <div className='flex items-end mt-3'>
        <button
            onClick={() => {
                dispatch(saveProgress({
                    user: clientSelected.id,
                    payload: {data: {link}, step: 2, name: 'sales'}
                }));
                nextStep();
            }}
            className={`border bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo text-white border-whit p-1 rounded-md w-full`}
        >
            {`Cargar archivos`}
        </button>
        </div>
        <ToastContainer
            position="top-right"
            theme="light"
            autoClose={2000}
            style={{ width: '500px' }}
          />
        </div>
    )
}

export default LinkPayment;