export const Manzana28Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1874_967)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M468.538 730.718L490.995 712.498L455.827 678.602L425.743 702.329L433.37 726.904C450.319 721.82 463.877 727.328 468.538 730.718Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M409.641 654.025L425.742 702.751L466.842 669.702L433.369 634.534L409.641 654.025Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('16'))} d="M521.923 686.227L490.993 712.497L455.825 678.601L488.027 650.636L521.923 686.227Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('15'))} d="M498.619 641.315L466.841 669.703L433.368 634.535L464.723 607.418L498.619 641.315Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('13'))} d="M464.722 607.419L498.619 641.315L529.973 612.079L496.5 578.183L464.722 607.419Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M560.904 581.995L529.973 612.078L496.5 578.181L527.431 548.945L560.904 581.995Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M588.869 551.487L560.904 581.994L527.431 548.944L556.243 517.59L588.869 551.487Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M616.834 518.014L588.869 551.487L556.244 517.591L583.361 485.812L616.834 518.014Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M643.529 485.812L616.835 518.014L583.362 485.812L610.479 452.339L643.529 485.812Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M686.322 428.189L643.527 485.814L610.478 452.341L617.681 443.867L686.322 428.189Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('14'))} d="M554.125 656.991L521.923 686.227L487.603 650.636L518.957 622.247L554.125 656.991Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('12'))} d="M583.361 629.026L554.125 657.415L518.957 622.247L548.617 593.858L583.361 629.026Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M612.175 598.522L582.939 629.452L548.619 594.285L577.431 564.201L612.175 598.522Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M640.139 568.012L612.174 598.519L577.007 564.622L604.971 532.42L640.139 568.012Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M666.831 536.234L640.137 568.012L604.545 532.844L630.815 500.643L666.831 536.234Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M693.103 503.61L666.833 536.236L630.818 501.068L657.088 467.595L693.103 503.61Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M718.102 469.713L693.527 503.61L657.511 467.594L681.663 434.545L718.102 469.713Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M754.541 417.171L718.102 469.711L681.663 434.543L685.9 428.611L745.643 413.781C753.1 411.07 754.682 414.911 754.541 417.171Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1874_967" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1874_967" transform="matrix(0.0011918 0 0 0.00154466 -2.6863 -0.885258)"/>
        </pattern>
        <image id="image0_1874_967" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}