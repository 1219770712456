import './SecondaryButton.css';

export const SecondaryButton = (props) => {
    const {children, disabled = false} = props;
    let className = `secondary-size secondary-btn cursor-pointer border border-[#B7D9E6] rounded-md bg-gradient-to-r from-btnSecondaryFrom to-btnSecondaryTo ${props.className} uppercase`;

    if (disabled) {
      className = `secondary-size text-white cursor-pointer secondary-disabled-text rounded-md bg-gray ${props.className} uppercase`
    }

    return (
        <button
        {...props}
        className={className}
        >
        {children}
      </button>
    )
}