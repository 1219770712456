import axios from "axios";

const api = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : 'http://zihuapp.bcknd.amplifar.com/api'
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://zihuapp.bcknd.amplifar.com'
});

const addAuthorizationHeader = (config) => {
  const token = localStorage.getItem('userToken');
  if (token) {
    const userInfo = JSON.parse(token);
    if (userInfo) {
      config.headers['Authorization'] = `Bearer ${userInfo.token}`;
      config.headers['X-CSRF-TOKEN'] = ` `;
    }
  }
  return config;
};

api.interceptors.response.use(function (response) {
  console.debug('response', response);
  return response;
}, function (error) {
  console.debug('error', error);
  if (error.response.status === 401) {
    window.location.href = '/login';
    // Clear local storage or perform other logout actions
    localStorage.clear();
  }
  return Promise.reject(error);
});

api.interceptors.request.use(addAuthorizationHeader);

export default api;

