import { t } from 'i18next';
import './SelectCategory.css';
import { useCallback, useEffect, useState } from 'react';
import { getSections } from '../../../../services/SectionsService';
import Loading from '../../../../components/loading/Loading';
import { SectionMap } from '../maps/sections/SectionMap';
import { GeneralMap } from '../maps/GeneralMap';

const SelectCategory = () => {

    const [first, setFirst] = useState(true);
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(true);

    const getSectionList = useCallback(async () => {
        const theSections = await getSections();
        if (theSections.data) {
          setSections(theSections.data.data);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (first) {
          setFirst(false);
          setLoading(true);
          getSectionList();
        }
    }, [first, getSectionList]);

    return (
        <div>
        {loading && <Loading/>}
        <div className='title-white'>
            {'Selección de terreno'}
        </div>
        {/* Map container */}
        <div className='flex justify-center w-100'>
            <GeneralMap isPublic={false}/>
        </div>
        </div>
    )
}

export default SelectCategory;