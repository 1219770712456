import React, { useCallback, useEffect, useState } from 'react'
import "./LotLandForm.css"
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from "react-toastify";
import { saveLotLand, updateLotLand } from '../../../../../../../services/LotLandsService';
import { getSections } from '../../../../../../../services/SectionsService';
import _ from 'lodash'
import Loading from '../../../../../../../components/loading/Loading';

export default function LotLandForm(
  {onSuccess, lotLandToEdit}
) {

  const [first, setFirst] = useState(true);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    reset,
    setValue
  } = useForm(
    { defaultValues: lotLandToEdit }
  )

  /**
   * Get sections
   */
  const getSectionList = useCallback(async () => {
    setLoading(true);
    const theSections = await getSections();
    setLoading(false);
    if (theSections.data) {
      setSections(theSections.data.data);
      if (lotLandToEdit) {
        setValue('section', lotLandToEdit.section);
      }
    }
  }, [lotLandToEdit, setValue]);

  /**
   * Handle submit form
   */
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const params = {
      section_id: data.section,
      identifying_label: data.identifyingLabel,
      price: data.price,
      description: data.description,
      location: data.location,
      measurements: data.measurements,
      manzana: data.manzana,
      lote: data.lote,
      surface: data.surface
    };
    try {
      let response = null;
      if (!lotLandToEdit) {
        response = await saveLotLand(params);
      } else {
        response = await updateLotLand(lotLandToEdit.id, params);
      }
      setLoading(false);
      if (response.data && response.data.status === 'success') {
        toast.success(
          lotLandToEdit ? t('lotLandPage.update') : t('lotLandPage.save')
        );
        reset(
          {
            section: '',
            identifyingLabel: '',
            price: '',
            description: '',
            location: '',
            measurements: '',
            surface: '',
            lote: '',
            manzana: ''
          }
        );
        onSuccess();
      } else {
        toast.error(
          response
        );
      }
    } catch (error) {
      toast.error(
        error.message
      );
    }
  });

  useEffect(() => {
    if (first) {
      setFirst(false);
      getSectionList();
    }
  }, [first, getSectionList]);

  return (
    <div className='flex justify-center LotLandForm'>
      { loading && <Loading /> }
      <div className='w-10/12 p-8 mt-4 bg-white rounded-md text-dark'>
        <div className='text-left'>
          { lotLandToEdit ? t('lotLandPage.edit') : t('lotLandPage.add') }
        </div>
        <div className='h-4 border-b border-gray' >&nbsp;</div>
        <div className='mt-4 text-light'>
          <form onSubmit={onSubmit}>
            <div className='flex w-full gap-2 mb-2'>
              <div className='w-1/2'>
                <label
                  htmlFor='section'
                  className='label'
                >
                  { t('lotLandFormPage.section') }
                </label>
                <select
                  className='w-full p-2 text-black border rounded-md border-light'
                  {
                    ...register(
                      'section', {
                        required: {
                          value: true,
                          message: t('lotLandFormPage.errors.section.required')
                        }
                      }
                    )
                  }
                >
                  <option value=''>Seleccionar</option>
                  {
                    _.map(sections, (section) => (
                      <option
                        value={section.id}
                        key={section.id}
                      >
                        {section.name}
                      </option>
                    ))
                  }
                </select>
                {
                  errors.section && 
                  <div className='error-message'>
                    {errors.section.message}
                  </div>
                }
              </div>
              <div className='w-1/2'>
                <label
                  htmlFor='identifyingLabel'
                  className='label'
                >
                  { t('lotLandFormPage.identifyingLabel') }
                </label>
                <input
                  className='w-full p-2 text-black border rounded-md border-light'
                  {
                    ...register(
                      'identifyingLabel', {
                        required: {
                          value: true,
                          message: t('lotLandFormPage.errors.identifyingLabel.required')
                        }
                      }
                    )
                  }
                />
                {
                  errors.identifyingLabel && 
                  <div className='error-message'>
                    {errors.identifyingLabel.message}
                  </div>
                }
              </div>
            </div>

            <div className='w-full mb-2'>
              <label
                htmlFor='description'
                className='label'
              >
                {t('lotLandFormPage.description')}
              </label>
              <input
                type="text"
                id="description"
                className="w-full p-2 text-black border rounded-md border-light"
                {
                  ...register(
                    'description', {
                      required: {
                        value: true,
                        message: t('lotLandFormPage.errors.description.required')
                      },
                      minLength: {
                        value: 5,
                        message: t('lotLandFormPage.errors.description.minLength')
                      }
                    }
                  )
                }
              />
              {
                errors.description && 
                <div className='error-message'>
                  {errors.description.message}
                </div>
              }
            </div>

            <div className='flex w-full gap-2 mb-2'>
              <div className='w-1/2'>
                <label
                  htmlFor='manzana'
                  className='label'
                >
                  { t('lotLandFormPage.manzana') }
                </label>
                <input
                  className='w-full p-2 text-black border rounded-md border-light'
                  type='text'
                  id="manzana"
                  {...register('manzana', {
                    required: {
                      value: true,
                      message: t('lotLandFormPage.errors.manzana.required')
                    }
                  })}
                />
                {
                  errors.manzana && 
                  <div className='error-message'>
                    {errors.manzana.message}
                  </div>
                }
              </div>
              <div className='w-1/2'>
                <label
                  htmlFor='lote'
                  className='label'
                >
                  { t('lotLandFormPage.lote') }
                </label>
                <input
                  className='w-full p-2 text-black border rounded-md border-light'
                  type='text'
                  id="lote"
                  {...register('lote', {
                    required: {
                      value: true,
                      message: t('lotLandFormPage.errors.lote.required')
                    }
                  })}
                />
                {
                  errors.lote && 
                  <div className='error-message'>
                    {errors.lote.message}
                  </div>
                }
              </div>
            </div>
            <div className='flex w-full gap-2 mb-2'>
              <div className='w-1/2'>
                <label
                  htmlFor='price'
                  className='label'
                >
                  {t('lotLandFormPage.price')}
                </label>
                <input
                  type="number"
                  id="price"
                  className="w-full p-2 text-black border rounded-md border-light"
                  {
                    ...register(
                      'price', {
                        required: {
                          value: true,
                          message: t('lotLandFormPage.errors.price.required')
                        },
                        min: {
                          value: 1,
                          message: t('lotLandFormPage.errors.price.min')
                        }
                      }
                    )
                  }
                />
                {
                  errors.price && 
                  <div className='error-message'>
                    {errors.price.message}
                  </div>
                }
              </div>
              
              <div className='w-1/2'>
                <label
                  htmlFor='location'
                  className='label'
                >
                  {t('lotLandFormPage.location')}
                </label>
                <input
                  type="text"
                  id="location"
                  className="w-full p-2 text-black border rounded-md border-light"
                  {
                    ...register(
                      'location', {
                        required: {
                          value: true,
                          message: t('lotLandFormPage.errors.location.required')
                        }
                      }
                    )
                  }
                />
                {
                  errors.location && 
                  <div className='error-message'>
                    {errors.location.message}
                  </div>
                }
              </div>
            </div>
            
            <div className='flex w-full gap-2 mb-2'>
              <div className='w-1/2'>
                <label
                  htmlFor='measurements'
                  className='label'
                >
                  {t('lotLandFormPage.measurements')}
                </label>
                <input
                  type="text"
                  id="measurements"
                  className="w-full p-2 text-black border rounded-md border-light"
                  {
                    ...register(
                      'measurements', {
                        required: {
                          value: true,
                          message: t('lotLandFormPage.errors.measurements.required')
                        }
                      }
                    )
                  }
                />
                {
                  errors.measurements && 
                  <div className='error-message'>
                    {errors.measurements.message}
                  </div>
                }
              </div>
              <div className='w-1/2'>
                <label
                  htmlFor='surface'
                  className='label'
                >
                  {t('lotLandFormPage.surface')}
                </label>
                <input
                  type="text"
                  id="surface"
                  className="w-full p-2 text-black border rounded-md border-light"
                  {
                    ...register(
                      'surface', {
                        required: {
                          value: true,
                          message: t('lotLandFormPage.errors.surface.required')
                        }
                      }
                    )
                  }
                />
                {
                  errors.surface && 
                  <div className='error-message'>
                    {errors.surface.message}
                  </div>
                }
              </div>
            </div>
            

            <div className='flex w-full gap-2 mb-2'>
              <div className='w-1/2'>
                <label
                  htmlFor='boundaries_northwest'
                  className='label'
                >
                  {t('lotLandFormPage.boundaries_northwest')}
                </label>
                <input
                  type="text"
                  id="boundaries_northwest"
                  className="w-full p-2 text-black border rounded-md border-light"
                  {
                    ...register(
                      'boundaries_northwest', {
                        max: {
                          value: 255,
                          message: t('lotLandFormPage.errors.boundaries_northwest.max')
                        }
                      }
                    )
                  }
                />
                {
                  errors.boundaries_northwest && 
                  <div className='error-message'>
                    {errors.boundaries_northwest.message}
                  </div>
                }
              </div>
              <div className='w-1/2'>
                <label
                  htmlFor='boundaries_southwest'
                  className='label'
                >
                  {t('lotLandFormPage.boundaries_southwest')}
                </label>
                <input
                  type="text"
                  id="boundaries_southwest"
                  className="w-full p-2 text-black border rounded-md border-light"
                  {
                    ...register(
                      'boundaries_southwest', {
                        max: {
                          value: 255,
                          message: t('lotLandFormPage.errors.boundaries_southwest.max')
                        }
                      }
                    )
                  }
                />
                {
                  errors.surface && 
                  <div className='error-message'>
                    {errors.surface.message}
                  </div>
                }
              </div>
            </div>

            <div className='flex w-full gap-2 mb-2'>
              <div className='w-1/2'>
                <label
                  htmlFor='boundaries_northeast'
                  className='label'
                >
                  {t('lotLandFormPage.boundaries_northeast')}
                </label>
                <input
                  type="text"
                  id="boundaries_northeast"
                  className="w-full p-2 text-black border rounded-md border-light"
                  {
                    ...register(
                      'boundaries_northeast', {
                        max: {
                          value: 255,
                          message: t('lotLandFormPage.errors.boundaries_northeast.max')
                        }
                      }
                    )
                  }
                />
                {
                  errors.boundaries_northeast && 
                  <div className='error-message'>
                    {errors.boundaries_northeast.message}
                  </div>
                }
              </div>
              <div className='w-1/2'>
                <label
                  htmlFor='boundaries_southeast'
                  className='label'
                >
                  {t('lotLandFormPage.boundaries_southeast')}
                </label>
                <input
                  type="text"
                  id="boundaries_southeast"
                  className="w-full p-2 text-black border rounded-md border-light"
                  {
                    ...register(
                      'boundaries_southeast', {
                        max: {
                          value: 255,
                          message: t('lotLandFormPage.errors.boundaries_southeast.max')
                        }
                      }
                    )
                  }
                />
                {
                  errors.boundaries_southeast && 
                  <div className='error-message'>
                    {errors.boundaries_southeast.message}
                  </div>
                }
              </div>
            </div>

            <div className='flex justify-center mt-2'>
              <div className='w-1/2'>
                <button
                  className='w-full pt-1 pb-1 text-white rounded-sm bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo'
                >
                  {t('global.save')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
