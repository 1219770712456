import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import { useAuth } from '../../../../../hooks/useAuth';
import { updatePassword } from '../../../../../services/UsersService';
import AvatarDetails from '../../../../../assets/icons/AvatarDetails';
import Card from '../../../../../components/card/Card';
import { Selector } from '../../../../../components/selector/Selector';
import Modal from '../../../../../components/modal/Modal';
import "./CompleteProfile.css";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {createPayment, getPaymentMethod} from '../../../../../services/PaymentService';
import { CheckoutForm } from '../../../payment/Payment';
import { roles } from '../../../../../constants/roles';
import { ShowCard } from '../../../sale/components/card/ShowCard';
import { PrimaryButton } from '../../../../../components/primary/PrimaryButton';

const countryCodes = [
  {value: '+1', label: 'America'},
  {value: '+52', label: 'México'},
];

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const UpdatePassword = () => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
  } = useForm();

  const submit = async (data) => {
    try {
      const response = await updatePassword(data);
      if (response?.data?.status === 'success') {
        toast.success(response.data.message);
      } else {
        toast.error(response?.data);
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        // validation error
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.message
        );
      }
    }
  }

  return (
    <>
    <div className='p-5'>
      <form onSubmit={handleSubmit(submit)}>
        <div>
          <label className='w-full mb-1 label-pass text-sm md:text-base'>{t('profile.currentPassword')}</label>
          <input className='w-full p-3 mt-2 input-form' type="password" {...register('password')} autoComplete='on'/>
        </div>
        <div className='mt-2'>
          <label className='w-full mb-1 label-pass'>{t('profile.newPassword')}</label>
          <input className='w-full p-3 mt-2 input-form' type="password" {...register('new_password')}/>
        </div>
        <div className='mt-2'>
          <label className='w-full mb-1 label-pass'>{t('profile.confirmPassword')}</label>
          <input className='w-full p-3 mt-2 input-form' type="password" {...register('new_password_confirmation')}/>
        </div>
        <div className='flex justify-center mt-2'>
          <PrimaryButton >{t('profile.save')}</PrimaryButton>
        </div>
      </form>
    </div>
    </>
  )
}

export const CompleteProfile = ({firstTime = false}) => {

  const { user } = useAuth().user;
  const { updateProfileOnMemory } = useAuth();
  
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [paymentToggle, setPaymentToggle] = useState(false);
  const [card, setCard] = useState(null);
  const [showAlertFirstTime, setShowAlertFirstTime] = useState(false);

  const [secret, setSecret] = useState(null);
  const { i18n } = useTranslation();

  const {
      register,
      handleSubmit,
      setError,
      control
    } = useForm({
      mode: 'onSubmit',
      defaultValues: {
        name: user.name,
        lastName: user.last_name,
        countryCode: countryCodes.filter(c => c.value === user.country_code)[0],
        phone: user.phone,
        email: user.email,
        postalCode: user?.client?.zip_code ?? null,
        address: user?.client?.full_address ?? null
      }
    });

    const getSecret = useCallback(async () => {
        try {
            const response = await createPayment({client: user?.client?.id});
            setSecret(response.data)
        } catch (error) {
            console.error(error)
        }
    }, [user?.client?.id]);

    const getCard = useCallback(async () => {
        // retrieve cards
        try {
            const response = await getPaymentMethod();
            setCard(response.data);
        } catch (error) {
            console.error(error)
        }
    }, []);
  
    useEffect(() => {
        getSecret();
        if (firstTime) {
          setShowAlertFirstTime(true);
        } else {
          setShowAlertFirstTime(false);
        }
    }, [firstTime, getSecret]);

    useEffect(() => {
        getCard();
    }, [getCard, paymentToggle])

    const options = {
        // passing the client secret obtained from the server
        clientSecret: secret,
        locale: i18n.language
      };
    
    if (!secret) {
        return <></>
    }

  /**
   * Handle submit form
   */
  const onSubmit = handleSubmit(async (data) => {

    // password confirmation
    if (data.password) {
      if (data.password !== data.passwordConfirmation) {
        setError('passwordConfirmation', {message: t('registerPage.errors.confirmPassword.validate')});
        return null;
      }
    }

    const params = {
      name: data.name,
      last_name: data.lastName,
      country_code: data.countryCode.value,
      phone: data.phone,
      email: data.email,
    };

    try {
      await updateProfileOnMemory(params);
    } catch (error) {
      toast.error(
        error.message
      );
    }
  });

  return (
    <>
    <div className='p-10'>
      <div className='title'>
        {t('completeProfile.title')}
      </div>
        {/* Avatar section */}
        <Card className='mt-7 bg-[#F1F3F4] rounded-md'>
          <div className='w-full'>
              {/* Avatar and names */}
              <div className="flex items-center p-10 pb-0">
                  <div className='m-4'>
                  <AvatarDetails/>
                  </div>
                  <div className="w-full">
                      <div>
                        <h1 className='userTitle'>{`${user.name} ${user.last_name}`}</h1>
                      </div>
                  </div>
              </div>
              <form onSubmit={onSubmit} className='p-10 pt-0'>
                <div className='grid grid-cols-1 md:grid-cols-2'>
                  <div className='p-3'>
                    <div className='mt-2'>
                      <label className='w-full mb-1 label-input'>{t('profile.name')}</label>
                      <input className='w-full p-1 rounded-sm' {...register('name')}/>
                    </div>
                    <div className='mt-2'>
                      <label className='w-full mb-1 label-input'>{t('profile.countryCode')}</label>
                      <Selector name="countryCode" defaultValue={countryCodes.filter(c => c.value === user.country_code)[0]} className={'w-full md:w-3/5'} options={countryCodes} control={control}/>
                    </div>
                    {user?.client?.zip_code && (
                      <div className='mt-2'>
                        <label className='w-full mb-1 label-input'>{t('profile.zipCode')}</label>
                        <input className='w-full p-1 rounded-sm' {...register('postalCode')}/>
                      </div>
                    )}
                    <div className='mt-2'>
                        <label className='w-full mb-1 label-input'>{t('profile.email')}</label>
                        <input className='w-full p-1 rounded-sm' {...register('email')}/>
                      </div>
                  </div>
                  <div className='p-3'>
                    <div className='mt-2'>
                      <label className='w-full mb-1 label-input'>{t('profile.lastName')}</label>
                      <input className='w-full p-1 rounded-sm' {...register('lastName')}/>
                    </div>
                    <div className='mt-2'>
                      <label className='w-full mb-1 label-input'>{t('profile.phone')}</label>
                      <input className='w-full p-1 rounded-sm' {...register('phone')}/>
                    </div>
                    {user?.client?.full_address && (
                    <div className='mt-2'>
                      <label className='w-full mb-1 label-input'>{t('profile.address')}</label>
                      <input className='w-full p-1 rounded-sm' {...register('address')}/>
                    </div>
                    )}
                    <div className='mt-5'>
                      <p onClick={() => setToggle(true)} className='cursor-pointer update-password'>{t('profile.updatePassword')}</p>
                    </div>
                  </div>
                </div>

                <div className='divider'></div>

                {user.roles[0].name.toLowerCase() === roles.CLIENT && (
                  <ShowCard toggle={paymentToggle} setToggle={setPaymentToggle}/>
                )}

                <div className='flex justify-center mt-2'>
                      <PrimaryButton disabled={!card}>{t('profile.save')}</PrimaryButton>
                </div>
              </form>
          </div>
        </Card>
    </div>
    {toggle && (
      <>
        <div className='block lg:hidden'>
          <Modal
            type="medium"
            title={t('profile.updatePassword')}
            onCloseModal={() => setToggle(false)}
          >
            <UpdatePassword/>
          </Modal>
        </div>
        <div className='hidden lg:block'>
          <Modal
            type="small"
            title={t('profile.updatePassword')}
            onCloseModal={() => setToggle(false)}
          >
            <UpdatePassword/>
          </Modal>
        </div>
      </>
    )}
    {
      paymentToggle && (
        <>
          <div className='block lg:hidden'>
            <Modal
              type='medium'
              title="Ingresa tu método de pago"
              onCloseModal={() => setPaymentToggle(false)}
            >
              <Elements key={secret} stripe={stripePromise} options={options}>
                <CheckoutForm secret={secret}/>
              </Elements>
            </Modal>
          </div>
          <div className='hidden lg:block'>
            <Modal
              type='small'
              title="Ingresa tu método de pago"
              onCloseModal={() => setPaymentToggle(false)}
            >
              <Elements key={secret} stripe={stripePromise} options={options}>
                <CheckoutForm secret={secret}/>
              </Elements>
            </Modal>
          </div>
        </>
      )}

      {
        showAlertFirstTime &&
        <Modal
          onCloseModal={() => {
            setShowAlertFirstTime(false);
          }}
          type="small"
        >
          <div className='flex justify-center items-center h-100 p-8 text-center'>
            {t('registerPage.firstTimeMessage')}
          </div>
        </Modal>
      }
    </>
  )
}
