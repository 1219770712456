const Avatar = ({size = '44'}) => {
    return (
        <>
        <svg width={size} height={size} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22" cy="22" r="22" fill="#0096B3"/>
        <path d="M25.24 21.86C24.35 22.36 23.33 22.64 22.24 22.64C21.15 22.64 20.13 22.36 19.24 21.86C16.18 23.06 14 26.04 14 29.53V29.88C16.2 31.82 19.08 32.9901 22.24 32.9901C25.4 32.9901 28.28 31.8201 30.48 29.8701V29.52C30.48 26.04 28.3 23.06 25.24 21.86ZM22.24 11C19.24 11 16.81 13.4301 16.81 16.4301C16.81 19.4301 19.24 21.86 22.24 21.86C25.24 21.86 27.67 19.4301 27.67 16.4301C27.66 13.4301 25.24 11 22.24 11Z" fill="#E5F4F7"/>
        </svg>
        </>
    )
}

export default Avatar;