import { Payment } from "../payment/Payment";

const PaymentMethod = () => {
    return (
        <>
        <Payment />
        </>
    )
}

export default PaymentMethod;