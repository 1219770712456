import React, { useCallback, useEffect, useState } from 'react'
import { getAmortizationsClient } from '../../../services/AmortizationsService';
import "./Credits.css"
import { useAuth } from '../../../hooks/useAuth';
import Loading from '../../../components/loading/Loading';
import { useTranslation } from 'react-i18next';
import _ from "lodash"
import { format } from 'date-fns';

export default function Credits() {

  const [loading, setLoading] = useState(false);
  const [amortizations, setAmortizations] = useState([]);

  const { user } = useAuth();
  const { t } = useTranslation();

  const getAmortizations = useCallback(async (userId) => {
    setLoading(true);
    const amortizations = await getAmortizationsClient(userId);
    setAmortizations(amortizations.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (user) {
      getAmortizations(user.user.id);
    }
  }, [getAmortizations, user]);

  return (
    <div
      className='Credits'
    >
      { loading && <Loading /> }
      <div className='my-2'>
        <h1 className='title'>
          { t('adminSections.amortizations') }
        </h1>
      </div>
      <div className='w-full'>
        {
          _.map(amortizations, (amortization) => (
            <div
              key={amortization.land.id}
              className='mt-4'
            >
              <div className='bg-tertiary p-4 rounded-md text-primary my-2'>
                <div className='text-xl'>
                  { amortization.land.identifying_label }
                </div>
                <div className='text-sm text-dark mt-2'>
                  { amortization.land.description }
                </div>
              </div>

              <>
                <div className='grid grid-cols-6 gap-4 border-b border-black py-1 text-base text-primary'>
                  <div>
                    { t('amortizationSchedulePage.period') }
                  </div>
                  <div>
                    { t('amortizationSchedulePage.paymentDate') }
                  </div>
                  <div>
                    { t('amortizationSchedulePage.amount') }
                  </div>
                  <div>
                    { t('amortizationSchedulePage.interest') }
                  </div>
                  <div>
                    { t('amortizationSchedulePage.paymentAmount') }
                  </div>
                  <div>
                    { t('amortizationSchedulePage.status') }
                  </div>
                </div>
                {
                  _.map(amortization.items, (item) => (
                    <div
                      className='grid grid-cols-6 gap-4 border-b border-primary my-1 text-sm'
                      key={item.id}
                    >
                      <div>
                        { item.period }
                      </div>
                      <div>
                        { format(new Date(item.payment_date), 'dd/MM/yyyy') }
                      </div>
                      <div>
                        { item.amount }
                      </div>
                      <div>
                        { item.interest }
                      </div>
                      <div>
                        { item.payment_amount }
                      </div>
                      <div>
                        { item.status }
                      </div>
                    </div>
                  ))
                }
              </>
              
            </div>
          ))
        }
      </div>
      <div className='mt-6'>&nbsp;</div>
    </div>
  )
}
