export const roles = {
    SUPER_ADMIN: "super admin",
    ADMIN: "administrador",
    SELLER: "vendedor",
    CLIENT: "cliente",
    COORDINATOR: "coordinador",
    ORGANIZATION: "organizacion"
};

export const rolesIds = {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    SELLER: 5,
    CLIENT: 6,
    COORDINATOR: 3,
    ORGANIZATION: 4
};