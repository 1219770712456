import "./Splat.css";

const Splat = ({size = '145', label}) => {
    return (
        <div className="svg-content">
        <svg className="svg-img" width={size} height={size} viewBox="0 0 145 144" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.6264 100.8C28.7134 100.797 29.7736 100.462 30.6648 99.8399L60.4944 79.2L25.9776 89.9855C24.7136 90.3787 23.6325 91.212 22.9305 92.3342C22.2285 93.4564 21.9522 94.7932 22.1517 96.1017C22.3512 97.4103 23.0133 98.604 24.0178 99.466C25.0224 100.328 26.3027 100.801 27.6264 100.8Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M44.654 113.83C44.03 114.725 43.695 115.789 43.694 116.88C43.6845 118.206 44.153 119.492 45.0137 120.501C45.8743 121.51 47.0696 122.176 48.3808 122.376C49.692 122.576 51.0314 122.298 52.154 121.591C53.2766 120.885 54.1072 119.798 54.494 118.529L65.294 84L44.654 113.83Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M79.694 7.2C79.694 5.29044 78.9354 3.45909 77.5852 2.10883C76.2349 0.758569 74.4036 0 72.494 0C70.5844 0 68.7531 0.758569 67.4028 2.10883C66.0526 3.45909 65.294 5.29044 65.294 7.2C65.294 11.1768 72.494 60 72.494 60C72.494 60 79.694 11.1768 79.694 7.2Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M65.294 136.8C65.294 138.71 66.0526 140.541 67.4028 141.891C68.7531 143.241 70.5844 144 72.494 144C74.4036 144 76.2349 143.241 77.5852 141.891C78.9354 140.541 79.694 138.71 79.694 136.8C79.694 132.823 72.494 84 72.494 84C72.494 84 65.294 132.823 65.294 136.8Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M137.294 64.8C133.317 64.8 84.494 72 84.494 72C84.494 72 133.317 79.2 137.294 79.2C139.204 79.2 141.035 78.4415 142.385 77.0912C143.735 75.741 144.494 73.9096 144.494 72C144.494 70.0905 143.735 68.2591 142.385 66.9089C141.035 65.5586 139.204 64.8 137.294 64.8Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M7.6942 64.8C5.78464 64.8 3.95329 65.5586 2.60303 66.9089C1.25277 68.2591 0.494202 70.0905 0.494202 72C0.494202 73.9096 1.25277 75.741 2.60303 77.0912C3.95329 78.4415 5.78464 79.2 7.6942 79.2C11.671 79.2 60.4942 72 60.4942 72C60.4942 72 11.671 64.8 7.6942 64.8Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M22.7806 118.293L34.7787 106.295L38.1723 109.689L26.1742 121.687L22.7806 118.293Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M37.1816 103.895L44.3822 96.6942L47.7763 100.088L40.5757 107.289L37.1816 103.895Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M119.011 89.9855L84.494 79.2L114.324 99.8399C115.218 100.465 116.283 100.8 117.374 100.8C118.698 100.801 119.978 100.328 120.983 99.466C121.987 98.604 122.649 97.4103 122.849 96.1017C123.048 94.7932 122.772 93.4564 122.07 92.3342C121.368 91.212 120.287 90.3787 119.023 89.9855H119.011Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M79.6945 84L90.4825 118.517C90.8611 119.794 91.6892 120.892 92.8138 121.607C93.9384 122.322 95.2836 122.605 96.6011 122.406C97.9187 122.207 99.1196 121.537 99.9822 120.522C100.845 119.506 101.311 118.212 101.294 116.88C101.291 115.793 100.956 114.733 100.334 113.842L79.6945 84Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M106.771 109.697L110.165 106.303L122.163 118.301L118.769 121.695L106.771 109.697Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M97.1744 100.098L100.568 96.7038L107.769 103.904L104.375 107.299L97.1744 100.098Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M25.9776 54.0119L60.4944 64.8L30.6648 44.16C29.7733 43.5383 28.7133 43.2034 27.6264 43.2C26.1634 43.2031 24.7612 43.7857 23.7267 44.8202C22.6922 45.8547 22.1096 47.2569 22.1064 48.7199C22.1013 49.9 22.4745 51.0506 23.1712 52.003C23.8679 52.9554 24.8515 53.6595 25.9776 54.0119Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M65.294 59.9998L54.494 25.483C54.1072 24.2143 53.2766 23.1272 52.154 22.4207C51.0314 21.7142 49.692 21.4356 48.3808 21.6357C47.0696 21.8359 45.8743 22.5015 45.0137 23.5108C44.153 24.52 43.6845 25.8055 43.694 27.1318C43.6939 28.2174 44.0246 29.2773 44.642 30.1702L65.294 59.9998Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M22.7901 25.6968L26.1837 22.3032L38.1818 34.3013L34.7882 37.6949L22.7901 25.6968Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M37.189 40.0979L40.5831 36.7038L47.7837 43.9044L44.3896 47.2985L37.189 40.0979Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M117.374 43.2C116.288 43.1999 115.229 43.5306 114.336 44.148L84.494 64.8L119.011 54C120.135 53.6508 121.119 52.9513 121.817 52.0035C122.516 51.0557 122.893 49.9094 122.894 48.732C122.894 47.2669 122.313 45.8616 121.278 44.8245C120.243 43.7874 118.839 43.2031 117.374 43.2Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M100.346 30.1704C100.968 29.2788 101.303 28.2189 101.306 27.132C101.326 25.7984 100.862 24.5029 100 23.485C99.1387 22.467 97.9376 21.7954 96.6192 21.5944C95.3008 21.3933 93.9541 21.6764 92.8282 22.3912C91.7023 23.1061 90.8733 24.2044 90.4945 25.4832L79.6945 60L100.346 30.1704Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M106.781 34.3127L118.779 22.3147L122.173 25.7083L110.175 37.7063L106.781 34.3127Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        <path d="M97.1818 43.9094L104.382 36.7088L107.776 40.1029L100.576 47.3035L97.1818 43.9094Z" fill="#2BBFDC1F" fillOpacity="0.49"/>
        </svg>
        <p className="svg-text">{label}</p>
        </div>
    );
}

export default Splat;