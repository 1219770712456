export const Manzana15Div3 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1882_548)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('39'))} d="M609.057 683.583L602.559 669.22L665.824 598.773L690.104 628.183L609.057 683.583Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('42'))} d="M674.032 739.322L666.508 718.462L730.457 694.182L750.634 739.322H674.032Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('41'))} d="M730.457 694.18L666.85 718.46L652.829 681.185L710.965 659.299L730.457 694.18Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('43'))} d="M627.181 739.321L615.554 695.206L653.171 681.186L674.032 739.321H627.181Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('40'))} d="M615.212 695.207L609.056 683.237L690.104 628.18L710.965 659.299L615.212 695.207Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('38'))} d="M602.559 669.215L594.352 652.459L639.834 570.043L665.824 598.769L602.559 669.215Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('37'))} d="M639.834 570.042L612.134 545.078L584.092 629.546L594.352 652.458L639.834 570.042Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('36'))} d="M584.092 629.55L564.258 577.912L581.357 522.17L612.134 545.082L584.092 629.55Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('35'))} d="M564.258 577.911L524.931 565.6L546.475 501.309L581.357 522.169L564.258 577.911Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('34'))} d="M546.475 501.309C543.466 498.847 524.019 489.112 514.672 484.553L491.417 554.657L524.931 565.6L546.475 501.309Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('33'))} d="M514.672 484.55L483.552 470.529L458.93 542.686L491.418 554.655L514.672 484.55Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('32'))} d="M458.93 542.686L418.577 529.691L463.376 461.639L483.552 470.53L458.93 542.686Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('31'))} d="M418.577 530.036L382.328 517.041L445.251 450.014L463.376 461.641L418.577 530.036Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('30'))} d="M365.913 512.595L359.074 496.523L428.836 433.941L445.251 450.014L382.328 517.041L365.913 512.595Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('28'))} d="M327.27 421.629L404.898 399.059L415.841 417.867L343.685 461.64L327.27 421.629Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('29'))} d="M428.836 433.938L415.841 418.207L343.685 461.638L359.074 496.519L428.836 433.938Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1882_548" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1882_548" transform="matrix(0.000961893 0 0 0.00124669 -2.0923 -1.63453)"/>
        </pattern>
        <image id="image0_1882_548" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}