export const Manzana11Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <rect width="1219" height="941" fill="url(#pattern0_1868_1223)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('9'))}
            d="M588.443 631.25L596.131 623.135L558.549 589.824C544.883 604.857 519.543 624.559 508.582 632.531L588.443 631.25Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('7'))}
            d="M558.551 589.826L596.133 623.137C607.066 613.571 618.34 595.235 622.611 587.263L579.477 562.066C575.377 569.924 563.818 583.847 558.551 589.826Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('8'))}
            d="M653.356 631.254L665.741 612.036L621.754 588.975C615.945 601.274 597.126 622.428 588.442 631.254H653.356Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('5'))}   
            d="M622.609 586.839C628.076 580.347 638.553 560.218 643.108 550.965L597.412 528.758C591.433 543.705 584.458 554.666 579.476 562.069L622.609 586.839Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('6'))}
            d="M665.742 612.033C669.842 605.884 681.116 585.271 686.241 575.733L641.826 553.525C637.982 561.64 628.16 577.868 621.754 588.972L665.742 612.033Z" 
            fill="#0096B3"
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('3'))}
            d="M597.412 528.757L643.108 550.965C649.258 540.373 655.635 519.504 658.056 510.393L609.797 494.592C608.43 503.475 600.971 521.07 597.412 528.757Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M617.483 454.018L665.315 474.944C664.033 485.62 660.19 503.13 658.054 510.39L609.796 494.589C613.212 485.364 616.344 463.698 617.483 454.018Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M701.618 538.576C704.693 531.06 709.447 510.39 711.44 500.994L665.317 474.943C663.95 487.926 658.769 507.4 656.349 515.515L701.618 538.576Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('4'))}
            d="M686.242 575.732C691.025 567.874 698.485 547.688 701.616 538.577L656.347 515.516C651.223 532.94 644.674 548.115 641.827 553.525L686.242 575.732Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1868_1223" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1868_1223" transform="matrix(0.00120124 0 0 0.0015569 -2.94019 -2.22315)"/>
        </pattern>
        <image id="image0_1868_1223" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}