import React, { useCallback } from 'react'
import zihuaLogo from '../../assets/images/zihua-logo.svg'
import "./PageNotFound.css"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div className='PageNotFound'>
      <div className='flex flex-col justify-center items-center'>
        <img
          src={zihuaLogo}
          alt='Zihua Logo'
          className='w-20'
        />
        <div className='mt-8 text-2xl text-black'>
          { t('pageNotFound.description') }
        </div>
        <div className='mt-8'>
          <button
            className='bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo px-6 py-2 rounded-sm text-white'
            onClick={handleGoHome}
          >
            { t('pageNotFound.goHome') }
          </button>
        </div>
      </div>
    </div>
  )
}
