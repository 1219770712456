import React, { useCallback, useEffect, useState } from 'react'
import "./Section.css"
import { useTranslation } from 'react-i18next'
import { FaEdit, FaTrash } from 'react-icons/fa';
import SectionForm from './components/SectionForm/SectionForm';
import SectionDelete from './components/SectionDelete/SectionDelete'
import Modal from '../../../../../components/modal/Modal'
import Pagination from '../../../../../components/pagination/Pagination';
import { deleteSection, getSections } from '../../../../../services/SectionsService'
import { toast, ToastContainer } from 'react-toastify';
import _ from 'lodash'
import Loading from '../../../../../components/loading/Loading';
import { PrimaryButton } from '../../../../../components/primary/PrimaryButton';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { roles } from '../../../../../constants/roles';
import { useAuth } from '../../../../../hooks/useAuth';
import { FaFileExcel } from 'react-icons/fa6';

export default function Section() {

  const { t } = useTranslation();
  const { user } = useAuth();

  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [meta, setMeta] = useState(null);
  const [sections, setSections] = useState([]);
  const [first, setFirst] = useState(true);
  const [sectionToEdit, setSectionToEdit] = useState(null);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const downloadFile = useCallback((theResponse) => {
    const url = window.URL.createObjectURL(new Blob([theResponse]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `sections.xlsx`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }, []);

  /**
   * Get section list in the page selected
   */
  const getSectionList = useCallback(async (page, download = undefined) => {
    setLoading(true);
    const theSections = await getSections(page, download);
    setLoading(false);
    if (!download) {
      if (theSections.data.data) {
        theSections.data.data.forEach(theSection => {
          theSection.show = false;
        });
        setSections(theSections.data.data);
        setMeta(theSections.data.meta);
      }
    } else {
      downloadFile(theSections.data);
    }
  }, [downloadFile]);

  const handleShowForm = useCallback((theSection) => {
    const data = {
      id: theSection.id ? theSection.id : undefined,
      name: theSection.name,
      description: theSection.description,
      minPrice: theSection.min_price,
      maxPrice: theSection.max_price
    };
    setSectionToEdit(data);
    setShowForm(true);
  }, []);

  const handleDelete = useCallback((theSection) => {
    setSectionToDelete(theSection);
    setShowDelete(true);
  }, []);

  /**
   * Handle page selected on pagination
   */
  const onPageSelected = useCallback((pageSelected) => {
    if (meta && meta.current_page !== pageSelected) {
      // getUserList(pageSelected);
    }
  }, [meta]);

  /**
   * Handle success in form
   */
  const handleSuccess = useCallback(() => {
    getSectionList(1);
  }, [getSectionList]);

  /**
   * Handle close modal and reload users list
   */
  const handleCloseModal = useCallback(() => {
    getSectionList(1);
    setShowForm(false);
    setSectionToEdit(null);
  }, [getSectionList]);

  const handleCancelDelete = useCallback(() => {
    setSectionToDelete(null);
    setShowDelete(false);
  }, []);

  /**
   * Handle confirm delete
   */
  const handleConfirmDelete = useCallback(async() => {
    try {
      setLoading(true);
      const response = await deleteSection(sectionToDelete.id);
      setLoading(false);
      if (response.status === 200) {
        toast.success(t('sectionPage.delete'));
        setShowDelete(false);
        setSectionToDelete(null);
        getSectionList(1);
      }
    } catch (error) {
      toast.error(error.message)
    }
  }, [getSectionList, sectionToDelete, t]);

  const handleArrowClick = useCallback((sectionSelected, show) => {
    const theIndex = sections.findIndex((theSection) => theSection.id === sectionSelected.id);
    const currentSections = [...sections];
    currentSections[theIndex].show = show;
    setSections(currentSections);
  }, [sections]);

  const handleDownload = useCallback(async () => {
    getSectionList(1, 'excel');
  }, [getSectionList]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getSectionList(1);
    }
    if (user.user.roles[0].name.toLowerCase() === roles.SUPER_ADMIN) {
      setCanDelete(true);
    }
  }, [first, getSectionList, user?.user?.roles]);

  return (
    <div className='Section'>
      { loading && <Loading /> }
      <div className='flex justify-between'>
        <div className='mt-2 mb-2'>
          <PrimaryButton
            onClick={() => setShowForm(true)}
          >
            { t('global.add') }
          </PrimaryButton>
        </div>
        <div>
          <FaFileExcel
            className='excel-icon'
            onClick={handleDownload}
          />
        </div>
      </div>
      {/* Vista móvil */}
      <div className='block lg:hidden'>
      {
          _.map(sections, (theSection, index) => (
            <div
              key={index}
              className='card-info'
            >
              <div className='flex justify-between px-4'>
                <div className='text-sm'>
                  {theSection.name}
                </div>
                <div>
                  {
                    !theSection.show &&
                    <BiSolidDownArrow
                      onClick={() => handleArrowClick(theSection, true)}
                    />
                  }
                  {
                    theSection.show &&
                    <BiSolidUpArrow
                      onClick={() => handleArrowClick(theSection, false)}
                    />
                  }
                </div>
              </div>
              {
                theSection.show &&
                <div>
                  <div className='grid grid-cols-2 gap-4 my-2'>
                    <div className='field-title'>
                      { t('global.description') }
                    </div>
                    <div className='text-left field-value'>
                      { theSection.description }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('sectionPage.minPrice') }
                    </div>
                    <div className='text-left field-value'>
                      { theSection.min_price }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                    { t('sectionPage.maxPrice') }
                    </div>
                    <div className='text-left field-value'>
                      { theSection.max_price }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('global.options') }
                    </div>
                    <div className='text-left field-value'>
                      <div className='flex justify-end gap-2'>
                        <div
                          className='cursor-pointer text-primary'
                          onClick={() => handleShowForm(theSection)}
                        >
                          <FaEdit />
                        </div>
                        {
                          canDelete &&
                          <div
                            className='cursor-pointer text-primary'
                            onClick={() => handleDelete(theSection)}
                          >
                            <FaTrash />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>

      {/* Vista web */}
      <div className='hidden lg:block'>
        <table className="w-full table-auto">
          <thead className='text-left bg-header'>
            <tr>
              <th className='p-2 text-black'>
                { t('global.name') }
              </th>
              <th className='p-2 text-black'>
                { t('global.description') }
              </th>
              <th className='p-2 text-black'>
                { t('sectionPage.minPrice') }
              </th><th className='p-2'>
                { t('sectionPage.maxPrice') }
              </th>
              {/* <th className='p-2 text-black'>
                { t('sectionPage.catalog') }
              </th> */}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody className='text-cell'>
            {
              _.map(sections, (theSection, index) => (
                <tr
                  className={`border-b border-white text-sm ${index % 2 === 0 ? 'bg-white' : 'bg-header'}`}
                  key={index}
                >
                  <td className='p-2 text-primary'>
                    {theSection.name}
                  </td>
                  <td className='p-2'>
                    {theSection.description}
                  </td>
                  <td className='p-2'>
                    {theSection.min_price}
                  </td>
                  <td className='p-2'>
                    {theSection.max_price}
                  </td>
                  {/* <td className='p-2'>
                    {theSection.catalog}
                  </td> */}
                  <td>
                    <div className='flex gap-2 m-2'>
                      <div
                        className='cursor-pointer text-primary'
                        onClick={() => handleShowForm(theSection)}
                      >
                        <FaEdit />
                      </div>
                      <div
                        className='cursor-pointer text-primary'
                        onClick={() => handleDelete(theSection)}
                      >
                        <FaTrash />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      <div className='flex justify-center w-full mt-4'>
        <Pagination
          meta={meta}
          onPageSelected={onPageSelected}
        />
      </div>

      {
        showForm &&
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
        >
          <SectionForm
            onSuccess={handleSuccess}
            sectionToEdit={sectionToEdit}
          />
        </Modal>
      }

      {
        showDelete &&
        <Modal
          onCloseModal={() => setShowDelete(false)}
          type="small"
        >
          <SectionDelete
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        </Modal>
      }

      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
