import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import zihuaLogo from '../../../../assets/images/zihua-logo.svg'
import fmLogo from '../../../../assets/home/login/fmlogo.png'
import { FaUsers } from "react-icons/fa";
import { useAuth } from '../../../../hooks/useAuth';
import { roles } from '../../../../constants/roles';
import { PiHouseFill } from "react-icons/pi";
import Group from '../../../../assets/icons/Group';
import Calculator from '../../../../assets/icons/Calculator';
import { Avatar2 } from '../../../../assets/icons/Avatar2';
import { FaComment } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";

const iconSize = 25;

export default function SideMenu() {

  const [optionSelected, setOptionSelected] = useState('');
  const [role, setRole] = useState('');

  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleOptionSelected = useCallback((option) => {
    setOptionSelected(option);
  }, []);

  const navigateOptionSelected = useCallback((option, path) => {
    setOptionSelected(option);
    navigate(path);
  }, [navigate]);

  useEffect(() => {
    setOptionSelected(location.pathname.substring(location.pathname.lastIndexOf('/') + 1));
    const userRole = user.user.roles[0].name.toLowerCase();
    setRole(userRole);
  }, [location, user]);

  return (
    <header className="main-head relative">
      <div className='flex justify-center w-full mt-4'>
        <img
          src={zihuaLogo}
          alt='Zihua Logo'
          className='w-6 lg:w-16'
        />
      </div>
      <nav className="head-nav w-15 lg:w-40">
        <ul className="">
          <li className='flex mt-2'>
            <a href="home" className="cursor-pointer" onClick={() => handleOptionSelected('home')}>
              <PiHouseFill
                size={iconSize}
                className={`mr-2 ${optionSelected === 'home' ? 'text-primary' : 'text-menu'}`}
                onClick={() => navigateOptionSelected('home', 'home')}
              />
              <div className={`hidden lg:block ${optionSelected === 'home' ? 'text-primary' : 'text-menu'}`}>
                  {t('adminSections.home')}
              </div>
            </a>
          </li>

          {
            role !== roles.CLIENT &&
            <>
              <li className='flex'>
                <a href="/admin/users" className="cursor-pointer" onClick={() => handleOptionSelected('users')}>
                  <FaUsers
                    size={iconSize}
                    className={`mr-2 ${optionSelected === 'users' ? 'text-primary' : 'text-menu'}`}
                    onClick={() => navigateOptionSelected('users', 'users')}
                  />
                  <div className={`hidden lg:block ${optionSelected === 'users' ? 'text-primary' : 'text-menu'}`}>
                    {t('adminSections.users')}
                  </div>
                </a>
              </li>
            </>
          }

          {
            role === roles.SUPER_ADMIN &&
            <>
              <li className='flex px-2 mt-2'>
                <a
                  href="catalogs"
                  onClick={() => handleOptionSelected('catalogs')}
                  className="cursor-pointer"
                >
                <Group
                  size={iconSize}
                  className={"mr-2"}
                  onClick={() => navigateOptionSelected('catalogs', '/admin/catalogs')}
                  disabled={optionSelected !== 'catalogs'}
                />
                  <div className={`hidden lg:block ${optionSelected === 'catalogs' ? 'text-primary' : 'text-menu'}`}>{t('adminSections.catalogs')}</div>
                </a>
              </li>
              <li className='flex'>
                <a
                  href="amortizations"
                  onClick={() => handleOptionSelected('amortizations')}
                  className="cursor-pointer"
                >
                  <Calculator
                    size={iconSize}
                    className={"mr-2"}
                    onClick={() => navigateOptionSelected('amortizations', '/admin/amortizations')}
                    disabled={optionSelected !== 'amortizations'}
                  />
                  <div className={`hidden lg:block ${optionSelected === 'amortizations' ? 'text-primary' : 'text-menu'}`}>{t('adminSections.amortizations')}</div>
                </a>
              </li>
              <li className='flex'>
                <a
                  href="contact"
                  onClick={() => handleOptionSelected('contact')}
                  className="cursor-pointer"
                >
                  <FaComment
                    size={iconSize}
                    className={"mr-2"}
                    onClick={() => navigateOptionSelected('contact', '/admin/contact')}
                    disabled={optionSelected !== 'contact'}
                    color={optionSelected === 'contact' ? '#0095b3' : '#939598'}
                  />
                  <div className={`hidden lg:block ${optionSelected === 'contact' ? 'text-primary' : 'text-menu'}`}>{t('adminSections.contact')}</div>
                </a>
              </li>
              <li className='flex'>
                <a
                  href="notification-list"
                  onClick={() => handleOptionSelected('notification-list')}
                  className="cursor-pointer"
                >
                  <IoIosNotifications
                    size={iconSize}
                    className={"mr-2"}
                    onClick={() => navigateOptionSelected('notification-list', '/admin/notification-list')}
                    disabled={optionSelected !== 'notification-list'}
                    color={optionSelected === 'notification-list' ? '#0095b3' : '#939598'}
                  />
                  <div className={`hidden lg:block ${optionSelected === 'notification-list' ? 'text-primary' : 'text-menu'}`}>{t('adminSections.notificationList')}</div>
                </a>
              </li>
            </>
          }
         
          {
            role === roles.CLIENT && (
            <>
              {/* <li className='flex'>
                <a
                  href="credits"
                  onClick={() => handleOptionSelected('credits')}
                  className={'cursor-pointer'}
                >
                  <PiMathOperationsFill size={iconSize} className={`mr-2 ${optionSelected === 'credits' ? 'text-primary' : 'text-menu'}`}/>
                  <div className={`${optionSelected === 'credits' ? 'text-primary' : 'text-menu'}`}>
                    {t('adminSections.credits')}
                  </div>
                </a>
              </li> */}
              <li className='flex'>
                <a
                  href="profile"
                  onClick={() => handleOptionSelected('profile')}
                  className={'cursor-pointer'}
                >
                  <Avatar2 size={iconSize} className={`mr-2 ${optionSelected === 'profile' ? 'text-primary' : 'text-menu'}`} disabled={optionSelected !== 'profile'}/>
                  <div className={`hidden lg:block ${optionSelected === 'profile' ? 'text-primary' : 'text-menu'}`}>
                    {t('adminSections.profile')}
                  </div>
                </a>
              </li>
            </>
            )
          }

          {
            (role === roles.ORGANIZATION || role === roles.SUPER_ADMIN || role === roles.ADMIN) &&
            <>
              <li className='flex'>
                <a href="/admin/contacts-organization" className="cursor-pointer" onClick={() => handleOptionSelected('contactsOrganization')}>
                  <FaUsers
                    size={iconSize}
                    className={`mr-2 ${optionSelected === 'contactsOrganization' ? 'text-primary' : 'text-menu'}`}
                    onClick={() => navigateOptionSelected('contactsOrganization', 'contacts-organization')}
                  />
                  <div className={`hidden lg:block ${optionSelected === 'contactsOrganization' ? 'text-primary' : 'text-menu'}`}>
                    {t('adminSections.registers')}
                  </div>
                </a>
              </li>
            </>
          }

        </ul>
      </nav>
      <div className='flex justify-center w-full mt-4 absolute bottom-0'>
        <img
          src={fmLogo}
          alt='Fuerza Migrante Logo'
          className='w-6 lg:w-16'
        />
      </div>
    </header>
  )
}
