const Group = ({disabled, color="#0096B3", size, className}) => {

    if (disabled) {
        color = '#939598';
    }

    return (
        <div className={className}>
        <svg width={size} height={size} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0834 8.54981C10.0834 9.55796 9.25788 10.3832 8.25001 10.3832H1.83343C0.825276 10.3832 0 9.55796 0 8.54981V2.13323C0 1.12508 0.825276 0.299805 1.83343 0.299805H8.25001C9.25788 0.299805 10.0834 1.12508 10.0834 2.13323V8.54981Z" fill={color}/>
        <path d="M10.0834 20.466C10.0834 21.4741 9.25788 22.2994 8.25001 22.2994H1.83343C0.825276 22.2994 0 21.4741 0 20.466V14.0494C0 13.0415 0.825276 12.2162 1.83343 12.2162H8.25001C9.25788 12.2162 10.0834 13.0415 10.0834 14.0494V20.466Z" fill={color}/>
        <path d="M11.9165 0.299805H16.4999V2.13323H11.9165V0.299805Z" fill={color}/>
        <path d="M11.9165 7.63336H18.3334V9.46679H11.9165V7.63336Z" fill={color}/>
        <path d="M11.9165 3.96625H21.9999V5.79967H11.9165V3.96625Z" fill={color}/>
        <path d="M11.9165 12.2162H21.9999V14.0494H11.9165V12.2162Z" fill={color}/>
        <path d="M11.9165 19.5498H18.3334V21.3832H11.9165V19.5498Z" fill={color}/>
        <path d="M11.9165 15.8834H16.4999V17.7165H11.9165V15.8834Z" fill={color}/>
        </svg>
        </div>
    )
}

export default Group;