export const Manzana3Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1218" height="942" viewBox="0 0 1218 942" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1218" height="942" fill="url(#pattern0_1862_4430)"/>
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('2'))}
                d="M525.789 321.282L505.688 342.93L525.789 393.957L550.53 386.226L525.789 321.282Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('1'))}
                d="M492.545 405.167L525.79 393.957L505.689 342.93L478.629 370.763L492.545 405.167Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('3'))}
                d="M492.545 405.168L550.917 386.226L560.195 411.353L501.436 430.681L492.545 405.168Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('4'))}
                d="M501.438 430.295L560.197 411.354L568.702 436.094L510.33 455.809L501.438 430.295Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('5'))}
                d="M509.94 455.034L568.699 436.092L578.363 460.832L518.832 480.547L509.94 455.034Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('6'))}
                d="M518.834 480.549L578.366 460.834L586.87 486.734L528.112 506.063L518.834 480.549Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('7'))}
                d="M528.109 506.063L586.868 486.734L595.759 511.088L537 530.803L528.109 506.063Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('8'))}
                d="M537.002 530.802L595.761 511.087L604.652 536.214L545.12 556.316L537.002 530.802Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('9'))}
                d="M545.12 556.317L604.652 536.216L613.156 561.729L554.398 580.285L545.12 556.317Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('10'))}
                d="M554.396 580.286L613.155 561.73L621.66 586.471L562.514 605.799L554.396 580.286Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer" 
                onClick={(e) => landSelector(e, findLandByLot('11'))}
                d="M562.514 605.41L621.659 586.468L630.55 613.141L571.405 632.47L562.514 605.41Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer"
                onClick={(e) => landSelector(e, findLandByLot('12'))}
                d="M571.407 632.472L630.552 613.144L637.897 637.884L579.912 657.212L571.407 632.472Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer"
                onClick={(e) => landSelector(e, findLandByLot('13'))}
                d="M579.91 657.211L637.896 637.883L646.014 663.396L587.642 681.952L579.91 657.211Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer"
                onClick={(e) => landSelector(e, findLandByLot('14'))}
                d="M587.642 681.952L646.014 663.783L653.745 689.683L595.76 709.012L587.642 681.952Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer"
                onClick={(e) => landSelector(e, findLandByLot('15'))}
                d="M595.76 708.626L653.745 689.684L661.09 714.811L602.718 732.593L595.76 708.626Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer"
                onClick={(e) => landSelector(e, findLandByLot('16'))}
                d="M602.719 732.593L661.091 714.811L669.209 741.097L610.837 760.039L602.719 732.593Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer"
                onClick={(e) => landSelector(e, findLandByLot('17'))}
                d="M656.837 805.655L640.215 750.762L669.208 741.098L678.872 770.864C682.274 789.728 665.6 801.918 656.837 805.655Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <path 
                className="cursor-pointer"
                onClick={(e) => landSelector(e, findLandByLot('18'))}
                d="M619.727 790.192C629.314 806.582 648.462 807.33 656.838 805.655L640.215 750.762L611.223 760.039L619.727 790.192Z" 
                fill="#0096B3" 
                fillOpacity="0.64" 
                stroke="#6D6859" 
                stroke-opacity="0.6"
            />
            <defs>
            <pattern id="pattern0_1862_4430" patternContentUnits="objectBoundingBox" width="1" height="1">
             <use href="#image0_1862_4430" transform="matrix(0.00108822 0 0 0.00140777 -2.6054 -1.1714)"/>
            </pattern>
                <image id="image0_1862_4430" width="4096" height="2304" href="/mz1.png"/>
            </defs>
        </svg>
    );
}