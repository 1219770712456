import React from 'react'
import zihuaLogo from '../../../../../assets/home/images/zihua-logo.svg'
import zihuaLogoSm from '../../../../../assets/home/images/zihua-logo-sm.png'
import avatarLogo from '../../../../../assets/home/images/avatar.svg'
import './Navbar.css'
import { Link } from 'react-router-dom'
import { IoIosMenu } from "react-icons/io";
import { useState } from 'react'


export default function Navbar() {

  const [showMenu, setShowMenu] = useState(false);

  const togleMenu = () => {
    console.log('Entró al togleMenu');
    const currentState = showMenu;
    setShowMenu(!currentState);
  };

  return (
    <div className='Navbar'>
      <div className='flex justify-between items-center h-100 py-1 gap-4 text-white uppercase'>
        {/* mobile */}
        <div className='block lg:hidden'>
          <div className='mr-4'>
            <IoIosMenu 
              onClick={togleMenu}
              className='text-3xl'
            />
            {
              showMenu &&
              <div className='absolute mt-3.5 -ml-8'>
                <ul className='mobile-menu flex flex-col'>
                  <li onClick={() => setShowMenu(false)} >
                    <a href="#home">
                      Inicio
                    </a>
                  </li>
                  <li onClick={() => setShowMenu(false)} >
                    <a href="#about">
                      Acerca de
                    </a>
                  </li>
                  <li onClick={() => setShowMenu(false)} >
                    <a href="#amenities">
                      Amenidades
                    </a>
                  </li>
                  <li onClick={() => setShowMenu(false)} >
                    <a href="#location">
                      Ubicación
                    </a>
                  </li>
                  <li onClick={() => setShowMenu(false)} >
                    <a href="#contact">
                      Contacto
                    </a>
                  </li>
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='hidden lg:block'>
          <a href="#home">
            <img src={zihuaLogo} alt='Zihua Logo' className='logo' />
          </a>
        </div>
        <div className='block lg:hidden'>
          <img src={zihuaLogoSm} alt='Zihua Logo' className='logo mobile' />
        </div>

        {/* web */}
        <div className='hidden lg:block'>
          <ul>
            <li>
              <a href="#home">
                Proyecto
              </a>
            </li>
            <li>
              <a href="#about">
                Acerca de
              </a>
            </li>
            <li>
              <a href="#amenities">
                Amenidades
              </a>
            </li>
            <li>
              <a href="#location">
                Terreno
              </a>
            </li>
            <li>
              <a href="#location">
                Financiamiento
              </a>
            </li>
            <li>
              <a href="#location">
                Compra tu lote
              </a>
            </li>
            <li>
              <a href="#contact">
                Contacto
              </a>
            </li>
          </ul>
        </div>

        <div className='block'>
          <Link to='login'>
            <img src={avatarLogo} alt='Avatar Logo' className='avatar-logo' />
          </Link>
        </div>

      </div>
    </div>
  )
}
