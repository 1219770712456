import { uploadFiles } from "../../../../services/FileService";
import Dropzone from "./Dropzone";
import { useSelector } from "react-redux";
import { useWizard } from "react-use-wizard";

const UploadContracts = () => {

    const {clientSelected, landSelected} = useSelector(state => state.payment);
    const { nextStep } = useWizard();


    const handleFileUpload = async (files) => {
        try {
            if (files) {
                const response = await uploadFiles(clientSelected.client.id, landSelected.id, files);
                if (response.data.status === 'success') {
                    nextStep();
                }
            }
        } catch (error) {
            console.error(error)
        }
    }
    
    return (
        <>
            <label>Cargar contratos</label>
            <Dropzone onFilesChange={(f) => handleFileUpload(f)} />
        </>
    )
}

export default UploadContracts;