export const CheckPrimary = ({size = 81}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M81 40.5C81 62.8675 62.8675 81 40.5 81C18.1325 81 0 62.8675 0 40.5C0 18.1325 18.1325 0 40.5 0C62.8675 0 81 18.1325 81 40.5ZM17.0566 40.0626L34.9879 58.4317L63.942 28.9534L57.4691 22.4805L34.9879 45.4859L23.5295 33.5894L17.0566 40.0626Z" fill="url(#paint0_linear_1234_5756)" fillOpacity="0.6"/>
        <defs>
        <linearGradient id="paint0_linear_1234_5756" x1="0.00229228" y1="40.4941" x2="81.0089" y2="40.4941" gradientUnits="userSpaceOnUse">
        <stop stopColor="#024E82"/>
        <stop offset="1" stopColor="#0096B3"/>
        </linearGradient>
        </defs>
        </svg>
    )
}