import React, { useCallback, useEffect, useState } from 'react'
import "./Tabs.css"
import _ from 'lodash'

export default function Tabs(
  {items, onSelected}
) {

  const [itemSelected, setItemSelected] = useState('');

  const onItemSelected = useCallback((item) => {
    setItemSelected(item.name);
    onSelected(item.name);
  }, [onSelected]);

  useEffect(() => {
    if (itemSelected === '') {
      if (items.length > 0) {
        setItemSelected(items[0].name);
      }
    }
  }, [itemSelected, items]);

  return (
    <div className='Tabs'>
      <ul
        className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-white"
      >
        {
          _.map(items, (item, index) => (
            <li
              className="me-2"
              key={index}
            >
              <button
                className={
                  `${
                    itemSelected === item.name
                      ? 'text-white bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo'
                      : 'text-light'
                    } 
                    inline-block p-4  rounded-t-lg active dark:bg-gray-800`
                }
                onClick={() => onItemSelected(item)}
              >
                {item.label}
              </button>
          </li>
          ))
        }
      </ul>
    </div>
  )
}
