import './PrimaryButton.css';

export const PrimaryButton = (props) => {
    const {children, disabled = false} = props;
    let className = `primary-btn-size text-white cursor-pointer primary-btn-text rounded-md bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo ${props.className} uppercase`;

    if (disabled) {
      className = `primary-btn-size cursor-pointer primary-btn-text text-white rounded-md bg-gray ${props.className} uppercase`
    }

    if (props.outline) {
      className = `primary-btn-size primary-btn-text bg-white rounded-md hover:outline-blue-400 text-primary outline-primary ${props.className}`
    }

    return (
        <button
        {...props}
        className={className}
        >
        {children}
      </button>
    )
}