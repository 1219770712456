export const DocumentFile = () => {
    return (
        <svg width="56" height="67" viewBox="0 0 56 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M33.6 0V11.1667C33.6 16.1004 37.6115 20.1 42.56 20.1H55.9741C55.9914 20.3248 56 20.551 56 20.7782V49.1333C56 59.0009 47.977 67 38.08 67H17.92C8.02301 67 0 59.0009 0 49.1333V17.8667C0 7.99917 8.02301 0 17.92 0H33.6ZM38.08 0.82869V11.1667C38.08 13.6335 40.0858 15.6333 42.56 15.6333H54.3649C54.0128 15.135 53.6078 14.6701 53.1535 14.2474L40.4248 2.40258C39.7256 1.75192 38.933 1.22295 38.08 0.82869ZM19 28.5C18.1716 28.5 17.5 29.1716 17.5 30C17.5 30.8284 18.1716 31.5 19 31.5H38C38.8284 31.5 39.5 30.8284 39.5 30C39.5 29.1716 38.8284 28.5 38 28.5H19ZM17.5 35C17.5 34.1716 18.1716 33.5 19 33.5H38C38.8284 33.5 39.5 34.1716 39.5 35C39.5 35.8284 38.8284 36.5 38 36.5H19C18.1716 36.5 17.5 35.8284 17.5 35ZM19 38.5C18.1716 38.5 17.5 39.1716 17.5 40C17.5 40.8284 18.1716 41.5 19 41.5H38C38.8284 41.5 39.5 40.8284 39.5 40C39.5 39.1716 38.8284 38.5 38 38.5H19Z" fill="url(#paint0_linear_689_654)" fillOpacity="0.6"/>
        <defs>
        <linearGradient id="paint0_linear_689_654" x1="0.00158479" y1="33.4951" x2="56.0062" y2="33.4951" gradientUnits="userSpaceOnUse">
        <stop stopColor="#024E82"/>
        <stop offset="1" stopColor="#0096B3"/>
        </linearGradient>
        </defs>
        </svg>
    );
}