import { t } from 'i18next';
import AvatarDetails from '../../../../assets/icons/AvatarDetails';
import Splat from '../../../../assets/icons/Splat';
import { ProgressBar } from './ProgressBar';
import "./UserDetails.css";
import { useAuth } from '../../../../hooks/useAuth';
import { roles } from '../../../../constants/roles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProgress } from '../../../../features/userSlice/paymentSlice';
import { getAmortizationSchedule } from '../../../../services/AmortizationsService';
import Loading from '../../../../components/loading/Loading';
import format from 'date-fns/format';
import _ from "lodash";
import { InitialPayment } from './steps/InitialPayment';
import { UploadFilesComponent } from './steps/UploadFilesComponent';
import { PendingApproval } from './steps/PendingApproval';
import { fetchLandsFromUser } from '../../../../features/userSlice/userSlice';

const PaymentTable = ({client, land}) => {
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState([]);

  const showSchedule = async () => {
    setLoading(true);
    const schedule = await getAmortizationSchedule(client.client.id, land.value, 1);
    setSchedule(schedule.data.data);
    setLoading(false);
  };

  useEffect(() => {
    showSchedule();
  }, []);

  const getStatus = (status) => {
    if (status === 'PENDING') {
      return t('status.pending')
    }
    
    if (status === 'PAID') {
      return t('status.paid')
    }

    return ""
  }

  return (
      <>
      { loading && <Loading /> }
      {(schedule.length > 0) && (
        <div className='mt-6'>
        <table className='w-full mt-5 table-auto'>
          <thead className='text-black bg-header'>
            <tr className='text-left'>
              <th className='p-2'>
                { t('amortizationSchedulePage.period') }
              </th>
              <th className='p-2'>
                { t('amortizationSchedulePage.paymentDate') }
              </th>
              <th className='p-2'>
                { t('amortizationSchedulePage.amount') }
              </th>
              <th className='p-2'>
                { t('amortizationSchedulePage.paymentAmount') }
              </th>
              <th className='p-2'>
                { t('amortizationSchedulePage.balance') }
              </th>
              <th className='p-2'>
                { t('amortizationSchedulePage.status') }
              </th>
              <th>
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody className='text-cell'>
            {
              schedule &&
              _.map(schedule, (item, index) => (
                <tr
                  className={`border-b border-white text-sm ${index % 2 === 0 ? 'bg-white' : 'bg-header'}`}
                  key={index}
                >
                  <td className='p-2'>
                    { item?.period }
                  </td>
                  <td className='p-2'>
                    { format(new Date(item.payment_date), 'dd/MM/yyyy') }
                  </td>
                  <td className='p-2'>
                    { item?.amount }
                  </td>
                  <td className='p-2'>
                    { item?.payment_amount }
                  </td>
                  <td className='p-2'>
                    { item?.balance }
                  </td>
                  <td className='p-2'>
                    {getStatus(item?.status)}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      )}
      </>
    )
}

const Amortization = ({client, land}) => {
  return (
    <>
    {/* Congrats icon */}
    <div className="flex items-center justify-center mt-3 mb-3">
        <div className=''>
        <Splat label="¡Has completado 0 de 100 pagos!"/>
        </div>
    </div>
    {/* Payment table */}
    <div>
        <PaymentTable client={client} land={land}/>
    </div>
    </>
  )
}

const stepHandler = (step, land, setStepper, client) => {
  switch (step) {
    case 3:
      return <UploadFilesComponent land={land} setStepper={setStepper} client={client}/>
    case 4:
      return <PendingApproval client={client} land={land}/>
    case 6:
      return <InitialPayment/>
    case 7:
      return <Amortization client={client} land={land}/>
    default:
      break;
  }
}

const gap = (100/7)/2; //
const defaultProgress = (100/7);

// const defaultStep = 3;

const UserDetails = ({user, land}) => {
  
  const userAuth = useAuth();
  const authUser = userAuth.user.user;
  const dispatch = useDispatch();
  const {client} = useSelector(state => state.user);
  // console.debug('userSelected, ', client);
  const {latestProgress} = useSelector(state => state.payment);
  const [stepper, setStepper] = useState(latestProgress?.step);

  const step = stepper ?? latestProgress?.step;
  const progress = step === 3 ? (defaultProgress * step) : (defaultProgress * step) - gap;

  // fetch wizard data
  useEffect(() => {
    dispatch(fetchProgress({user: user.id, params: { land_id: land.value, name: 'sales'}}));
  }, [land])

    return (
        <>
        <div className='p-10 pt-2'>
            {/* Avatar section */}
            <div className='w-full'>
                <div className="flex items-center">
                    <div className='m-4'>
                    <AvatarDetails/>
                    </div>
                    <div className="w-full">
                        <div>
                          <h1 className='userTitle'>{`${user.name} ${user.last_name}`}</h1>
                        </div>
                        {(authUser.roles[0].name.toLowerCase() === roles.SELLER) && (
                          <>
                          <div><p className='text-primary'>{user.email}</p></div>
                          <div><p className='text-primary'>{`(${user.country_code}) ${user.phone}`}</p></div>
                          </>
                        )}
                    </div>
                    {(authUser.roles[0].name.toLowerCase() === roles.SELLER) && (
                    <div className='flex justify-end w-full'>{`${t('userDetails.land')} ${land?.label}`}</div>
                    )}
                </div>
            </div>
            {/* Progress bar */}
            <div className='w-full'>
                <div>
                    <ProgressBar progress={progress}/>
                </div>
                {/* Labels */}
                <div className='grid grid-cols-7 gap-5 mt-5'>
                    <div className='progress-label text-end'>{t('userDetails.userRegister')}</div>
                    <div className='progress-label text-end'>{t('userDetails.selectLand')}</div>
                    <div className='progress-label text-end'>{t('userDetails.sendingDocuments')}</div>
                    <div className='progress-label text-end'>{t('userDetails.documentReception')}</div>
                    <div className='progress-label text-end'>{t('userDetails.documentApproval')}</div>
                    <div className='progress-label text-end'>{t('userDetails.initialPayment')}</div>
                    <div className='progress-label text-end'>{t('userDetails.landPayment')}</div>
                </div>
            </div>
            <div>
              {stepHandler(step, land, setStepper, user)}
            </div>
        </div>
        </>
    );
}

export default UserDetails;
