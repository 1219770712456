export const Manzana15Div2 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1882_1284)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M415.879 321.749L425.034 352.812L498.277 329.597L489.776 299.842L415.879 321.749Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('12'))} d="M425.034 352.488L435.171 381.916L507.759 359.027L498.277 329.6L425.034 352.488Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('13'))} d="M435.171 381.585L442.037 411.993L516.914 388.778L507.759 359.023L435.171 381.585Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('14'))} d="M442.037 411.996L451.192 441.424L525.089 418.536L516.914 388.781L442.037 411.996Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('16'))} d="M459.694 471.178L469.83 501.587L543.072 478.045L533.917 448.617L459.694 471.178Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('15'))} d="M451.192 441.423L459.694 471.178L533.917 448.944L525.089 418.535L451.192 441.423Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('17'))} d="M543.072 478.043L552.228 507.471L478.004 530.032L469.83 501.585L543.072 478.043Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('18'))} d="M478.004 530.035L487.814 560.117L561.383 537.882L552.228 507.801L478.004 530.035Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('19'))} d="M561.383 537.883L569.23 567.965L495.661 590.199L487.814 560.117L561.383 537.883Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('21'))} d="M504.816 619.953L514.626 650.362L587.868 627.147L578.386 597.719L504.816 619.953Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('20'))} d="M495.661 590.199L504.816 619.954L578.386 597.72L569.23 567.965L495.661 590.199Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('22'))} d="M514.299 650.031L523.781 680.113L596.369 657.551L587.868 627.143L514.299 650.031Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('23'))} d="M596.369 657.555L605.852 687.636L533.917 709.544L523.781 679.789L596.369 657.555Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('24'))} d="M533.917 709.541L545.034 738.969L617.296 714.773L605.852 687.307L533.917 709.541Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('27'))} d="M568.903 794.886L579.04 823.006L653.263 801.752L640.511 772.979L568.903 794.886Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('26'))} d="M556.478 767.416L568.903 795.209L640.511 772.975L629.067 744.201L556.478 767.416Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('25'))} d="M617.296 714.773L629.067 744.201L556.478 767.416L545.034 738.643L617.296 714.773Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1882_1284" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1882_1284" transform="matrix(0.000919705 0 0 0.00119201 -1.78222 -1.11664)"/>
        </pattern>
        <image id="image0_1882_1284" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}