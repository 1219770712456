export const endpoints = {
  login: 'api/token',
  user: 'api/user',
  sections: 'api/sections',
  lotLands: 'api/lands',
  profile: 'api/profile',
  pay: 'api/pay',
  amortizations: 'api/amortizations',
  recovery: 'api/recovery-password',
  reset: 'api/reset-password',
  contacts: 'api/contacts',
  userByRole: 'api/user-by-role',
  notification: 'api/notifications',
  landsAssign: 'api/lands-assign',
  progress: 'api/save-progress',
  updatePassword: 'api/change-password',
  fetchProgress: 'api/wizard-progress',
  dashboard: 'api/dashboard',
  files: 'api/files',
  subscription: 'api/subscription',
  allNotifications: 'api/all-notifications',
  sectionsPublic: 'api/sections-public',
  lotLandsPublic: 'api/lands-public',
  uploadLogo: 'api/upload-logo',
  saveUserPublic: 'api/fuerza-migrante-contacts',
  organizationsPublic: 'api/organizations-public',
  fuerzaMigranteContacts: 'api/fuerza-migrante-contacts'
};