import React from 'react'
import "./HomeLayout.css"
import { useOutlet } from 'react-router-dom'

export default function HomeLayout() {

  const outlet = useOutlet();

  return (
    <div
      className='HomeLayout'
    >
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" integrity="sha256-2TnSHycBDAm2wpZmgdi0z81kykGPJAkiUY+Wf97RbvY=" crossOrigin="anonymous"></link>
      <div
        className='w-full'
      >
        {outlet}
      </div>
    </div>
  )
}
