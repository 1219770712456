import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../hooks/useAuth";
import Dropzone from "../../../sale/components/Dropzone";
import { uploadFiles } from "../../../../../services/FileService";
import { useDispatch } from "react-redux";
import { fetchProgress } from "../../../../../features/userSlice/paymentSlice";

export const UploadFilesComponent = ({land, setStepper, client}) => {
    const {user} = useAuth().user;
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    return (
      <div className='mt-4'>
      <Dropzone onFilesChange={async (files) => {
        try {
            if (files) {
              let id = null;
              let user_id = null;
              // id user is client send client
              if (user?.client?.id) {
                id = user.client.id;
                user_id = user.id;
              } else { // as a seller take user from local storage
                const userSelected = JSON.parse(localStorage.getItem('user_selected'));
                // id = userSelected.client.id;
                // user_id = userSelected.id;
                id = client.client.id;
                user_id = userSelected.id;
              }
              
              const response = await uploadFiles(id, land.value, files);
              if (response.data.status === 'success') {
                dispatch(fetchProgress({user: user_id, params: { land_id: land.value, name: 'sales'}}))
                .then(r => {
                  navigate('/admin/upload-success');
                })
              }
            }
        } catch (error) {
            console.error(error)
        }
      }} />
      </div>
    )
  }