export const Rejected = ({disabled = false, color="#F45D85", size=10}) => {
    if (disabled) {
        color="#A2AAAB"
    }

    return (
        <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.27382 5.00019C9.27382 7.4187 7.31323 9.37929 4.89472 9.37929C2.47622 9.37929 0.515625 7.4187 0.515625 5.00019C0.515625 2.58169 2.47622 0.621094 4.89472 0.621094C7.31323 0.621094 9.27382 2.58169 9.27382 5.00019ZM6.62551 4.03551L5.57568 5.01913L6.55935 6.06897L5.85947 6.73104L4.87579 5.68121L3.81649 6.66483L3.16382 5.96494L4.21377 4.98132L3.23009 3.93143L3.92998 3.26941L4.91366 4.31924L5.96349 3.33557L6.62551 4.03551Z" fill={color}/>
        </svg>
    )
}