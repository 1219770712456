import React, { useCallback, useEffect, useState } from 'react'
import "./SelectLand.css"
import { getLotLands } from '../../../../services/LotLandsService';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { assignLand, saveProgress } from '../../../../features/userSlice/paymentSlice';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import LandDetails from './LandDetails';
import Loading from '../../../../components/loading/Loading';
import { Manzana1Div1 } from '../maps/manzanas/Manzana1Div1';
import { Manzana1Div2 } from '../maps/manzanas/Manzana1Div2';
import { ModalDetails } from './modal/ModalDetails';
import { roles } from '../../../../constants/roles';
import { useAuth } from '../../../../hooks/useAuth';
import { selectClient } from '../../../../features/userSlice/userSlice';

const SelectLand = () => {
    const { section, group } = useParams();
    const {user: authUser} = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    // const [position, setPosition] = useState({top: 201, left: 950});
    const [position, setPosition] = useState({top: 0, left: 0});
    // console.debug('position', position);
    const {client: user} = useSelector(state => state.user); // user selected
    console.debug('user', user);
    const [loading, setLoading] = useState(false);
    const [lands, setLands] = useState();

    const periods = [
        {label: t('selectLand.defaultPeriodLabel'), value: 120}
    ];

    const {
        handleSubmit,
        watch,
        setValue
    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            periods: 120
        }
    });

    const landWatcher = watch('land');

    const fetchLands = useCallback(async () => {
        try {
            const response = await getLotLands({pagination: false, section, group});
            setLands(response.data.data);
            setLoading(false);
        } catch (error) {
            toast.error(error.message)
        }
    }, [group, section]);
    
    useEffect(() => {
        setLoading(true);
        // dispatch fetch available lands
        fetchLands();
    }, [fetchLands]);

    const submit = (data) => {
        // convert years to months
        const monthsPeriod = data.periods;
        let client = null;
        let userPayload = null;

        client = user.client.id;
        userPayload = user.id;

        const payload = {
            client_id: client,
            land_id: data.land.id,
            periods: monthsPeriod,
            interest: 0
        }

        dispatch(assignLand({land: payload.land_id, params: payload}))
        dispatch(selectClient(user));

        dispatch(saveProgress({
            user: userPayload,
            payload: {data: payload, step: 3, name: 'sales', land: payload.land_id}
        })).then(response => {
            // set user selected
            navigate('/admin/details');
        });
    }

    const landSelectorHandler = (e, land) => {
        const rect = e.target.getBoundingClientRect();
        setValue('land', land);
        setPosition({
                  top: rect.top,
                  left: rect.left + window.scrollX + rect.width / 2,
                });
        setIsVisible(true);
    }

    const groupHandler = (group) => {
        switch (group) {
            case '1':
                return <Manzana1Div1 landSelector={landSelectorHandler} lands={lands} selected={landWatcher}/>
            case '2':
                return <Manzana1Div2 landSelector={landSelectorHandler} lands={lands} selected={landWatcher}/>
            default:
                return (<></>);
        }
    }

    return (
        <>
          <div className="">
            {loading && <Loading/>}
            <div className='title-white'>{t('userDetails.selectLand')}</div>
            <div className="flex justify-center w-full">
                {groupHandler(group)}
            </div>
            </div>
            {/* error handler */}
            <ToastContainer
                position="top-right"
                theme="light"
                autoClose={2000}
                style={{ width: '500px' }}
            />
            <div className="modal-container">
            {isVisible && (
                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                <ModalDetails
                    title='Detalles:'
                    onCloseModal={() => setIsVisible(false)}
                    type='small'
                    land={landWatcher}
                    submit={handleSubmit(submit)}
                >
                    <LandDetails land={landWatcher}/>
                </ModalDetails>
                </div>
            )}
            </div>
        </>
    );
}


export default SelectLand