import React from 'react'
import "./SectionDelete.css"
import { useTranslation } from 'react-i18next'

export default function SectionDelete(
  {onCancel, onConfirm}
) {

  const { t } = useTranslation();

  return (
    <div className='SectionDelete'>
      <div
        className='flex flex-col justify-center items-center bg-white p-2 rounded-md w-full'
      >
        <div className='text-sm lg:text-2xl'>
          { t('sectionDeletePage.title') }
        </div>
        <div className='mt-2 text-xs lg:text-base'>
          { t('sectionDeletePage.description') }
        </div>
        <div className='flex flex-col justify-center items-center lg:items-start lg:flex-row lg:justify-between p-2 lg:p-4 w-full align-bottom mt-2 lg:mt-4'>
          <div>
            <button
              className='p-2 bg-black text-white rounded-md text-xs lg:text-base'
              onClick={onCancel}
            >
              { t('global.cancel') }
            </button>
          </div>
          <div className='mt-2 lg:mt-0'>
            <button
              className='p-2 bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo text-white rounded-md text-xs lg:text-base'
              onClick={onConfirm}
            >
              { t('global.delete') }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
