import { t } from "i18next";
import Card from "../../../../../components/card/Card";
import { PrimaryButton } from "../../../../../components/primary/PrimaryButton";
import { useCallback, useEffect, useState } from "react";
import { getPaymentMethod } from "../../../../../services/PaymentService";

export const ShowCard = ({toggle, setToggle}) => {

    const [card, setCard] = useState(null);

    const getCard = useCallback(async () => {
    // retrieve cards
        try {
            const response = await getPaymentMethod();
            setCard(response.data);
        } catch (error) {
            console.error(error)
        }
    }, []);

    
    useEffect(() => {
        getCard();
    }, [toggle]);

    if (!card) {
        return (
            <div className='mt-2'>
                <div className='mb-2 no-card'>{t('showCard.noPaymentMethod')}</div>
                <PrimaryButton onClick={(e) => {e.preventDefault(); setToggle(true);}}>
                    {t('showCard.addPaymentMethod')}
                </PrimaryButton>
            </div>
        )
    }

    return (
        <div>
        <Card className={!card ? 'flex justify-center p-5 mt-2' : 'mt-2'}>
            <div className='grid grid-cols-4 p-3 gap-28 payment-method-header rounded-t-md'>
                <div>{t('showCard.card')}</div>
                <div>{t('showCard.type')}</div>
                <div>{t('showCard.country')}</div>
                <div></div>
            </div>
            <div className='grid grid-cols-4 p-3 pt-0 gap-28'>
                <div>************* - {card?.last4}</div>
                <div>{card?.brand}</div>
                <div>{card?.country}</div>
                <div className='cursor-pointer update-password' onClick={() => setToggle(true)}>{t('showCard.updatePaymentMethod')}</div>
            </div>
        </Card>
        </div>
    )
}