import { IoCloseCircle } from 'react-icons/io5';
import React, { useCallback } from 'react';
import "./ModalDetails.css";
import { GradientButton } from '../../../../../components/gradientButton/GradientButton';
import { useAuth } from '../../../../../hooks/useAuth';
import { roles } from '../../../../../constants/roles';

export const ModalDetails = ({children, title = '', onCloseModal, land, submit}) => {

  const {user} = useAuth();

  const closeModal = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);
      
  return (
    <>
      <div className='modal-details'>
      <form onSubmit={submit}>
        <div className='overflow-y-hidden'>
          <div className={'modal-content-sm'}
          >
            <div className='sticky flex justify-between modal-header'>
              <div className='modal-title'>
                { title }
              </div>
              <div>
                <IoCloseCircle
                  className='text-2xl cursor-pointer text-primary' 
                  onClick={closeModal}
                />
              </div>
            </div>
            <div className={'modal-children-sm'}
            >
              { children }
            </div>
          </div>
        </div>
        {!user && (
          <div className='button-continue'>
            <GradientButton>{'CONTINUAR CON EL PROCESO'}</GradientButton>
          </div>
        )}
        {user && (land?.status === 'on_sale' && (user.user.roles[0].name.toLowerCase() === roles.SELLER
                  || user.user.roles[0].name.toLowerCase() === roles.COORDINATOR
                  || user.user.roles[0].name.toLowerCase() === roles.ORGANIZATION
                  || user.user.roles[0].name.toLowerCase() === roles.CLIENT)) && (
          <div className='button-continue'>
            <GradientButton>{'CONTINUAR CON EL PROCESO'}</GradientButton>
          </div>
        )}
      </form>
      </div>
    </>
  )
}