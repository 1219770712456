import React, { useEffect, useState } from 'react';
import "./ClientDetails.css";
import UserDetails from '../../../register/components/UserDetails';
import { t } from 'i18next';
import { Selector } from '../../../../../components/selector/Selector';
import { useForm } from 'react-hook-form';
import Card from '../../../../../components/card/Card';
import { useAuth } from '../../../../../hooks/useAuth';
import { roles } from '../../../../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLandsFromUser } from '../../../../../features/userSlice/userSlice';
import { addMilliseconds } from 'date-fns';

export default function ClientDetails() {
  const [first, setFirst] = useState(true);

  const dispatch = useDispatch();
  const userAuth = useAuth();
  const {lands} = useSelector(state => state.user);
  const userSelected = useSelector(state => state.user.client);
  let user = null;
  const defaultValues = {
    land: {
      value: lands[0]?.id,
      label: lands[0]?.identifying_label
    }
  };

  console.log('defaultValues', defaultValues)

  // TODO refactor user selected
  if (userAuth.user.user.roles[0].name.toLowerCase() === roles.CLIENT) {
    user = userAuth.user.user;
  } else {
    user = userSelected;
  }

  const {control, watch} = useForm({
    defaultValues,
    mode: 'onChange'
  });

  const landSelected = watch('land');

  useEffect(() => {
    // fetch lands
    let params = null;
    if (userAuth.user.user.roles[0].name.toLowerCase() !== roles.CLIENT) {
      params = {user: userSelected.id}
    }
    if (first) {
      dispatch(fetchLandsFromUser(params));
      setFirst(false);
    }
    if (lands.length > 0 && !landSelected.value) {
      landSelected.value = lands[0]?.id;
      landSelected.label = lands[0]?.identifying_label;
    }
  }, [defaultValues, dispatch, first, landSelected, lands, userAuth.user.user.roles, userSelected.id])

  useEffect(() => {}, [lands])

  const clientHasLands = () => {
    return lands.length > 0;
  }

  const landsToOptions = () => {
    if (lands.length > 0) {
      return lands.map(l => ({value: l.id, label: l.identifying_label}));
    } else {
      return []
    }
  }

  return (
    <div className='p-5'>
      <div>
        <h2 className='mb-10 userTitle'>{t('clientDashboard.title')}</h2>
      </div>
      <div className="w-1/6">
        <Selector
          name="land"
          options={landsToOptions()}
          defaultValue={defaultValues}
          control={control}
        />
      </div>

      { (clientHasLands &&  landSelected.value) ? 
      <Card className='mt-10 gray-card'>
        <UserDetails user={user} land={landSelected}/>
      </Card>
         : clientHasLands ? "Seleccione el terreno" : "Cliente no cuenta con terrenos"
      }
    </div>
  )
}
