export const Manzana19Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1874_3717)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('12'))} d="M573.89 627.658L619.213 610.662L603.431 574.241L558.917 590.833L573.89 627.658Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('13'))} d="M590.076 663.27L574.294 627.658L619.213 610.662L634.995 647.083L590.076 663.27Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('14'))} d="M590.075 663.269L635.399 647.082L640.255 658.818V680.668C640.255 684.112 639.442 687.608 637.142 690.171C630.802 697.237 622.336 698.741 615.74 698.258C611.107 697.92 607.447 694.613 605.195 690.55L590.075 663.269Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M640.255 658.816V569.788L601.811 570.193L640.255 658.816Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M603.025 574.24L590.48 536.605L543.942 553.602L558.915 590.832L603.025 574.24Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M640.255 569.791V529.323H588.053L601.811 570.195L640.255 569.791Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M543.943 553.603L532.612 515.968L577.935 497.758L590.48 536.606L543.943 553.603Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M640.256 529.323V496.545L577.936 497.759L588.053 529.323H640.256Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M640.255 496.544V468.217H570.247L577.936 497.758L640.255 496.544Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M520.068 468.217H570.247L577.936 497.758L532.612 515.968L520.068 468.217Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M520.067 468.216L509.546 414.395H545.561V468.216H520.067Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M577.936 414.395H545.562V468.216H577.936V414.395Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M610.31 414.395H577.936V468.216H610.31V414.395Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M640.256 414.395H610.31V468.216H640.256V414.395Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1874_3717" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1874_3717" transform="matrix(0.00113825 0 0 0.00147527 -2.51039 -1.53637)"/>
        </pattern>
        <image id="image0_1874_3717" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}