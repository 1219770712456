const RoundedX = ({size = '120', color = "#F45D85"}) =>{
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 120 120" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M120 60C120 93.1371 93.1371 120 60 120C26.863 120 0 93.1371 0 60C0 26.863 26.863 0 60 0C93.1371 0 120 26.863 120 60ZM83.7143 46.7842L69.33 60.2612L82.8078 74.6455L73.2184 83.7169L59.7405 69.3327L45.2266 82.8097L36.2841 73.2202L50.6699 59.7432L37.1921 45.3581L46.7816 36.2875L60.2594 50.6717L74.6436 37.1939L83.7143 46.7842Z" fill={color}/>
        </svg>
        </>
    )
}

export default RoundedX;