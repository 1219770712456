import React from 'react'
import './Header.css'
import Navbar from '../navbar/Navbar'
import titleBanner from '../../../../../assets/home/images/Zihuabnner.png'


export default function Header() {
  return (
    <div className='Header'>
      <Navbar />
      <div className='flex flex-col items-center lg:items-start h-screen align-bottom'>
        <div className="flex justify-center  items-end w-100 h-100 pb-40 md:pb-56 lg:pb-28">
          <div className='logo-content-width flex flex-col justify-content-center'>
            <img
              src={titleBanner}
              alt='Zihua' 
              className='title-banner self-center'
            />
            <div className='title pt-6 lg:pt-0'>
              Zihua Investments te presenta una oportunidad única
            </div>
          </div>
        </div>
        

        {/* <div className='explore flex justify-center lg:justify-center'>
          <a href='#contact-form'>
            <button
              className='rounded-md text-white py-2 px-10 text-sm bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo uppercase'
            >
              Explora más
            </button>
          </a>
        </div> */}
      </div>
    </div>
  )
}
