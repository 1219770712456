import api from "../api/axios";
import { endpoints } from "../api/endpoints";

export const getAmortizationList = async (page) => {
  try {
      return await api.get(endpoints.amortizations + `/get-amortizations?page=${page}`);
  } catch (error) {
      return error.message;
  }
};

export const getAmortizationSchedule = async (clientId, landId, page) => {
  try {
      return await api.get(endpoints.amortizations + `/get-amortization-schedule/${clientId}/${landId}?page=${page}`);
  } catch (error) {
      return error.message;
  }
};

export const getAmortizationsClient = async (clientId) => {
  try {
    return await api.get(endpoints.amortizations + `/get-amortization-client/${clientId}`);
  } catch (error) {
      return error.message;
  }
}
