import { useSelector } from "react-redux";
import Splat from '../../../../../assets/icons/Splat';
import { PrimaryButton } from "../../../../../components/primary/PrimaryButton";
import { t } from "i18next";

export const InitialPayment = () => {

    const {latestProgress} = useSelector(state => state.payment);

    return (
      <>
      {/* Info icon */}
      <div className="flex items-center justify-center mt-3 mb-3">
          <div className=''>
          <Splat label={t('wizard.makePayment')}/>
          </div>
      </div>
      {/* Payment link */}
      <div className='justify-center w-full text-center'>
        <a href={latestProgress.data.link} target="_blank" rel="noreferrer">
        <PrimaryButton>
          {t('userDetails.pay')}
        </PrimaryButton>
        </a>
      </div>
      </>
    )
  }