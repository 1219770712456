import api from '../../api/axios'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { endpoints } from '../../api/endpoints'

export const assignLand = createAsyncThunk("land/assign", async ({land, params}) => {
    const response = await api.post(`${endpoints.landsAssign}/${land}`, params)
    return {
        data: response.data,
        params
    }
})

export const createAmortization = createAsyncThunk("amortization/create", async (payload) => {
    const response = await api.post(`${endpoints.amortizations}/create-schedule`, payload)
    return {
        data: response.data,
    }
})

export const saveProgress = createAsyncThunk("wizard/progress", async ({user, payload}) => {
    const response = await api.post(`${endpoints.progress}/${user}`, payload);
    return {
        data: response.data
    }
})

export const fetchProgress = createAsyncThunk("fetch/progress", async ({user, params}) => {
    const response = await api.get(`${endpoints.fetchProgress}/${user}`, {params});
    return {
        data: response.data
    }
})


const initialState = {
    link: '',
    clientSelected: JSON.parse(localStorage.getItem('user_selected')),
    landSelected: null,
    latestProgress: null
}

export const paymentSlice = createSlice({
    name: "payments",
    initialState,
    reducers: {
        selectClient: (state, action) => {
            localStorage.setItem('user_selected', JSON.stringify(action.payload))
            state.clientSelected = action.payload
        },
        selectLand: (state, action) => {
            state.landSelected = action.payload
        },
        setManual: (state, action) => {
            state[action.payload.field] = action.payload.value;
        },
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProgress.fulfilled, (state, action) => {
            state.latestProgress = action.payload.data
        })
    }
})
export const {selectClient, selectLand, reset, setManual} = paymentSlice.actions
export default paymentSlice.reducer
