export const ProgressBar = ({progress = '25'}) => {
    return (
        <>
        <div className="w-full rounded-md bg-neutral-200">
        <div
            className="bg-primary p-0.5 h-5 text-center text-xs font-medium leading-none text-primary-100 rounded-s-md"
            style={{width: `${progress}%`}}>
        </div>
        </div>
        </>
    )
}