import React, { useCallback, useEffect } from 'react'
import "./Modal.css"
import { IoCloseCircle } from 'react-icons/io5'

export default function Modal(
  {onCloseModal, children, type = 'large', title = ''}
) {

  const closeModal = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='Modal'>
      <div className='overflow-y-hidden'>
        <div className={
          type === 'large'
            ? 'modal-content'
            : type === 'medium'
              ? 'modal-content-md'
              : 'modal-content-sm'
          }
        >
          <div className='modal-header flex justify-between sticky'>
            <div className='modal-title'>
              { title }
            </div>
            <div>
              <IoCloseCircle
                className='text-2xl text-primary cursor-pointer' 
                onClick={closeModal}
              />
            </div>
          </div>
          <div className={
            type === 'large'
              ? 'modal-children'
              : type === 'medium'
                ? 'modal-children-md'
                : 'modal-children-sm'
            }
          >
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}
