import { toast } from "react-toastify";
import api from "../../../../../api/axios";
import "./ShowFile.css";

export const ShowFile = ({file, status}) => {
    
    const updateFile = async (file, status) => {
        return await api.put(`api/files/${file.id}`, {status});
    }

    const setStatusHandler = async (status) => {
        try {
            const response = await updateFile(file, status);
            if (response.data.status === 'success') {
                if (status === 'rejected') {
                    toast.error('El archivo ha sido rechazado');
                }
                
                if (status === 'approved') {
                    toast.success('El archivo ha sido aprobado');
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="p-2">
            <div className="flex gap-2">
            <div className="w-4/5">
            {file?.file_path && (<embed
                src={`${file.file_path}`}
                width="100%"
                height="550px"
                type="application/pdf"
                />)
            }
            </div>
            <div className="flex items-end justify-center w-1/5 align-baseline">
                <div className="">
                    <button onClick={() => setStatusHandler('approved')} className="w-full p-2 mb-2 text-white rounded-md bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo">APROBAR</button>
                    <button onClick={() => setStatusHandler('rejected')} className="p-2 w-full text-white rounded-md bg-[#F45D85]">RECHAZAR</button>
                </div>
            </div>
            </div>
        </div>
    )
}