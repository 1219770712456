import api from '../api/axios'
import { endpoints } from '../api/endpoints'

export const saveRegisterContact = async (data) => {
  try {
      return await api.post(endpoints.contacts, data);
  } catch (error) {
      return error.message;
  }
};

export const getRegisterContacts = async (page, sort, order) => {
  try {
    return await api.get(endpoints.contacts + `?page=${page}&sort=${sort}&order=${order}`);
  } catch (error) {
    return error.message;
  }
};

export const getOrganizationContacts = async (page, sort, order) => {
  try {
    return await api.get(endpoints.fuerzaMigranteContacts + `?page=${page}&sort=${sort}&order=${order}`);
  } catch (error) {
    return error.message;
  }
};