import { createContext, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import api from "../api/axios";
import { endpoints } from "../api/endpoints";
import { useDispatch } from "react-redux";
import { addUser, removeUser } from "../features/userSlice/userSlice";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("userToken", null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // call this function when you want to authenticate the user
  const login = useCallback(async (data) => {
    try {
      const response = await api.post(
        endpoints.login,
        data
      );
      const responseData = response.data.data;
      if (response.status === 200) {
        const userInfo = {
          token: responseData.token,
          user: responseData.user,
          permissions: responseData.permissions
        };
        setUser(userInfo);
        dispatch(addUser(userInfo));
        navigate("/admin/home");
      }
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Ocurrió un error');
    }
  }, [dispatch, navigate, setUser]);

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    setUser(null);
    dispatch(removeUser(''));
    navigate("/login", { replace: true });
  }, [dispatch, navigate, setUser]);

  const recoveryPassword = useCallback(async (phone) => {
    try {
      const response = await api.post(
        endpoints.recovery,
        {phone}
      );
      return response;
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Ocurrió un error');
    }
  }, []);

  const updateMe = useCallback(async () => {
    try {
      const response = await api.get('api/me');
      const responseData = response.data.data;
      if (response.status === 200) {
        const userInfo = {
          token: responseData.token ?? user.token,
          user: responseData.user,
          permissions: responseData.permissions ?? user.permissions
        };
        setUser(userInfo);
        dispatch(addUser(userInfo));
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response ? error.response.data.message : 'Ocurrió un error');
    }
  }, [dispatch, setUser, user]);

  const updateProfileOnMemory = useCallback(async (data) => {
    try {
      const response = await api.put(endpoints.profile, data);
      const responseData = response.data.data;
      if (response.status === 200) {
        const userInfo = {
          token: responseData.token ?? user.token,
          user: responseData,
          permissions: responseData.permissions ?? user.permissions
        };
        setUser(userInfo);
        dispatch(addUser(userInfo));
        navigate("/admin/home");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response ? error.response.data.message : 'Ocurrió un error');
    }
  }, [dispatch, navigate, setUser, user]);

  const resetPassword = useCallback(async (params) => {
    try {
      const response = await api.post(
        endpoints.reset,
        params
      );
      return response;
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Ocurrió un error');
    }
  }, []);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      recoveryPassword,
      resetPassword,
      updateProfileOnMemory,
      updateMe
    }),
    [user, login, logout, recoveryPassword, resetPassword, updateProfileOnMemory, updateMe]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
