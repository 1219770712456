import React from 'react'
import './Slider.css'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

export default function Slider(
  {items = []}
) {

  const breakpoints = {
    1024: {
      perPage: 3,
    },
    768: {
      perPage: 3,
    },
    640: {
      perPage: 1,
    },
  };
  

  const options = {
    type: 'loop', // Enable loop playback
    perPage: 3, // Show 3 slides at a time
    arrows: true, // Show navigation arrows
    breakpoints,
    perMove   : 1,
    pagination: false,
    cloneStatus:false,
    autoplay: true,
  };

  return (
    <div className='Slider'>
      <Splide options={options}>
        {items.map((slide, index) => (
          <SplideSlide key={ index } >
            <div className='flex justify-center items-center'>
              <div className='image-container'>
                <div className='flex justify-center'>
                  <img src={slide.image} alt={slide.title} className='w-full' />
                </div>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  )
}
