import { t } from "i18next";
import RoundedCheck from "../../../assets/icons/RoundedCheck";
import Card from "../../../components/card/Card";
import "./Success.css";
import { SecondaryButton } from "../../../components/secondary/SecondaryButton";
import { PrimaryButton } from "../../../components/primary/PrimaryButton";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {

    const navigate = useNavigate();

    return (
        <div className="justify-center p-10">
            <Card className="p-10 m-20">
                <div className="flex justify-center">
                    <RoundedCheck/>
                </div>
                <div className="flex justify-center">
                    <h1>{t('successPage.title')}</h1>
                </div>
                <div className="flex justify-center">
                    {t('successPage.description')}
                </div>
            </Card>
            <div className="">
                <div className="flex justify-center mb-2">
                    <SecondaryButton disabled>
                        {t('successPage.download')}
                    </SecondaryButton>
                </div>
                <div className="flex justify-center">
                    <PrimaryButton onClick={() => navigate('/admin/home')}>
                        {t('successPage.continue')}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess;