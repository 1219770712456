import React from 'react'
import './LineChart.css'
import ReactEcharts from 'echarts-for-react';
import { graphic } from 'echarts';

export default function LineChart(
  {title, bgColor1, bgColor2, chartData = {}}
) {

  const options = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: "category",
      data: chartData?.labels
    },
    yAxis: {
      type: "value"
    },
    series: [
      {
        data: chartData?.values,
        type: 'line',
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: bgColor1
            },
            {
              offset: 1,
              color: bgColor2
            }]),
          opacity: 1
        }
      }
    ],
    tooltip: {
      trigger: "axis"
    }
  }

  return (
    <div className='LineChart'>
      <div className='title'>
        {title}
      </div>
      <ReactEcharts
        option={options}
        style={{height: '200px'}}
      ></ReactEcharts>
    </div> 
  )
}
