import { t } from 'i18next';
import Card from '../../../../../components/card/Card';
import { SandClock } from '../../../../../assets/icons/SandClock';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../../../components/primary/PrimaryButton';

export const PendingApproval = () => {

    const navigate = useNavigate();

    return (
        <div className="justify-center p-10">
            <Card className="p-20 m-20">
                <div className="flex justify-center">
                    <SandClock/>
                </div>
                <div className="flex justify-center">
                    <h1>{t('Aprobación pendiente')}</h1>
                </div>
                <div className="flex justify-center">
                    {t('Tus documentos están siendo procesados')}
                </div>
            </Card>
            <div className='flex justify-center'>
                <PrimaryButton className={'cursor-pointer'} onClick={() => navigate(-1)}>Continuar</PrimaryButton>
            </div>
        </div>
    )
}