import React, { useState } from 'react'
import "./ProtectedLayout.css"
import { Navigate, useOutlet } from 'react-router-dom'
import { useAuth } from "../../hooks/useAuth";
import SideMenu from './components/sideMenu/SideMenu';
import NavBar from '../navBar/NavBar';
import { ToastContainer } from 'react-toastify';
import { Letter } from '../../assets/icons/Letter';
import Modal from '../modal/Modal';
import { SendMessage } from './components/sideMenu/SendMessage';
import { t } from 'i18next';
import { roles } from '../../constants/roles';

export default function ProtectedLayout() {
  const { user } = useAuth();
  const outlet = useOutlet();
  const [toggle, setToggle] = useState(false);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div
      className='ProtectedLayout'
    >
      <div className='flex gap-2'>
        <div>
          <SideMenu />
        </div>
        <div className='flex flex-col'>
          <div
            className='flex flex-col w-screen h-screen content'
          >
            <NavBar />
            <div className="pl-16 wrap-all-the-things lg:pl-40">
              {outlet}
              {(user.user.roles[0].name.toLowerCase() === roles.CLIENT) && (
              <div className='fixed bottom-0 w-10/12'>
                <div className='flex justify-end w-full p-2'>
                  <div onClick={() => setToggle(true)} className='cursor-pointer'>
                  <Letter/>
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
        {toggle && (
          <>
            <div className='block lg:hidden'>
              <Modal
                onCloseModal={() => setToggle(false)}
                title={t('sendMessage.title')}
                type="medium"
              >
                <SendMessage/>
              </Modal>
            </div>
            <div className='hidden lg:block'>
              <Modal
                onCloseModal={() => setToggle(false)}
                title={t('sendMessage.title')}
                type="small"
              >
                <SendMessage/>
              </Modal>
            </div>
          </>
        )}
        <ToastContainer
          position="top-right"
          theme="light"
          autoClose={2000}
          style={{ width: '500px' }}
        />
      </div>
    </div>
  )
}
