const AvatarDetails = ({size = '73'}) => {
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
        <mask id="mask0_305_818"  maskUnits="userSpaceOnUse" x="2" y="2" width="69" height="69">
        <path d="M36.5714 70.9999C17.5239 71.0475 2.04773 55.6349 2.00011 36.5874C1.95249 17.5399 17.365 2.06384 36.4125 2.01622C55.46 1.9686 70.9361 17.3812 70.9837 36.4287C71.0313 55.492 55.6347 70.9681 36.5714 70.9999Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_305_818)">
        <path d="M41.0789 52.254C39.5392 51.8572 37.9201 51.6509 36.2535 51.6509C34.5868 51.6509 32.9836 51.8731 31.4439 52.2699C22.8726 54.508 16.46 62.492 16.0473 72.1428L31.4916 72.111L41.1266 72.0951L56.5709 72.0634C56.1106 62.4127 49.6503 54.4445 41.0789 52.254Z" fill="#C87029"/>
        <path d="M41.0789 52.254C39.5392 51.8572 37.9201 51.6509 36.2535 51.6509C34.5868 51.6509 32.9836 51.8731 31.4439 52.2699C22.8726 54.508 16.46 62.492 16.0473 72.1428L31.4916 72.111L41.1266 72.0951L56.5709 72.0634C56.1106 62.4127 49.6503 54.4445 41.0789 52.254Z" fill="#C87029"/>
        <path d="M36.2696 56.238C33.6188 56.238 31.4442 54.0793 31.4442 51.4285L31.4285 45.6508C31.4285 43 33.587 40.8254 36.2378 40.8254C38.8886 40.8254 41.0632 42.9842 41.0632 45.6349L41.0791 51.4126C41.0791 54.0634 38.9204 56.238 36.2696 56.238Z" fill="#FCCB82"/>
        <path d="M36.2694 56.2382C33.6186 56.2382 31.444 54.0795 31.444 51.4287V54.0954C31.444 56.7461 33.6186 58.9049 36.2694 58.9049C38.9202 58.9049 41.0789 56.7303 41.0789 54.0795V51.4128C41.0789 54.0636 38.9202 56.2382 36.2694 56.2382Z" fill="#FFCB7C"/>
        <path d="M47.936 22.8573L47.9519 33.6668C47.9519 35.8572 47.4759 38.0477 46.5076 40.0159C44.3489 44.4127 41.9679 47.3968 36.4917 47.6984H35.9998C31.8887 48.3175 27.4284 44.8096 25.0633 38.635C24.6506 37.5557 24.476 36.4128 24.476 35.2541L24.4443 22.905C24.4284 16.9367 29.587 12.0479 35.9203 12.0479H36.4123C42.7297 12.032 47.9202 16.8891 47.936 22.8573Z" fill="#FCCB82"/>
        <path d="M36.4123 11.8733H35.9203C29.6029 11.8892 24.4443 16.7781 24.4443 22.7304V25.3971C24.4284 19.4289 29.587 14.54 35.9203 14.54H36.4123C42.7297 14.5241 47.9202 19.3971 47.936 25.3495V22.6828C47.9202 16.7305 42.7297 11.8575 36.4123 11.8733Z" fill="#DCA163"/>
        <path opacity="0.4" d="M36.4918 47.6508H35.9999C34.3808 47.6508 32.8253 47.3334 31.4285 46.762V49.4286C32.8412 50 34.3808 50.3333 35.9999 50.3175H36.4918C38.1109 50.3175 39.6664 49.9842 41.0632 49.3969V46.7302C39.6664 47.3175 38.1267 47.6508 36.4918 47.6508Z" fill="#F09F78"/>
        <path d="M37.8885 36.1272H34.8409C34.8409 36.1272 34.6822 37.4129 35.8568 37.397H36.8726C38.0631 37.4129 37.8885 36.1272 37.8885 36.1272Z" fill="#DD7B3E"/>
        <path d="M47.9522 27.3972C47.9522 26.6035 48.492 25.8575 49.2697 25.6988H49.2856C50.2221 25.5242 51.0634 26.3178 51.0634 27.3337V30.2384C51.0634 30.8733 50.5871 31.3812 49.9998 31.3971H47.9363L47.9522 27.3972Z" fill="#FBBD7A"/>
        <path d="M49.3013 25.6987H49.3489C50.095 25.794 50.6822 26.4924 50.6981 27.3336V30.2384C50.6981 30.8733 50.222 31.3812 49.6347 31.3971H47.9362L47.9203 27.0162C47.9203 26.5717 48.1585 26.159 48.5553 25.9685C48.7775 25.8416 49.0314 25.7463 49.3013 25.6987Z" fill="#FBBD7A"/>
        <path d="M24.4603 26.2067C24.4603 26.2067 23.8728 25.6829 23.1109 25.54C22.1744 25.3654 21.3332 26.1591 21.3332 27.1908V30.0956C21.3332 30.7305 21.8094 31.2384 22.3967 31.2384H24.4603V26.2067Z" fill="#FBBD7A"/>
        <path opacity="0.5" d="M21.7142 30.0955V27.1908C21.7142 26.3336 22.3015 25.6511 23.0475 25.54C22.1427 25.413 21.3331 26.1908 21.349 27.1908V30.0955C21.349 30.7304 21.8252 31.2383 22.4125 31.2383H22.7776C22.2062 31.2383 21.7301 30.7304 21.7142 30.0955Z" fill="#F09F78"/>
        <path d="M23.1269 25.5398H23.0476C22.3016 25.635 21.7144 26.3335 21.7144 27.1906V30.0953C21.7144 30.7302 22.1904 31.2382 22.7777 31.2382H24.4762L24.4603 26.2065C24.4603 26.2065 23.8729 25.6827 23.1269 25.5398Z" fill="#FBBD7A"/>
        <path d="M41.0789 51.4129V49.2859C39.6821 49.8732 38.1266 50.2065 36.5075 50.2065H36.0154C34.3963 50.2065 32.8408 49.8891 31.444 49.3177V51.4446C31.444 51.6827 31.4758 51.9049 31.5075 52.143L41.0789 51.6827C41.0631 51.5875 41.0789 51.5081 41.0789 51.4129Z" fill="#FCCB82"/>
        <path d="M41.063 51.6668C40.936 54.2064 38.8249 56.2382 36.2694 56.2382C33.8567 56.2382 31.8408 54.4445 31.4916 52.1271L30.8251 52.1588L30.2378 52.2223V53.2699L32.3647 60.7778L36.2217 72.0952L41.7613 51.6191L41.063 51.6668Z" fill="#D3D5D6"/>
        <rect x="40.2694" y="29.9526" width="4.76187" height="1.58729" fill="url(#pattern0)"/>
        <path d="M42.7459 30.5718C43.3173 30.5718 43.7775 30.1114 43.7616 29.54V29.3654C43.7616 28.794 43.3015 28.3336 42.73 28.3495C42.1586 28.3495 41.6983 28.8099 41.7141 29.3813V29.5559C41.7141 30.1114 42.1904 30.5718 42.7459 30.5718Z" fill="#633223"/>
        <rect x="27.5713" y="29.9526" width="4.76187" height="1.58729" fill="url(#pattern1)"/>
        <path d="M30.1743 30.6032C30.7458 30.6032 31.2061 30.1429 31.1902 29.5715V29.3969C31.1902 28.8254 30.7299 28.3651 30.1584 28.381C29.587 28.381 29.1267 28.8413 29.1425 29.4127V29.5873C29.1425 30.1429 29.6188 30.6032 30.1743 30.6032Z" fill="#633223"/>
        <path d="M40.1429 41.6349C40.0635 41.6349 40 41.6667 39.9365 41.7302C39.9365 41.7302 39.4761 41.8413 38.8094 41.9524C37.6665 42.1429 36.4762 42.0794 35.3651 41.7302C35.1587 41.6667 34.9365 41.6032 34.6984 41.5556C32.7301 41.1112 33.5238 42.3175 33.5238 42.3175L33.5556 42.3334C33.5714 42.3492 33.5715 42.381 33.5873 42.3969C34.3492 43.0794 35.5873 43.4921 36.9842 43.4762C38.381 43.4445 39.6032 42.9841 40.3333 42.2857C40.5873 42.0476 40.4444 41.6349 40.1429 41.6349Z" fill="#B54F4B"/>
        <path d="M31.4603 27.4446C31.254 27.4288 31.0636 27.397 30.9366 27.3176C30.1112 26.8732 28.5715 27.0002 28.143 27.1748C28.016 27.2224 27.8414 27.2065 27.762 27.1113C27.6827 27.0319 27.7145 26.9367 27.8256 26.8732C28.3653 26.6192 30.4445 26.2541 32.0635 26.9684C32.3651 27.0954 32.3176 27.3018 31.9525 27.397C31.8096 27.4288 31.6349 27.4446 31.4603 27.4446Z" fill="#541F13"/>
        <path d="M30.6503 53.0954L36.2694 55.651L42.555 53.0637L49.555 72.0795L27.3012 72.1271L26.7457 60.143L30.6503 53.0954Z" fill="#C87029"/>
        <path d="M32.7142 54.6824C32.7142 54.6824 36.4127 52.857 39.5555 54.984C39.9206 55.238 36.2379 56.5396 36.2379 56.5396L33.619 55.6031L32.7142 54.6824Z" fill="#151719"/>
        <path d="M29.1746 50.6509L31.4286 49.6509V52.1906L29.1746 50.6509Z" fill="#502813"/>
        <path d="M29.1746 50.6511L35.111 53.159L36.2379 56.5241L29.1904 54.3654L29.1746 50.6511Z" fill="#6D3616"/>
        <path d="M43.3331 50.6191L41.0791 49.635L41.095 52.1747L43.3331 50.6191Z" fill="#502813"/>
        <path d="M43.333 50.6191L37.4123 53.1588L36.2853 56.5239L43.333 54.3334V50.6191Z" fill="#6D3616"/>
        <path d="M36.3171 72.1112C36.1584 72.1112 36.0313 71.889 36.0313 71.6033L35.9998 56.397C35.9998 56.1113 36.1107 56.5082 36.2695 56.5082C36.4282 56.5082 36.587 56.0796 36.587 56.3653L36.6186 71.6033C36.6186 71.8731 36.4917 72.1112 36.3171 72.1112Z" fill="#6D3616"/>
        <path d="M37.333 58.3334C37.5259 58.3334 37.6822 58.1771 37.6822 57.9842C37.6822 57.7914 37.5259 57.635 37.333 57.635C37.1402 57.635 36.9839 57.7914 36.9839 57.9842C36.9839 58.1771 37.1402 58.3334 37.333 58.3334Z" fill="#6D3616"/>
        <path d="M37.3488 60.778C37.5417 60.778 37.698 60.6217 37.698 60.4288C37.698 60.2359 37.5417 60.0796 37.3488 60.0796C37.1559 60.0796 36.9995 60.2359 36.9995 60.4288C36.9995 60.6217 37.1559 60.778 37.3488 60.778Z" fill="#6D3616"/>
        <path d="M36.9995 62.8573C36.9995 63.0477 37.1583 63.2065 37.3488 63.2065C37.5393 63.2065 37.698 63.0477 37.698 62.8573C37.698 62.6668 37.5393 62.5081 37.3488 62.5081C37.1583 62.5081 36.9995 62.6668 36.9995 62.8573Z" fill="#6D3616"/>
        <path d="M37.0154 65.2857C37.0154 65.4762 37.1743 65.6349 37.3648 65.6349C37.5553 65.6349 37.714 65.4762 37.714 65.2857C37.714 65.0953 37.5553 64.9365 37.3648 64.9365C37.1584 64.9524 37.0154 65.0953 37.0154 65.2857Z" fill="#6D3616"/>
        <path d="M37.0154 67.7303C37.0154 67.9208 37.1743 68.0795 37.3648 68.0795C37.5553 68.0795 37.714 67.9208 37.714 67.7303C37.714 67.5398 37.5553 67.3811 37.3648 67.3811C37.1743 67.3811 37.0154 67.5398 37.0154 67.7303Z" fill="#6D3616"/>
        <path d="M37.0154 70.1588C37.0154 70.3493 37.1743 70.508 37.3648 70.508C37.5553 70.508 37.714 70.3493 37.714 70.1588C37.714 69.9683 37.5553 69.8096 37.3648 69.8096C37.1743 69.8096 37.0154 69.9683 37.0154 70.1588Z" fill="#6D3616"/>
        <path d="M43.111 60.8889V63.3334C43.111 64.1111 43.6982 64.7619 44.4125 64.7619H45.7775C46.4918 64.7619 47.0792 64.1111 47.0792 63.3334V60.8889H43.111Z" fill="#6D3616"/>
        <path d="M41.7445 65.1252H45.6968V66.0935H41.7445V65.1252Z" fill="#502813"/>
        <path d="M26.8731 60.9209V63.3653C26.8731 64.1431 27.4603 64.7939 28.1746 64.7939H29.5396C30.2539 64.7939 30.8413 64.1431 30.8413 63.3653V60.9209H26.8731Z" fill="#6D3616"/>
        <path d="M25.5062 65.1216H29.4586V66.0898H25.5062V65.1216Z" fill="#502813"/>
        <path opacity="0.4" d="M46.0314 27.1115C46.0314 27.1115 40.8886 28.0798 39.6981 24.0322C38.5076 19.9846 36.0156 21.3021 35.9839 21.937C35.9839 21.937 34.2536 20.8259 33.1108 22.0163C31.968 24.2385 32.603 26.2703 26.4601 26.9846L27.6981 21.7941L30.7775 17.5878C30.7775 17.5878 35.8093 16.9688 36.1585 16.9688C36.5077 16.9688 39.7298 17.0322 40.4282 17.7782C41.1266 18.5243 44.3172 21.0163 44.5076 21.3496C44.6981 21.683 46.0314 24.1909 46.0314 24.1909V27.1115Z" fill="#F09F78"/>
        <path d="M52.1267 23.7146C51.1584 23.794 50.3331 23.0004 49.6824 21.7305C49.0316 20.4766 48.8409 18.302 48.7298 16.8893C48.1584 9.57185 42.2538 6.77825 35.7459 7.44491C30.6983 5.68302 24.2062 6.63536 22.984 18.0321C22.9046 18.8258 22.746 19.6036 22.4762 20.3496C21.7619 22.4131 19.8413 20.4766 18.5397 20.1909C18.5397 20.1909 18.4761 26.8257 23.5395 27.2225C24.0157 28.0479 24.4919 29.0955 24.5077 29.9527C24.5077 30.0797 24.5236 30.1749 24.5236 30.2384V32.0955C26.1268 30.3813 26.4762 28.3971 26.4603 26.9845C27.7619 27.0956 29.7141 25.8733 30.7141 24.0956C32.5712 20.8099 31.6505 19.3655 35.8093 20.3813C39.6188 18.8734 38.968 20.794 40.841 24.0797C42.0791 26.2543 44.5077 26.9209 46.0315 27.1114C46.1585 28.413 46.6346 30.0003 47.9521 31.3971V29.1432C47.9521 27.4924 49.9044 26.6353 49.9044 26.6353C51.8568 25.6353 52.1267 23.7146 52.1267 23.7146Z" fill="#2C2F31"/>
        <path d="M41.2694 27.4128C41.4757 27.3969 41.6661 27.3493 41.7931 27.2858C42.6185 26.8414 44.1582 26.9525 44.5868 27.1271C44.7138 27.1747 44.8883 27.1429 44.9677 27.0636C45.0471 26.9842 45.0155 26.889 44.9044 26.8255C44.3647 26.5715 42.2853 26.2223 40.6662 26.9366C40.3646 27.0636 40.4122 27.2699 40.7772 27.3651C40.9201 27.4128 41.0948 27.4286 41.2694 27.4128Z" fill="#541F13"/>
        </g>
        <path d="M36.5082 70.9998C55.5575 70.9998 71 55.5573 71 36.508C71 17.4586 55.5575 2.01611 36.5082 2.01611C17.4588 2.01611 2.01636 17.4586 2.01636 36.508C2.01636 55.5573 17.4588 70.9998 36.5082 70.9998Z" stroke="#2BBFDC" strokeWidth="4" strokeMiterlimit="10"/>
        <defs>
        <image id="image0_305_818" width="3" height="1"/>
        </defs>
        </svg>
    );
}

export default AvatarDetails;