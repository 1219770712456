import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "./LangButtons.css"
import langEs from '../../assets/images/lang-es.svg'
import langEn from '../../assets/images/lang-en.svg'

export default function LangButtons() {

  const [currentLanguage, setCurrentLanguage] = useState('');

  const { i18n } = useTranslation();

  const changeLang = useCallback((lang) => {
    if (currentLanguage !== lang) {
      i18n.changeLanguage(lang);
      setCurrentLanguage(lang);
    }
  }, [currentLanguage, i18n]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className='LangButtons'>
      <img
        src={langEs}
        alt='ES'
        className='mr-2 cursor-pointer'
        onClick={() => changeLang('es')}
      />
      <img
        src={langEn}
        alt='EN'
        className='cursor-pointer'
        onClick={() => changeLang('en')}
      />
    </div>
  )
}
