import { configureStore } from '@reduxjs/toolkit';

import userReducer from '../features/userSlice/userSlice'
import paymentSlice from '../features/userSlice/paymentSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    payment: paymentSlice
  },
});

export default store;
