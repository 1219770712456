import React, { useCallback, useEffect, useState } from 'react'
import "./LotLand.css"
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Modal from '../../../../../components/modal/Modal';
import LotLandForm from './components/lotLandForm/LotLandForm';
import LotLandDelete from './components/lotLandDelete/LotLandDelete';
import { getLotLands, deleteLotLand } from '../../../../../services/LotLandsService';
import _ from 'lodash'
import Pagination from '../../../../../components/pagination/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../../../../components/loading/Loading';
import { PrimaryButton } from '../../../../../components/primary/PrimaryButton';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { FaFileExcel } from 'react-icons/fa6';

export default function LotLand() {

  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [meta, setMeta] = useState(null);
  const [lotLands, setLotLands] = useState([]);
  const [first, setFirst] = useState(true);
  const [lotLandToEdit, setLotLandToEdit] = useState(null);
  const [lotLandToDelete, setLotLandToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const downloadFile = useCallback((theResponse) => {
    const url = window.URL.createObjectURL(new Blob([theResponse]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `lands.xlsx`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }, []);

  /**
   * Get section list in the page selected
   */
  const getLotLandList = useCallback(async (page, download = undefined) => {
    setLoading(true);
    const theLotLands = await getLotLands({page, download});
    setLoading(false);
    if (!download) {
      if (theLotLands.data.data) {
        theLotLands.data.data.forEach(theLotLand => {
          theLotLand.show = false;
        });
        setLotLands(theLotLands.data.data);
        setMeta(theLotLands.data.meta);
      }
    } else {
      downloadFile(theLotLands.data);
    }
  }, [downloadFile]);

  /**
   * Handle show section form
   */
  const handleShowForm = useCallback((theLotLand) => {
    const data = {
      id: theLotLand.id ? theLotLand.id : undefined,
      section: theLotLand.section_id,
      identifyingLabel: theLotLand.identifying_label,
      price: theLotLand.price,
      description: theLotLand.description,
      location: theLotLand.location,
      measurements: theLotLand.measurements,
      status: theLotLand.status,
      manzana: theLotLand.manzana,
      lote: theLotLand.lote,
      surface: theLotLand.surface
    };
    setLotLandToEdit(data);
    setShowForm(true);
  }, []);

  /**
   * Handle show modal confirm
   */
  const handleDelete = useCallback((theLotLand) => {
    setLotLandToDelete(theLotLand);
    setShowDelete(true);
  }, []);

  /**
   * Handle page selected on pagination
   */
  const onPageSelected = useCallback((pageSelected) => {
    if (meta && meta.current_page !== pageSelected) {
      getLotLandList(pageSelected);
    }
  }, [getLotLandList, meta]);

  /**
   * Handle success in form
   */
  const handleSuccess = useCallback(() => {
    getLotLandList(1);
  }, [getLotLandList]);

  /**
   * Handle close modal and reload users list
   */
  const handleCloseModal = useCallback(() => {
    getLotLandList(1);
    setShowForm(false);
    setLotLandToEdit(null);
  }, [getLotLandList]);

  /**
   * Handle confirm delete
   */
  const handleConfirmDelete = useCallback(async() => {
    try {
      setLoading(true);
      const response = await deleteLotLand(lotLandToDelete.id);
      setLoading(false);
      if (response.status === 200) {
        toast.success(t('sectionPage.delete'));
        setShowDelete(false);
        setLotLandToDelete(null);
        getLotLandList(1);
      }
    } catch (error) {
      toast.error(error.message)
    }
  }, [getLotLandList, lotLandToDelete, t]);

  const handleArrowClick = useCallback((lotLandSelected, show) => {
    const theIndex = lotLands.findIndex((theLotLand) => theLotLand.id === lotLandSelected.id);
    const currentLotLands = [...lotLands];
    currentLotLands[theIndex].show = show;
    setLotLands(currentLotLands);
  }, [lotLands]);

  const handleDownload = useCallback(async () => {
    getLotLandList(1, 'excel');
  }, [getLotLandList]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getLotLandList(1);
    }
  }, [first, getLotLandList]);

  return (
    <div className='LotLand'>
      { loading && <Loading /> }
      <div className='flex justify-between'>
        <div className='mt-2 mb-2'>
          <PrimaryButton
            onClick={() => setShowForm(true)}
          >
            { t('global.add') }
          </PrimaryButton>
        </div>
        <div>
          <FaFileExcel
            className='excel-icon'
            onClick={handleDownload}
          />
        </div>
      </div>

      {/* Vista móvil */}
      <div className='block lg:hidden'>
      {
          _.map(lotLands, (theLotLand, index) => (
            <div
              key={index}
              className='card-info'
            >
              <div className='flex justify-between px-4'>
                <div className='text-sm'>
                  {theLotLand.identifying_label}
                </div>
                <div>
                  {
                    !theLotLand.show &&
                    <BiSolidDownArrow
                      onClick={() => handleArrowClick(theLotLand, true)}
                    />
                  }
                  {
                    theLotLand.show &&
                    <BiSolidUpArrow
                      onClick={() => handleArrowClick(theLotLand, false)}
                    />
                  }
                </div>
              </div>
              {
                theLotLand.show &&
                <div>
                  <div className='grid grid-cols-2 gap-4 my-2'>
                    <div className='field-title'>
                      { t('lotLandPage.section') }
                    </div>
                    <div className='text-left field-value'>
                      {theLotLand.section.name}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('lotLandPage.price') }
                    </div>
                    <div className='text-left field-value'>
                      {theLotLand.price}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('lotLandPage.description') }
                    </div>
                    <div className='text-left field-value'>
                      {theLotLand.description}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('lotLandPage.location') }
                    </div>
                    <div className='text-left field-value'>
                      {theLotLand.location}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('lotLandPage.measurements') }
                    </div>
                    <div className='text-left field-value'>
                      {theLotLand.surface}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('lotLandPage.status') }
                    </div>
                    <div className='text-left field-value'>
                      { t(`lotLandFormPage.${theLotLand.status}`) }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('lotLandPage.client') }
                    </div>
                    <div className='text-left field-value'>
                      {theLotLand.client_id}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('global.options') }
                    </div>
                    <div className='text-left field-value'>
                      <div className='flex justify-end gap-2'>
                        <div
                          className='cursor-pointer text-primary'
                          onClick={() => handleShowForm(theLotLand)}
                        >
                          <FaEdit />
                        </div>
                        {
                          canDelete &&
                          <div
                            className='cursor-pointer text-primary'
                            onClick={() => handleDelete(theLotLand)}
                          >
                            <FaTrash />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>

      {/* Vista web */}
      <div className='hidden lg:block'>
        <table className="w-full table-auto">
          <thead className='text-left bg-header'>
            <tr>
              <th className='p-2 text-black'>
                { t('lotLandPage.identifying') }
              </th>
              <th className='p-2 text-black'>
                { t('lotLandPage.section') }
              </th>
              <th className='p-2 text-black'>
                { t('lotLandPage.price') }
              </th>
              <th className='p-2 text-black'>
                { t('lotLandPage.description') }
              </th>
              <th className='p-2 text-black'>
                { t('lotLandPage.location') }
              </th>
              <th className='p-2 text-black'>
                { t('lotLandPage.measurements') }
              </th>
              <th className='p-2 text-black'>
                { t('lotLandPage.status') }
              </th>
              <th className='p-2 text-black'>
                { t('lotLandPage.client') }
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody className='text-cell'>
            {
              _.map(lotLands, (theLotLand, index) => (
                <tr
                  className={`border-b border-white text-sm ${index % 2 === 0 ? 'bg-white' : 'bg-header'}`}
                  key={index}
                >
                  <td className='p-2 text-primary'>
                    {theLotLand.identifying_label}
                  </td>
                  <td className='p-2'>
                    {theLotLand.section.name}
                  </td>
                  <td className='p-2'>
                    {theLotLand.price}
                  </td>
                  <td className='p-2'>
                    {theLotLand.description}
                  </td>
                  <td className='p-2'>
                    {theLotLand.location}
                  </td>
                  <td className='p-2'>
                    {theLotLand.surface}
                  </td>
                  <td className='p-2'>
                    { t(`lotLandFormPage.${theLotLand.status}`) }
                  </td>
                  <td className='p-2'>
                    {theLotLand.client_id}
                  </td>
                  <td className='p-2'>
                    <div className='flex gap-2'>
                      <div
                        className='cursor-pointer text-primary'
                        onClick={() => handleShowForm(theLotLand)}
                      >
                        <FaEdit />
                      </div>
                      <div
                        className='cursor-pointer text-primary'
                        onClick={() => handleDelete(theLotLand)}
                      >
                        <FaTrash />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      <div className='flex justify-center w-full mt-4'>
        <Pagination
          meta={meta}
          onPageSelected={onPageSelected}
        />
      </div>

      {
        showForm &&
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
        >
          <LotLandForm
            onSuccess={handleSuccess}
            lotLandToEdit={lotLandToEdit}
          />
        </Modal>
      }

      {
        showDelete &&
        <Modal
          onCloseModal={() => setShowDelete(false)}
          type="small"
        >
          <LotLandDelete
            onCancel={() => setShowDelete(false)}
            onConfirm={handleConfirmDelete}
          />
        </Modal>
      }

      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
