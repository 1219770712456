import React from 'react'
import "./RegisterDelete.css"
import { useTranslation } from 'react-i18next';

export default function RegisterDelete(
  {onCancel, onConfirm}
) {

  const { t } = useTranslation();

  return (
    <div className='RegisterDelete'>
      <div
        className='flex flex-col justify-center items-center w-full'
      >
        <div className='text-2xl'>
          { t('registerDeletePage.title') }
        </div>
        <div className='mt-2'>
          { t('registerDeletePage.description') }
        </div>
        <div className='flex justify-between p-4 w-full align-bottom mt-4'>
          <div>
            <button
              className='p-2 bg-black text-white rounded-md'
              onClick={onCancel}
            >
              { t('global.cancel') }
            </button>
          </div>
          <div>
            <button
              className='p-2 bg-blue-500 text-white rounded-md'
              onClick={onConfirm}
            >
              { t('global.delete') }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
