import { useEffect, useState } from "react";
import { useAuth } from "../../../../../hooks/useAuth";
import { roles } from "../../../../../constants/roles";
import { getFiles, uploadFiles } from '../../../../../services/FileService';
import { toast } from "react-toastify";
import { t } from "i18next";
import { Pending } from "../../../../../assets/icons/Pending";
import { Approved } from "../../../../../assets/icons/Approved";
import { Rejected } from "../../../../../assets/icons/Rejected";
import Card from "../../../../../components/card/Card";
import Modal from "../../../../../components/modal/Modal";
import { UpdateFile } from "../updateFile/UpdateFile";
import { Download } from "../../../../../assets/icons/Download";
import { ShowFile } from "../showFile/ShowFile";

export const PendingApproval = ({client, land}) => {
    // Check viewer is seller or client
    const {user} = useAuth().user;
    const [files, setFiles] = useState(null);
    const [toggleUpdateFile, setToggleUpdateFile] = useState(false);
    const [fileSelected, setFileSelected] = useState();
    const [showFile, setShowFile] = useState(false);
  
    const fetchFiles = async () => {
      try {
        let response;
        if (user.roles[0].name.toLowerCase() === roles.CLIENT) {
          response = await getFiles({land_id: land.value});
        } else {
          // seller
          response = await getFiles({client_id: client.client.id, land_id: land.value});
        }
        setFiles(response.data);
      } catch (error) {
        toast.error(error.message);
      }
    }
  
    const actions = (file) => {
      if (file.status === 'pending' || file.status === 'rejected') {
        return (
          <div className='flex justify-center gap-2'>
            <div className='cursor-pointer link' onClick={() => {
              setFileSelected(file);
              setToggleUpdateFile(true);
            }}>
            {t('completeProfile.update')}
            </div>
          </div>
        )
      }
    }
  
    const statusHandler = (file) => {
      const status = file.status
      if (status === 'pending') {
        return (
          <div className={'text-pending flex gap-1 items-center'}>
            <Pending disabled={status !== 'pending'}/> {t('fileStatus.pending')}
          </div>
        )
      }
      
      if (status === 'approved') {
        return (
          <div className={'text-approved flex gap-1 items-center'}>
            <Approved disabled={status !== 'approved'}/> {t('fileStatus.approved')}
          </div>
        )
      }
      
      if (status === 'rejected') {
        return (
          <div className={'text-rejected flex gap-1 items-center'}>
            <Rejected disabled={status !== 'rejected'}/> {t('fileStatus.rejected')}
          </div>
        )
      }
    }
  
    useEffect(() => {
      fetchFiles();
    }, [toggleUpdateFile, showFile]);
    
    return (
      user.roles[0].name.toLowerCase() === roles.CLIENT ? (
        <>
          <Card className='mt-5'>
            <table className='w-full'>
                <thead className='bg-[#F8F9FB]'>
                  <tr>
                    <td className='p-2'>{t('completeProfile.document')}</td>
                    <td className='p-2'>{t('completeProfile.status')}</td>
                    <td className='p-2'></td>
                  </tr>
                </thead>
                <tbody>
                  {
                    files?.map((f, i) => (
                      <tr key={i} className={(i % 2 === 0) ? '' : 'bg-[#F8F9FB]'}>
                        <td className='p-2'>{f.file_name.length ? f.file_name : 'Sin nombre'}</td>
                        <td className='p-2'>{statusHandler(f)}</td>
                        <td className='pr-5'>
                          {actions(f)}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
          </Card>
          {toggleUpdateFile && (
              <Modal type='small' onCloseModal={() => setToggleUpdateFile(false)} title='Actualizar documento'>
                <>
                <UpdateFile file={fileSelected} />
                </>
              </Modal>
          )}
        </>
      ) : (
        <div className='p-3 mt-5'>
          {/* Table */}
          <Card>
            <table className='w-full'>
              <thead className='bg-[#F8F9FB]'>
                <tr>
                  <td className='p-2'>{t('completeProfile.document')}</td>
                  <td></td>
                  <td className='p-2'>{t('completeProfile.status')}</td>
                </tr>
              </thead>
              <tbody>
                {
                  files?.map((f, i) => (
                    <tr key={i} className={(i % 2 === 0) ? '' : 'bg-[#F8F9FB]'}>
                      <td className='p-2'>{f.file_name.length ? f.file_name : 'Sin nombre'}</td>
                      <td className='w-1/5 cursor-pointer' onClick={() => {
                        setFileSelected(f);
                        setShowFile(true);
                      }}><Download/></td>
                      <td>
                        <div className='flex gap-3 align-baseline'>
                          {statusHandler(f)}
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </Card>
          {showFile && (
              <Modal type='large' onCloseModal={() => setShowFile(false)}>
                <>
                <ShowFile file={fileSelected}/>
                </>
              </Modal>
          )}
        </div>
      )
    )
  }