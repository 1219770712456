import React from 'react'
// import beach from '../../../../../assets/home/images/amenities/beach.svg'
// import golf from '../../../../../assets/home/images/amenities/golf.svg'
// import security from '../../../../../assets/home/images/amenities/security.svg'
// import land from '../../../../../assets/home/images/amenities/lote.jpeg'
import agua from '../../../../../assets/home/images/amenities/carousel/agua.png'
import clubplaya from '../../../../../assets/home/images/amenities/carousel/clubplaya.png'
import areaverde from '../../../../../assets/home/images/amenities/carousel/areaverde.png'
import laplaya from '../../../../../assets/home/images/amenities/carousel/lapalya.png'
import golff from '../../../../../assets/home/images/amenities/carousel/golf.png'
import laguna from '../../../../../assets/home/images/amenities/carousel/laguna.png'
import pista from '../../../../../assets/home/images/amenities/carousel/pista.png'
import planurb from '../../../../../assets/home/images/amenities/carousel/planeacion urbana.png'
import supermercado from '../../../../../assets/home/images/amenities/carousel/supermercado.png'
import vigilancia from '../../../../../assets/home/images/amenities/carousel/vigilancia.png'
import acceso from '../../../../../assets/home/images/amenities/carousel/accesocontr.png'
import aeropuerto from '../../../../../assets/home/images/amenities/aeropuerto.svg'
import oceano from '../../../../../assets/home/images/amenities/oceano.svg'
import sol from '../../../../../assets/home/images/amenities/sol.svg'
import selva from '../../../../../assets/home/images/amenities/selva.svg'
import conejo from '../../../../../assets/home/images/amenities/conejo.svg'
import pesca from '../../../../../assets/home/images/amenities/pesca.svg'
import peces from '../../../../../assets/home/images/amenities/peces.svg'
import './Amenities.css'
import Carousel from 'react-multi-carousel'

const itemsGallery = [
  {
    image: clubplaya,
    title: 'Club de playa exclusivo para residentes'
  },
  {
    image: golff,
    title: 'Campo de golf 18 hoyos'
  },
  {
    image: pista,
    title: 'Pista para correr y bicicleta'
  },
  {
    image: laplaya,
    title: 'Acceso a la playa'
  },
  {
    image: laguna,
    title: 'Acceso a la laguna'
  },
  {
    image: supermercado,
    title: 'Zona comercial con restaurantes y supermercados'
  },
  {
    image: areaverde,
    title: 'Áreas verdes por todo el complejo'
  },
  {
    image: planurb,
    title: 'Planeación urbana de primer nivel'
  },
  {
    image: acceso,
    title: 'Accesos controlados'
  },
  {
    image: vigilancia,
    title: 'Seguridad completa las 24 horas'
  },
  {
    image: agua,
    title: 'Planta de tratamientos de agua'
  }
];

export default function Amenities() {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <div className='Amenities'>
      <div className='flex flex-col lg:flex-row lg:justify-center space-top'>
        <div className='w-full text-center basis-full'>
          <div className='title'>
          El proyecto contará con: 
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className='block lg:hidden'>
        <div className='w-full flex flex-col items-center gallery-section pb-4'>
          <div
            style={{
              paddingBottom: '30px',
              position: 'relative'
            }}
            className='w-11/12 px-2 mt-2'
          >
            <Carousel
              swipeable={true}
              draggable={false}
              showDots={true}
              responsive={responsive}
              arrows={true}
              renderDotsOutside={true}
              containerClass="container-fluid m-0 p-0"
              dotListClass=""
              infinite={true}
              autoPlay={true}
            >
              {
                itemsGallery.map((item, index) => (
                  <div className='item' key={index}>
                    <img src={item.image} alt={item.title} className='item-img' />
                    <h3 className="gallery-subtitle">
                      {item.title}
                    </h3>
                  </div>
                ))
              }
            </Carousel>
          </div>
        </div>
      </div>

      {/* Laptop */}
      <div className='hidden lg:block'>
        <div className='w-full flex p-4 justify-center items-center gallery-section'>
          <div
            style={{
              paddingBottom: '30px',
              position: 'relative'
            }}
            className='w-10/12 px-8'
          >
            <Carousel
              autoPlay={true}
              infinite={true}
              swipeable={true}
              draggable={false}
              showDots={true}
              responsive={responsive}
              arrows={true}
              renderDotsOutside={true}
              containerClass="container-fluid m-0 p-0"
              dotListClass=""
            >
              {
                itemsGallery.map((item, index) => (
                  <div className='item' key={index}>
                    <img src={item.image} alt={item.title} className='item-img' />
                    <h3 className="gallery-subtitle">
                      {item.title}
                    </h3>
                  </div>
                ))
              }
            </Carousel>
          </div>
        </div>
      </div>

      <div className='profits lg:pt-12 pt-16 px-0 px-lg-4'>
        <div className="grid lg:grid-cols-4 grid-cols-2 lg:gap-16 gap-4 text-center mx-4">
          <div className="card-item">
            <img src={aeropuerto} alt="" className="image-item" />
            <p className="item-title">12 kms del Aeropuerto Internacional de Ixtapa/Zihuatanejo.</p>
          </div>
          <div className="card-item">
            <img src={oceano} alt="" className="image-item" />
            <p className="item-title">El terreno se encuentra entre la Laguna de Potosí y el Océano Pacífico.</p>
          </div>
          <div className="card-item">
            <img src={sol} alt="" className="image-item" />
            <p className="item-title">La región cuenta con un clima cálido subhúmedo la mayor parte del año.</p>
          </div>
          <div className="card-item">
            <img src={selva} alt="" className="image-item" />
            <p className="item-title">Zona clasificada como selva baja con árboles caducifolios, incluyendo pino, encino y otras especies.</p>
          </div>
        </div>

        <div className="grid lg:grid-cols-3 grid-cols-2 lg:gap-16 gap-2 text-center justify-center lg:mx-40 mx-2">
          <div className="card-item">
            <img src={conejo} alt="" className="image-item" />
            <p className="item-title">Se pueden encontrar una amplia variedad de especies animales, incluyendo mamíferos, aves y reptiles.</p>
          </div>
          <div className="card-item">
            <img src={pesca} alt="" className="image-item" />
            <p className="item-title">Una de las más reconocidas para pesca deportiva y comercial.</p>
          </div>
          <div className="card-item hidden lg:block">
            <img src={peces} alt="" className="image-item" />
            <p className="item-title">En esta región, se encuentran diversas especies acuáticas.</p>
          </div>
        </div>

        <div className='block lg:hidden'>
          <div className='d-flex justify-center w-full'>
            <div className="w-1/2 pt-7">
              <img src={peces} alt="" className="image-item" />
              <p className="item-title">En esta región, se encuentran diversas especies acuáticas.</p>
            </div>
          </div>
        </div>

      </div>
      <div className='gallery-section'>&nbsp;</div>
    </div>
  )
}
