import React, { useCallback, useState } from 'react'
import "./Catalogs.css"
import Section from './components/section/Section';
import LotLand from './components/lotLand/LotLand';
import Tabs from '../../../components/tabs/Tabs';
import { useTranslation } from 'react-i18next';

export default function Catalogs() {

  const [showComponent, setShowComponent] = useState('section');

  const { t } = useTranslation();

  const catalogs = [
    {
      name: 'section',
      label: t('catalogPage.sections')
    },
    {
      name: 'lotLand',
      label: t('catalogPage.lotLands')
    }
  ];

  const handleShowComponent = useCallback((componentName) => {
    setShowComponent(componentName);
  }, []);

  return (
    <div className='Catalogs'>
      <div className='w-auto mt-4 mb-4'>
        <Tabs
          onSelected={handleShowComponent}
          items={catalogs}
        />
      </div>

      {
        showComponent === 'section' &&
        <Section />
      }
      {
        showComponent === 'lotLand' &&
        <LotLand />
      }
    </div>
  )
}
