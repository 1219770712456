import './Card.css';

const Card = ({children, className=''}) => {
    const getClassname = () => {
        if (className.includes('bg-')) {
            return className;
        } else {
            return `card ${className}`;
        }
    }
    const names = getClassname();

    return (
        <div className={names}>
            {children}
        </div>
    )
}

export default Card;