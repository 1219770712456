import React, { useCallback, useEffect, useState } from 'react'
import './NotificationList.css'
import { useTranslation } from 'react-i18next';
import { getAllNotifications } from '../../../../services/NotificationService';
import _ from "lodash"
import Loading from '../../../../components/loading/Loading';
import Pagination from '../../../../components/pagination/Pagination';
import { format } from 'date-fns';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';

export default function NotificationList() {

  const [notifications, setNotifications] = useState([]);
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState('id');
  const [order, setOrder] = useState('desc');
  const [status, setStatus] = useState(null);

  const { t } = useTranslation();

  const getNotifications = useCallback(async (page) => {
    setLoading(true);
    const response = await getAllNotifications(page, sort, order, status);
    setLoading(false);
    console.log('response: ', response);
    if (response.data.data) {
      response.data.data.forEach(theNotification => {
        theNotification.show = false;
      });
      setNotifications(response.data.data);
      setMeta(response.data.meta);
    }
  }, [order, sort, status]);

  /**
   * Handle page selected on pagination
   */
  const onPageSelected = useCallback((pageSelected) => {
    if (meta && meta.current_page !== pageSelected) {
      getNotifications(pageSelected);
    }
  }, [getNotifications, meta]);

  const handleArrowClick = useCallback((notificationSelected, show) => {
    const theIndex = notifications.findIndex((theContact) => theContact.id === notificationSelected.id);
    const currentNotifications = [...notifications];
    currentNotifications[theIndex].show = show;
    setNotifications(currentNotifications);
  }, [notifications]);

  useEffect(() => {
    getNotifications(1);
  }, [getNotifications]);

  return (
    <div className='NotificationList'>
      { loading && <Loading /> }
      <div className='mt-2 mb-10'>
        <h1 className='title'>
          { t('adminSections.notificationList') }
        </h1>
      </div>

      {/* mobile */}
      <div className='block lg:hidden'>
        {
          _.map(notifications, (notification, index) => (
            <div
              key={index}
              className='card-info'
            >
              <div className='flex justify-between px-4'>
                <div className='text-sm font-bold'>
                  {notification?.from.name} {notification?.from.last_name}
                </div>
                <div>
                  {
                    !notification.show &&
                    <BiSolidDownArrow
                      onClick={() => handleArrowClick(notification, true)}
                    />
                  }
                  {
                    notification.show &&
                    <BiSolidUpArrow
                      onClick={() => handleArrowClick(notification, false)}
                    />
                  }
                </div>
              </div>
              {
                notification.show &&
                <div>
                  <div className='grid grid-cols-2 gap-4 my-2 text-sm'>
                    <div className='field-title'>
                      { t('NotificationListPage.from') }
                    </div>
                    <div className='text-left field-value'>
                      { notification?.from.name } {notification?.from.last_name}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2 text-sm'>
                    <div className='field-title'>
                      { t('NotificationListPage.to') }
                    </div>
                    <div className='text-left field-value'>
                      { notification?.to.name } {notification?.to.last_name}
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2 text-sm'>
                    <div className='field-title'>
                      { t('NotificationListPage.subject') }
                    </div>
                    <div className='text-left field-value'>
                      { notification.subject }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2 text-sm'>
                    <div className='field-title'>
                      { t('NotificationListPage.message') }
                    </div>
                    <div className='text-left field-value'>
                      { notification.message }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2 text-sm'>
                    <div className='field-title'>
                      { t('NotificationListPage.readAt') }
                    </div>
                    <div className='text-left field-value'>
                      { notification.read_at ? format(new Date(notification.read_at), 'dd/MM/yyyy H:m:s') : '-' }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4 my-2 text-sm'>
                    <div className='field-title'>
                      { t('NotificationListPage.date') }
                    </div>
                    <div className='text-left field-value'>
                      { format(new Date(notification.created_at), 'dd/MM/yyyy H:m:s') }
                    </div>
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>

      {/* web */}
      <div className='hidden lg:block'>
        <div className='w-full'>
          <table className="w-full mt-5 table-auto">
            <thead className='text-black bg-header'>
              <tr>
                <td className='p-2'>ID</td>
                <td className='p-2'>
                  { t('NotificationListPage.from') }
                </td>
                <td className='p-2'>
                  { t('NotificationListPage.to') }
                </td>
                <td className='p-2'>
                  { t('NotificationListPage.subject') }
                </td>
                <td className='p-2'>
                  { t('NotificationListPage.message') }
                </td>
                <td className='p-2'>
                  { t('NotificationListPage.readAt') }
                </td>
                <td>
                  { t('NotificationListPage.date') }
                </td>
              </tr>
            </thead>
            <tbody>
              {
                _.map(notifications, (notification, index) => (
                  <tr
                    className={`border-b border-white text-sm ${index % 2 === 0 ? 'bg-white' : 'bg-header'}`}
                    key={notification.id}
                  >
                    <td className='p-2 text-primary'>
                      {notification.id}
                    </td>
                    <td className='p-2'>
                      {notification?.from.name} {notification?.from.last_name}
                    </td>
                    <td className='p-2'>
                      {notification?.to.name} {notification?.to.last_name}
                    </td>
                    <td className='p-2'>
                      {notification.subject}
                    </td>
                    <td className='p-2'>
                      {notification.message}
                    </td>
                    <td className='p-2'>
                      { notification.read_at ? format(new Date(notification.read_at), 'dd/MM/yyyy H:m:s') : '-' }
                    </td>
                    <td className='p-2'>
                    { format(new Date(notification.created_at), 'dd/MM/yyyy H:m:s') }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className='flex justify-center w-full mt-4 mb-4'>
            <Pagination
              meta={meta}
              onPageSelected={onPageSelected}
            />
          </div>
        </div>
      </div>

    </div>
  )
}
