import { t } from 'i18next';
import './Submitted.css';
import { SentIcon } from '../../../../assets/icons/SentIcon';

export const Submitted = () => {
    return (
        <div className="p-8">
            <div className='mt-2 mb-2 text-center hero-text'>{t("sendMessage.sent")}</div>
            <div className='flex justify-center'>
                <SentIcon />
            </div>
            <div className='mt-2 mb-2 text-center soon-text'>
                {t('sendMessage.soon')}
            </div>
        </div>
    );
}