import React, { useCallback, useEffect, useState } from 'react'
import { getAmortizationList } from '../../../services/AmortizationsService'
import _ from "lodash"
import './Amortizations.css'
import Loading from '../../../components/loading/Loading';
import { useTranslation } from 'react-i18next';
import { FaList } from "react-icons/fa6";
import Pagination from '../../../components/pagination/Pagination';
import Modal from '../../../components/modal/Modal';
import AmortizationSchedule from './components/amortizationSchedule/AmortizationSchedule';

export default function Amortizations() {

  const [page, setPage] = useState(1);
  const [amortizations, setAmortizations] = useState([]);
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amortizationSelected, setAmortizationSelected] = useState(null);

  const { t } = useTranslation();

  const getAmortizations = useCallback(async () => {
    setLoading(true);
    const amortizations = await getAmortizationList(page);
    setAmortizations(amortizations.data.data);
    setMeta(amortizations.data);
    setLoading(false);
  }, [page]);

  const showSchedule = useCallback(async (amortization) => {
    setAmortizationSelected(amortization);
  }, [])

  /**
   * Handle page selected on pagination
   */
  const onPageSelected = useCallback((pageSelected) => {
    if (meta && meta.current_page !== pageSelected) {
      getAmortizations(pageSelected);
      setPage(pageSelected);
    }
  }, [getAmortizations, meta]);

  /**
   * Handle close modal and reload users list
   */
  const handleCloseModal = useCallback(() => {
    getAmortizations(1);
    setAmortizationSelected(null);
  }, [getAmortizations]);

  useEffect(() => {
    getAmortizations();
  }, [getAmortizations]);

  return (
    <div className='Amortizations'>
      { loading && <Loading /> }
      <div className='mt-2 mb-10'>
        <h1 className='title'>
          {t('adminSections.amortizations')}
        </h1>
      </div>

      <div className='w-full'>
        <table className="w-full mt-5 table-auto">
          <thead className='text-black bg-header'>
              <tr>
                <td className='p-2'>
                  { t('amortizationsPage.client') }
                </td>
                <td className='p-2'>
                  { t('amortizationsPage.land') }
                </td>
                <td className='p-2'>
                  &nbsp;
                </td>
              </tr>
            </thead>
            <tbody className='text-cell'>
              {
                _.map((amortizations), (amortization, index) => (
                  <tr
                    className={`border-b border-white text-sm ${index % 2 === 0 ? 'bg-white' : 'bg-header'}`}
                    key={index}
                  >
                    <td className='p-2'>
                      { amortization.client && (amortization.client.user.name + ' ' + amortization.client.user.last_name) }
                    </td>
                    <td className='p-2'>
                      { amortization.land && amortization.land.identifying_label }
                    </td>
                    <td className='p-2'>
                      <div className='flex justify-center'>
                        <FaList
                          className='cursor-pointer'
                          onClick={() => showSchedule(amortization)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
        </table>
      </div>
      <div className='flex justify-center w-full mt-4 mb-4'>
        <Pagination
          meta={meta}
          onPageSelected={onPageSelected}
        />
      </div>

      {
        amortizationSelected &&
        <Modal
          onCloseModal={handleCloseModal}
          type="large"
        >
          <AmortizationSchedule
            amortization={amortizationSelected}
          />
        </Modal>
      }
    </div>
  )
}
