import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadFile } from '../../../../assets/icons/UploadFile';
import { t } from 'i18next';
import { DocumentFile } from '../../../../assets/icons/DocumentFile';
import { PrimaryButton } from '../../../../components/primary/PrimaryButton';

const Dropzone = ({ onFilesChange }) => {
    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const onDrop = useCallback((files) => {
        // Store the accepted files in state but don't submit immediately
        setAcceptedFiles(files);
      }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            // 'image/jpeg': [],
            // 'image/jpg': [],
            // 'image/png': [],
            'application/pdf': []
        }, // Set accepted file type to PDF
        multiple: true, // Allow multiple file uploads
        maxFiles: 10,
        mazSize: 1000000 // 1Mb
    });

    // Expose a function to clear the files
    const clearFiles = () => {
        setAcceptedFiles([]);
    };

  return (
    <>
        <div
        {...getRootProps()}
        style={{
            // border: '2px dashed #cccccc',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            // backgroundColor: '#f8f8f8',
            cursor: 'pointer',
        }}
        >
        <input accept='.pdf' {...getInputProps()} />
        {!acceptedFiles.length && (
            <div className='justify-center w-full mt-6 text-center'>
                <div className='flex justify-center p-4'>
                    <p style={{ margin: 0 }}>
                        <UploadFile/>
                    </p>
                </div>
                <div className='w-full'>
                    <PrimaryButton>
                        {t('userDetails.selectFile')}
                    </PrimaryButton>
                </div>
            </div>
        )}

        <div className={acceptedFiles.length >= 5 ? 'grid grid-cols-5 gap-4' : `grid grid-cols-${acceptedFiles.length} m-2`}>
        {acceptedFiles.length > 0 && (
            acceptedFiles.map((f, i) => (
                <div key={i} className='text-center'>
                    <div className='flex justify-center'>
                        <DocumentFile/>
                    </div>
                    {f.name}
                </div>
            ))
        )}
        </div>

        </div>
        {acceptedFiles.length > 0 && (
        <div className='flex justify-center mt-2'>
            <PrimaryButton outline onClick={() => clearFiles()}>{t('userDetails.clear')}</PrimaryButton>
        </div>
        )}
        <div className='mt-2'>
        {acceptedFiles.length > 0 && (
            <div className='flex justify-center mt-2'>
                <PrimaryButton onClick={() => onFilesChange(acceptedFiles)}>{t('userDetails.sendFiles')}</PrimaryButton>
            </div>
        )}
        </div>
    </>
  );
};

export default Dropzone;
