import React from 'react'
import "./LotLandDelete.css"
import { useTranslation } from 'react-i18next'

export default function LotLandDelete(
  {onCancel, onConfirm}
) {

  const { t } = useTranslation();

  return (
    <div className='LotLandDelete'>
      <div
        className='flex flex-col justify-center items-center bg-white p-2 rounded-md w-full'
      >
        <div className='text-2xl'>
          { t('lotLandDeletePage.title') }
        </div>
        <div className='mt-2'>
          { t('lotLandDeletePage.description') }
        </div>
        <div className='flex justify-between p-4 w-full align-bottom mt-4'>
          <div>
            <button
              className='p-2 bg-black text-white rounded-md'
              onClick={onCancel}
            >
              { t('global.cancel') }
            </button>
          </div>
          <div>
            <button
              className='p-2 bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo text-white rounded-md'
              onClick={onConfirm}
            >
              { t('global.delete') }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
