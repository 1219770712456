export const Manzana34Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1882_2794)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M713.525 425.193L700.665 448.223L640.549 412.632L651.316 394.986L696.777 390.799L713.525 425.193Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M700.664 448.223L684.215 477.234L622.604 442.839L640.249 412.632L700.664 448.223Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M622.603 442.541L609.145 466.767L669.559 503.554L684.214 477.235L622.603 442.541Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M669.56 503.553L655.204 528.077L594.789 493.384L608.846 467.064L669.56 503.553Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M655.204 528.079L640.848 553.502L580.434 518.21L594.79 493.386L655.204 528.079Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M640.848 553.501L626.492 578.624L566.077 544.229L580.433 518.209L640.848 553.501Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M626.491 578.625L611.836 603.748L552.318 569.354L566.076 543.932L626.491 578.625Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M552.319 569.352L537.664 594.475L598.677 629.766L611.837 603.746L552.319 569.352Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M598.677 629.767L584.022 653.993L523.906 618.701L537.664 594.476L598.677 629.767Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M523.905 618.701L508.951 644.422L569.665 680.013L584.021 653.993L523.905 618.701Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M569.666 680.013L556.207 704.837L494.596 669.844L508.653 644.422L569.666 680.013Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('12'))} d="M556.208 705.138L539.16 728.466L477.848 693.174L494.596 669.846L556.208 705.138Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('13'))} d="M539.16 728.465L521.215 751.793L461.1 716.501L477.848 693.173L539.16 728.465Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('14'))} d="M461.1 716.502L443.753 739.83L504.766 775.122L521.216 751.794L461.1 716.502Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('16'))} d="M468.276 824.173L486.82 799.349L425.806 764.356L409.955 783.797L468.276 824.173Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('15'))} d="M443.752 739.829L425.807 764.354L486.82 799.347L504.765 775.121L443.752 739.829Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1882_2794" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1882_2794" transform="matrix(0.000841252 0 0 0.00109033 -1.32012 -0.32958)"/>
        </pattern>
        <image id="image0_1882_2794" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}