import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/axios";

export const fetchLandsFromUser = createAsyncThunk("fetch/user-lands", async (params = {}) => {
  const response = await api.get(`api/user-lands`, {params});
  return {
      data: response.data
  }
})

const initialState = {
  name: '',
  username: '',
  role: '',
  client: JSON.parse(localStorage.getItem('user_selected')),
  lands: []
};

export const userSlice = createSlice(
  {
    name: 'user',
    initialState,
    reducers: {
      addUser: (state, action) => {
        localStorage.setItem('user_selected', JSON.stringify(action.payload.user))
        const {token, user} = action.payload;
        state.token = token;
        state.user = user;
      },
      selectClient: (state, action) => {
        state.client = action.payload;
      },
      removeUser: (state, action) => {
        localStorage.removeItem('user_selected');
        state.token = null;
        state.user = null;
      }
    },
    extraReducers: (builder) => {
      builder.addCase(fetchLandsFromUser.fulfilled, (state, action) => {
          state.lands = action.payload.data.data
      })
  }
  }
);

export const { addUser, selectClient, removeUser } = userSlice.actions;
export default userSlice.reducer;
