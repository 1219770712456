import React, { useCallback, useEffect, useState } from 'react'
import './Location.css'
import { SectionMap } from '../../../../logged/sale/maps/sections/SectionMap'
import { getSectionsPublic } from '../../../../../services/SectionsService'
import LandSection from '../LandSection/LandSection'
import Loading from '../../../../../components/loading/Loading'
import { GeneralMap } from '../../../../logged/sale/maps/GeneralMap'

export default function Location() {

  const [sections, setSections] = useState([]);
  const [showLand, setShowLand] = useState(false);
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);

  const getSectionList = useCallback(async () => {
    const theSections = await getSectionsPublic();
    if (theSections.data) {
      setSections(theSections.data.data);
    }
  }, []);

  const handleChooseLand = useCallback((id, group) => {
    setLoading(true);
    setParams(
      {
        section: id,
        group: group
      }
    );
    setShowLand(true);
    setLoading(false);
  }, []);

  const handleClusterSelected = useCallback(() => {
    setShowLand(true);
  }, []);

  useEffect(() => {
    getSectionList();
  }, [getSectionList]);

  return (
    <div className='Location'>
      {loading && <Loading />}
      <div className='title py-8'>
        Elige tu terreno
      </div>
      

      <div className='flex justify-center px-8 px-lg-5'>
        <GeneralMap 
          isPublic={true}
        />
      </div>
      

      <div className='mt-4 lg:mt-40'>&nbsp;</div>
    </div>
  )
}
