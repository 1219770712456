export const Letter = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="#D8EBEF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60ZM43.1213 39.1213C43.6839 38.5587 44 37.7957 44 37V23C43.9968 22.658 43.9324 22.3194 43.81 22L31.66 30.1C31.1681 30.4268 30.5906 30.6011 30 30.6011C29.4094 30.6011 28.8319 30.4268 28.34 30.1L16.19 22C16.0676 22.3194 16.0032 22.658 16 23V37C16 37.7957 16.3161 38.5587 16.8787 39.1213C17.4413 39.6839 18.2044 40 19 40H41C41.7957 40 42.5587 39.6839 43.1213 39.1213ZM42.55 20.43L30.55 28.43C30.3888 28.5369 30.2 28.5948 30.0065 28.5966C29.813 28.5983 29.6232 28.544 29.46 28.44L17.46 20.44C17.924 20.1565 18.4563 20.0044 19 20H41C41.5462 19.9996 42.0821 20.1482 42.55 20.43Z" fill="url(#paint0_linear_615_112)" fillOpacity="0.6"/>
        <defs>
        <linearGradient id="paint0_linear_615_112" x1="0.00169798" y1="29.9956" x2="60.0066" y2="29.9956" gradientUnits="userSpaceOnUse">
        <stop stopColor="#024E82"/>
        <stop offset="1" stopColor="#0096B3"/>
        </linearGradient>
        </defs>
        </svg>
    );
}
