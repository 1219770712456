export const Avatar2 = ({disabled, color="#0096B3", className}) => {
    if (disabled) {
        color = '#939598';
    }

    return (
        <div className={className}>
            <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5947 10.6709C10.6766 11.1484 9.62441 11.4157 8.50002 11.4157C7.37562 11.4157 6.32344 11.1484 5.40536 10.6709C2.24881 11.8168 0 14.6627 0 17.9956V18.3298C2.26942 20.1824 5.24031 21.2998 8.50002 21.2998C11.7597 21.2998 14.7306 20.1825 17 18.3203V17.986C17 14.6627 14.7615 11.8168 11.5947 10.6709ZM8.50002 0.299805C5.40536 0.299805 2.89866 2.6204 2.89866 5.48534C2.89866 8.35028 5.40536 10.6709 8.50002 10.6709C11.5947 10.6709 14.1013 8.35028 14.1013 5.48534C14.1013 2.6204 11.5947 0.299805 8.50002 0.299805Z" fill={color}/>
            </svg>
        </div>
    )
}