import React, { useState } from 'react'
import "./ForgotPassword.css"
import Loading from '../../../../../components/loading/Loading'
import LangButtons from '../../../../../components/langButtons/LangButtons';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import zihuaLogo from '../../../../../assets/images/zihua-logo.svg'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../../hooks/useAuth';

export default function ForgotPassword() {

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { recoveryPassword } = useAuth();

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({});

  // Handle login submit
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const params = {
      username: data.username
    };
    const response = await recoveryPassword(params);
    setLoading(false);
    if (response.data.success === 0) {
      toast.error(t(`forgotPasswordPage.${response.data.message}`));
      return;
    }
    toast.success(t('forgotPasswordPage.success'));
  });

  return (
    <div className='ForgotPassword'>
      { loading && <Loading /> }
      <div className='flex justify-end pt-10 pr-10'>
        <LangButtons />
      </div>
      <div className='flex justify-center items-center w-screen h-screen bg-secondary'>
        <div className='w-8/12 md:6/12 p-8'>
          <div className='w-full flex justify-center'>
            <img src={zihuaLogo} alt='Zihua Logo' className='w-24' />
          </div>
          <form
            onSubmit={onSubmit}
            className='mt-10'
          >
            <div>
              <label className='text-light'>
                {t('loginPage.username')}
              </label>
              <br />
              <input
                type='text'
                className='border border-tertiary p-2 rounded-md w-full'
                {
                  ...register(
                    'username', {
                      required: {
                        value: true,
                        message: t('loginPage.errors.username.required')
                      }
                    }
                  )
                }
              />
              {
                errors.username && 
                <div className='error-message'>
                  {errors.username.message}
                </div>
              }
            </div>
            <div className='mt-4 flex flex-col lg:flex-row justify-center items-center text-center lg:text-left lg:justify-between w-full'>
              <div className='text-light w-full lg:w-3/5 cursor-pointer text-sm'>
                <Link  to='/login'>
                  { t('loginPage.backLogin') }
                </Link>
              </div>
              <div className='w-2/5 mt-4 lg:mt-0'>
                <button
                  className='rounded-md text-white p-2 w-full bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo'
                >
                  {t('global.send')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
