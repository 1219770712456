export const Manzana37Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1874_2896)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M511.048 552.487L534.379 548L545.595 595.558L485.026 608.569L511.048 552.487Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M450.481 670.48L514.639 655.674L503.422 604.527L485.027 608.565L450.481 670.48Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('3'))}
            d="M557.711 645.804L514.64 655.675L503.423 604.528L547.392 595.106L557.711 645.804Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('5'))}
            d="M602.128 635.938L557.711 645.809L547.392 595.11L590.014 585.24L602.128 635.938Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('7'))}
            d="M644.751 626.514L602.128 635.936L590.014 585.46L632.735 575.367L644.751 626.514Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('9'))}
            d="M689.166 616.194L644.749 626.514L632.635 575.367L676.603 565.047L689.166 616.194Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('11'))}
            d="M732.243 605.875L689.172 616.194L676.61 565.047L716.989 556.074L732.243 605.875Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('10'))}
            d="M702.628 509.413L716.985 556.074L674.363 565.495L662.698 518.835L702.628 509.413Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('8'))}
            d="M619.629 528.258L662.701 518.836L674.366 565.497L630.846 575.816L619.629 528.258Z" 
            fill="#0096B3" 
            fillOpacity="0.64"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('4'))}
            d="M577.007 538.129L534.384 547.999L545.601 595.557L588.672 585.687L577.007 538.129Z" 
            fill="#0096B3" 
            fillOpacity="0.64"
        />
        <path 
            d="M619.629 528.258L662.701 518.836L674.366 565.497L630.846 575.816L619.629 528.258Z" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            d="M577.007 538.129L534.384 547.999L545.601 595.557L588.672 585.687L577.007 538.129Z" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('6'))}
            d="M577.003 538.126L619.626 528.705L630.842 575.814L588.668 585.684L577.003 538.126Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1874_2896" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1874_2896" transform="matrix(0.00126197 0 0 0.00163562 -2.88598 -0.593218)"/>
        </pattern>
        <image id="image0_1874_2896" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}