export const Manzana32Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1881_10624)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M523.63 330.24L540.672 298.104L602.023 337.056L583.033 367.732L523.63 330.24Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M602.023 337.057L619.552 307.356L558.201 267.916L540.672 298.105L602.023 337.057Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M594.234 245.031L638.543 276.68L619.553 307.356L558.202 267.916L568.427 250.387C576.218 241.817 588.878 243.246 594.234 245.031Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M665.812 346.31L642.927 384.776L591.802 354.1L614.2 316.121L665.812 346.31Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M685.773 309.305L665.81 346.31L614.197 316.121L638.543 276.682L685.773 309.305Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M591.315 354.102L568.917 391.107L621.99 421.782L642.928 384.29L591.315 354.102Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M505.614 361.891L485.65 398.896L545.054 428.598L563.556 398.896L505.614 361.891Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M583.035 367.732L563.559 399.382L505.616 361.889L523.632 330.24L583.035 367.732Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M540.184 426.162L518.76 460.246L468.608 427.623L485.65 398.408L540.184 426.162Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M540.187 489.463C558.105 469.597 587.904 459.761 600.564 457.326L540.187 426.164L512.433 469.986L540.187 489.463Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M621.992 421.781L601.054 457.812L545.06 428.598L568.918 391.105L621.992 421.781Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('12'))} d="M468.611 427.623L447.187 466.089L495.391 497.251L518.763 459.759L468.611 427.623Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('15'))} d="M424.787 506.016L470.556 536.691L444.75 574.183L399.954 545.942L424.787 506.016Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('13'))} d="M447.19 466.09L424.792 506.017L471.049 537.179L495.394 497.252L447.19 466.09Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('14'))} d="M504.641 558.602C506.978 529.777 529.311 500.498 540.185 489.461L512.431 469.984L471.044 537.178L504.641 558.602Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('16'))} d="M510.487 617.518L504.158 558.601L470.561 536.69L445.241 574.183L510.487 617.518Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('19'))} d="M454.98 581L484.195 599.016L435.504 666.21L408.724 646.733L454.98 581Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('18'))} d="M352.729 608.755L408.723 646.734L430.634 615.571L376.587 577.105L352.729 608.755Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('17'))} d="M399.958 545.941L376.586 577.591L430.633 615.57L454.979 580.999L399.958 545.941Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('20'))} d="M483.707 599.018L510.487 617.52L463.256 685.201L435.989 666.211L483.707 599.018Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('21'))} d="M463.255 685.199L493.443 705.649L529.962 655.984L510.485 618.005L463.255 685.199Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('22'))} d="M493.446 705.651L529.965 655.499L545.546 669.619L562.588 683.74L528.991 730.483L493.446 705.651Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('23'))} d="M562.588 683.737L585.96 694.449L578.169 765.052L528.991 730.481L562.588 683.737Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('24'))} d="M578.169 764.567L624.426 752.395L629.295 703.703C610.792 700.782 597.808 697.374 585.96 694.452L578.169 764.567Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M624.422 752.395L722.291 725.128C722.681 723.57 722.129 722.207 721.804 721.72L629.291 703.704L624.422 752.395Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1881_10624" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1881_10624" transform="matrix(0.00136957 0 0 0.00177507 -2.5667 -1.21669)"/>
        </pattern>
        <image id="image0_1881_10624" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}