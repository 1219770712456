import React from 'react'
import './Example.css'
import step1 from '../../../../../../assets/home/images/example/step1.png'
import step2 from '../../../../../../assets/home/images/example/step2.png'
import step3 from '../../../../../../assets/home/images/example/step3.png'
import final from '../../../../../../assets/home/images/example/final.png'
import finalMobile from '../../../../../../assets/home/images/example/final-mobile.png'

export default function Example() {
  return (
    <div className='Example'>
        <div className='w-full text-center title'>
            Ejemplo:
        </div>

        <div className='grid grid-cols-1 gap-0 lg:grid-cols-3 gap-lg-4 px-6 lg:px-6 pt-4'>
            <div className='flex justify-center pt-4 relative'>
                <div className=''>
                    <img src={step1} alt='Paso 1' className='custom-width-image' />
                </div>
            </div>

            <div className='flex justify-center pt-4 relative'>
                <div className=''>
                    <img src={step2} alt='Paso 2' className='custom-width-image' />
                </div>
            </div>

            <div className='flex justify-center pt-4 relative'>
                <div className=''>
                    <img src={step3} alt='Paso 3' className='custom-width-image' />
                </div>
            </div>
        </div>

        <div className='flex justify-center py-10'>
            <div className='w-10/12 lg:w-8/12'>
                <img src={final} alt='paso final' className='custom-width-image m-auto hidden lg:block' />
                <img src={finalMobile} alt='paso final' className='custom-width-image m-auto block lg:hidden' />
            </div>
        </div>
    </div>
  )
}
