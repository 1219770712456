import React, { useCallback, useEffect, useState } from 'react';
import "./DashboardClient.css";
import UserDetails from '../../../register/components/UserDetails';
import { useAuth } from '../../../../../hooks/useAuth';
import { t } from 'i18next';
import { Selector } from '../../../../../components/selector/Selector';
import { useForm } from 'react-hook-form';
import SelectCategory from '../../../sale/components/SelectCategory';
import { CompleteProfile } from '../../../register/components/complete/CompleteProfile';

export default function DashboardClient() {

  const [first, setFirst] = useState(true);

  const auth = useAuth();
  const {updateMe} = useAuth();

  const {user} = auth.user;
  const {control, watch} = useForm({
    defaultValues: {
      land: {
        value: user?.client?.lands[0]?.id,
        label: user?.client?.lands[0]?.identifying_label
      }
    }
  })

  const landSelected = watch('land');
  
  const clientHasLands = () => {
    return user.client.lands.length > 0;
  }

  const landsToOptions = () => {
    if (clientHasLands) {
      return user.client.lands.map(l => ({value: l.id, label: l.identifying_label}));
    } else {
      return []
    }
  }

  const refreshUserInfo = useCallback(async () => {
    if (first) {
      setFirst(false);
      await updateMe();
    }
  }, [first, updateMe]);

  useEffect(() => {
    refreshUserInfo();
  }, [refreshUserInfo]);

  if (user.profile_completed === 0) {
    return (
      <>
      <CompleteProfile
        firstTime={true}
      />
      </>
    )
  } else if (user.client.lands.length <= 0) {
    return (<>
    <SelectCategory/>
    </>)
  }

  return (
    <div className=''>
      <div>
        <h2 className='mb-10 userTitle'>{t('clientDashboard.title')}</h2>
      </div>
      <div className='w-1/5'>
        <Selector
          name="land"
          options={landsToOptions()}
          defaultValue={{value: user.client.lands[0].id, label: user.client.lands[0].identifying_label}}
          control={control}
        />
      </div>

      { clientHasLands ? 
        <UserDetails user={user} land={landSelected}/> : "Cliente no cuenta con terrenos"
      }
    </div>
  )
}
