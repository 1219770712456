import React, { useCallback, useEffect, useState } from 'react'
import "./Register.css"
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../hooks/useAuth'
import { saveUser, updateUser, saveFile } from '../../../services/UsersService'; 
import { roles } from '../../../constants/roles'
import { ToastContainer, toast } from "react-toastify";
import Loading from '../../../components/loading/Loading';
import { Selector } from '../../../components/selector/Selector';
import { PrimaryButton } from '../../../components/primary/PrimaryButton';

const countryCodes = [
  {value: '+1', label: 'America'},
  {value: '+52', label: 'México'},
];

export default function Register(
  {userToEdit, onSuccess, type = ''}
) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [image, setImage] = useState();

  const defaultValues = userToEdit ? {
    ...userToEdit,
    countryCode: countryCodes.filter(r => r.value === userToEdit.countryCode)[0],
  } : null;
  const {
      register,
      handleSubmit,
      formState: {
        errors
      },
      reset,
      control
    } = useForm({
      defaultValues
    })

  /**
   * Handle submit form
   */
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const params = {
      name: data.name,
      last_name: data.lastName,
      country_code: data?.countryCode?.value ? data.countryCode.value : data.countryCode,
      phone: data.phone,
      email: data.email,
      rol: role,
      zip_code: data.zipCode ? data.zipCode : undefined, 
      full_address: data.address ? data.address : undefined,
      type: type !== '' ? type : undefined
    };
    try {
      let response = null;
      if (!userToEdit) {
        response = await saveUser(params);
      } else {
        response = await updateUser(userToEdit.id, params);
      }
      setLoading(false);
      if (response.data && response.data.status === 'success') {
        console.log('response.data: ', response.data);
        if (type === 'organization' && file) {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('id', response.data.data.id);
          await saveFile(formData);
        }
        toast.success(
          userToEdit ? t('registerPage.update') : t('registerPage.save')
        );
        reset(
          {
            name: '',
            lastName: '',
            countryCode: '',
            phone: '',
            email: '',
            zipCode: '',
            address: '',
            file
          }
        );
        onSuccess();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        // validation error
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.message
        );
      }
    }
  });

  const handleChangeFile = useCallback((e) => {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
  }, []);

  useEffect(() => {
    const userRole = user.user.roles[0].name.toLowerCase();
    if (userRole === roles.SUPER_ADMIN) {
      setRole(roles.ADMIN);
    }
    if (
      userRole === roles.ADMIN
      || userRole === roles.COORDINATOR
      || userRole === roles.ORGANIZATION
    ) {
      if (type === '') {
        setRole(roles.SELLER);
      } else {
        setRole(
          type === 'seller'
            ? roles.SELLER
            : type === 'coordinator'
              ? roles.COORDINATOR
              : type === 'organization'
                ? roles.ORGANIZATION
                : type === 'cliente'
                  ? roles.CLIENT
                  : roles.SELLER
        );
      }
    }
    if (userRole === roles.SELLER) {
      setRole(roles.CLIENT);
    }
  }, [type, user.user.roles]);

  return (
    <div
      className='flex justify-center Register'
    >
      { loading && <Loading /> }
      <div className='w-full lg:w-10/12 px-2 lg:px-8 mt-1 bg-white rounded-md text-dark'>
        <form onSubmit={onSubmit}>
          <div className='grid grid-cols-1 mt-4 text-light md:grid-cols-2 md:gap-2'>
            {/* Name */}
            <div className='w-full mt-2 lg:mt-0'>
              <label
                htmlFor='name'
                className='label'
              >
                {t('registerPage.name')}
              </label>
              <input
                type="text"
                id="name"
                className="w-full p-2 text-black border rounded-md border-light"
                {
                  ...register(
                    'name', {
                      required: {
                        value: true,
                        message: t('registerPage.errors.name.required')
                      },
                      minLength: {
                        value: 3,
                        message: t('registerPage.errors.name.minLength')
                      }
                    }
                  )
                }
              />
              {
                errors.name && 
                <div className='error-message'>
                  {errors.name.message}
                </div>
              }
            </div>
            {/* Last Name */}
            <div className='w-full  mt-2 lg:mt-0'>
              <label
                htmlFor='lastName'
                className='label'
              >
                { t('registerPage.lastName') }
              </label>
              <input
                type="text"
                id="lastName"
                className="w-full p-2 text-black border rounded-md border-light"
                {
                  ...register(
                    'lastName', {
                      required: {
                        value: true,
                        message: t('registerPage.errors.lastName.required')
                      },
                      minLength: {
                        value: 3,
                        message: t('registerPage.errors.lastName.minLength')
                      }
                    }
                  )
                }
              />
              {
                errors.lastName && 
                <div className='error-message'>
                  {errors.lastName.message}
                </div>
              }
            </div>
          </div>
          <div className='grid grid-cols-1 mt-2 lg:mt-4 text-light md:grid-cols-2 md:gap-2'>
            {/* Country Code */}
            <div className='w-full'>
              <label
                htmlFor='countryCode'
                className='label'
              >
                {t('registerPage.countryCode')}
              </label>
              <Selector name={"countryCode"} id="countryCode" defaultValue={defaultValues?.countryCode ? defaultValues.countryCode : null} options={countryCodes} control={control}/>
              {
                errors.countryCode && 
                <div className='error-message'>
                  {errors.countryCode.message}
                </div>
              }
            </div>

            {/* Phone */}
            <div className='w-full mt-2 lg:mt-0'>
              <label
                htmlFor='phone'
                className='label'
              >
                {t('registerPage.phone')}
              </label>
              <input
                type="text"
                id="phone"
                className="w-full p-2 text-black border rounded-md border-light"
                {
                  ...register(
                    'phone',
                    {
                      required: {
                        value: true,
                        message: t('registerPage.errors.phone.required')
                      },
                      minLength: {
                        value: 10,
                        message: t('registerPage.errors.phone.minLength')
                      },
                      maxLength: {
                        value: 15,
                        message: t('registerPage.errors.phone.maxLength')
                      }
                    }
                  )
                }
              />
              {
                errors.phone && 
                <div className='error-message'>
                  {errors.phone.message}
                </div>
              }
            </div>
          </div>

          <div className='grid grid-cols-1 mt-2 lg:mt-4 text-light'>
            {/* Email */}
            <div className='w-full'>
              <label
                htmlFor='email'
                className='label'
              >
                {t('registerPage.email')}
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-2 text-black border rounded-md border-light"
                {
                  ...register(
                    'email', {
                      required: {
                        value: true,
                        message: t('registerPage.errors.email.required')
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,4}$/,
                        message: t('registerPage.errors.email.pattern')
                      }
                    }
                  )
                }
              />
              {
                errors.email && 
                <div className='error-message'>
                  {errors.email.message}
                </div>
              }
            </div>
          </div>

          <div className='grid grid-cols-1 mt-2 lg:mt-4 text-light md:grid-cols-2 md:gap-2'>
            {
              (
                user.user.roles[0].name.toLowerCase() === roles.SELLER ||
                (user.user.roles[0].name.toLowerCase() === roles.COORDINATOR && type === roles.CLIENT) ||
                (user.user.roles[0].name.toLowerCase() === roles.ORGANIZATION && type === roles.CLIENT)
              ) &&
              <>
                {/* Zip Code */}
                <div className='w-full'>
                  <label
                    htmlFor='zipCode'
                    className='label'
                  >
                    {t('registerPage.zipCode')}
                  </label>
                  <input
                    type="text"
                    id="zipCode"
                    className="w-full p-2 text-black border rounded-md border-light"
                    {
                      ...register(
                        'zipCode',
                        {
                          required: {
                            value: true,
                            message: t('registerPage.errors.zipCode.required')
                          },
                          minLength: {
                            value: 5,
                            message: t('registerPage.errors.zipCode.minLength')
                          }
                        }
                      )
                    }
                  />
                  {
                    errors.zipCode && 
                    <div className='error-message'>
                      {errors.zipCode.message}
                    </div>
                  }
                </div>
                {/* Address */}
                <div className='w-full mt-2 lg:mt-0'>
                  <label
                    htmlFor='address'
                    className='label'
                  >
                    {t('registerPage.address')}
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="w-full p-2 text-black border rounded-md border-light"
                    {
                      ...register(
                        'address',
                        {
                          required: {
                            value: true,
                            message: t('registerPage.errors.address.required')
                          },
                          minLength: {
                            value: 5,
                            message: t('registerPage.errors.address.minLength')
                          }
                        }
                      )
                    }
                  />
                  {
                    errors.address && 
                    <div className='error-message'>
                      {errors.address.message}
                    </div>
                  }
                </div>
              </>
            }
          </div>

          {
            type === 'organization' &&
            <div className='w-full'>
              <h3>
                { t('registerPage.logo') }
              </h3>
              <input
                type="file"
                id="file"
                className="w-full p-2 text-black border rounded-md border-light show-file-input"
                accept=".png, .jpg, .jpeg"
                {
                  ...register(
                    'file',
                    {
                      required: {
                        value: true,
                        message: t('registerPage.errors.file.required')
                      }
                    }
                  )
                }
                onChange={handleChangeFile}
              />
              {
                errors.file && 
                <div className='error-message'>
                  {errors.file.message}
                </div>
              }
              {
                image &&
                <div className='flex justify-center'>
                  <img src={image} alt='logo' className='w-24 mt-4'/>
                </div>
              }
            </div>
          }

          <div className='flex justify-center my-2'>
              <PrimaryButton>
                {t('global.save')}
              </PrimaryButton>
          </div>
        </form>
      </div>

      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
