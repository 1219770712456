import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './DashboardOrganization.css'
import { useTranslation } from 'react-i18next';
import LineChart from '../../../../../components/charts/lineChart/LineChart';
import UsersTable from '../usersTable/UsersTable';
import { roles } from '../../../../../constants/roles';
import Modal from '../../../../../components/modal/Modal';
import Register from '../../../register/Register';
import { Selector } from '../../../../../components/selector/Selector';
import { useForm } from 'react-hook-form';
import { getClientsBySeller, getClientsChart, getPaymentsDashboard, getSalesBySeller, getSellerSignUp } from '../../../../../services/dashboardService';
import { PrimaryButton } from '../../../../../components/primary/PrimaryButton';

export default function DashboardOrganization() {

  const [random, setRandom] = useState(0);
  const [type, setType] = useState('');

  const { t, i18n } = useTranslation();
  const [chartValues, setChartValues] = useState({
    sellersSignUp: [],
    totalClients: [],
    monthlyPayments: [],
    salesBySeller: [],
    clientsBySeller: []
  });
  const ref = useRef(null);
  const currentDate = new Date();

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, index) => {
      const monthValue = index + 1;
      return {
        label: new Date(2023, index, 1).toLocaleString(i18n.language === 'es' ? i18n.language : 'default', { month: 'long' }),
        value: monthValue,
      };
    });
  }, []);

  const {
    control,
    handleSubmit,
    watch
  } = useForm({defaultValues: {month: currentDate.getMonth() + 1}});

  const selectedMonth = watch('month');

  const submit = useCallback(async (data) => {
    // Handle form submission here
    try {
      const params = {month: data.month?.value ? data.month?.value : data.month};

      const respSellersSignUp = await getSellerSignUp(params);
      const respClients = await getClientsChart(params);
      const respMonthlyPayments = await getPaymentsDashboard(params);
      const respSalesBySeller = await getSalesBySeller(params);
      const respClientsBySeller = await getClientsBySeller(params);

      setChartValues({
        ...chartValues,
        sellersSignUp: respSellersSignUp.data,
        totalClients: respClients.data,
        monthlyPayments: respMonthlyPayments.data,
        salesBySeller: respSalesBySeller.data,
        clientsBySeller: respClientsBySeller.data
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  /**
   * Handle add user to show form
   */
  const handleAdd = useCallback((theType) => {
    setType(theType);
  }, []);

   /**
   * Handle close modal and reload users list
   */
   const handleCloseModal = useCallback(() => {
    setType('');
  }, []);

  /**
   * Handle success in form
   */
  const handleSuccess = useCallback(() => {
    setType('');
    setRandom(Math.random());
    if (selectedMonth) {
      handleSubmit(submit)();
    }
  }, []);

  useEffect(() => {
    setRandom(Math.random());
  }, []);

  useEffect(() => {
    if (selectedMonth) {
      handleSubmit(submit)();
    }
  }, [selectedMonth, handleSubmit, submit]);

  return (
    <div className='p-5 DashboardOrganization' ref={ref}>
      <h3 className='mt-8 title'>
        { t('dashboardPage.organization.title') }
      </h3>
      <div className='pl-4 mt-5'>
        <div className='flex items-center justify-start gap-3'>
          <div className='w-1/5'>
          <form>
            <Selector label='' name={'month'} defaultValue={months.filter(r => r.value === currentDate.getMonth() + 1)[0]} control={control} options={months} />
          </form>
          </div>
          <PrimaryButton
            onClick={() => handleAdd('seller')}
          >
            { t('dashboardPage.admin.newSeller') }
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleAdd('cliente')}
          >
            { t('dashboardPage.seller.newCustomer') }
          </PrimaryButton>
        </div>
        <div className='flex flex-col gap-4 pr-8 mt-5 lg:flex-row'>
          <div>
            <LineChart
              title={<div className='subtitle'>Clientes Registrados</div>}
              bgColor1={'rgba(138, 191, 230, 0.49)'}
              bgColor2={'rgba(108, 180, 233, 0.54)'}
              chartData={chartValues.totalClients}
            />
          </div>
          <div>
            <LineChart
              title={<div className='subtitle'>Vendedores Registrados</div>}
              bgColor1={'rgba(146, 224, 235, 1)'}
              bgColor2={'rgba(34, 159, 176, 1)'}
              chartData={chartValues.sellersSignUp}
            />
          </div>
          <div>
            <LineChart
              title={<div className='subtitle'>Pagos mensuales</div>}
              bgColor1={'rgba(252, 227, 138, 0.69)'}
              bgColor2={'rgba(255, 147, 47, 0.8)'}
              chartData={chartValues.monthlyPayments}
            />
          </div>
        </div>
        <div className='flex flex-col gap-4 pr-8 mt-5 lg:flex-row'>
          <div>
            <LineChart
              title={<div className='subtitle'>Ventas concluidas por vendedor</div>}
              bgColor1={'rgba(204, 192, 82, 0.33)'}
              bgColor2={'rgba(223, 180, 68, 0.49)'}
              chartData={chartValues.salesBySeller}
            />
          </div>
          <div>
            <LineChart
              title={<div className='subtitle'>Clientes registrados por vendedor</div>}
              bgColor1={'rgba(222, 252, 138, 0.69)'}
              bgColor2={'rgba(127, 200, 53, 0.8)'}
              chartData={chartValues.clientsBySeller}
            />
          </div>
        </div>
        <div className='pr-4'>
          <UsersTable
            role={roles.CLIENT}
            random={random}
            onScroll={scrollToTop}
          />
        </div>
      </div>
      {
        type !== '' &&
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
          title={t('registerPage.add')}
        >
          <Register
            onSuccess={handleSuccess}
            type={type}
          />
        </Modal>
      }
    </div>
  )
}
