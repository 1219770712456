import React from 'react'
import terraza from '../../../../../assets/home/images/shop/image38.png'
// import beach from '../../../../../assets/home/images/shop/happy-young-family-having-fun-with-baby-sunny-beach 1.png'
// import persona from '../../../../../assets/home/images/shop/persona.svg'
// import enganche from '../../../../../assets/home/images/shop/enganche.svg'
// import calendario from '../../../../../assets/home/images/shop/calendario.svg'
import './Shopnow.css'
import Example from './Example/Example'

export default function Shopnow(){
    // const responsive = {
    //     desktop: {
    //       breakpoint: { max: 3000, min: 1024 },
    //       items: 1,
    //       slidesToSlide: 1 // optional, default to 1.
    //     },
    //     tablet: {
    //       breakpoint: { max: 1024, min: 464 },
    //       items: 1,
    //       slidesToSlide: 1 // optional, default to 1.
    //     },
    //     mobile: {
    //       breakpoint: { max: 464, min: 0 },
    //       items: 1,
    //       slidesToSlide: 1 // optional, default to 1.
    //     }
    //   };
      return(
        <div className="Shopnow">
            <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 justify-center items-end h-100 p-2">
                <div className='w-full flex justify-center items-center h-full'>
                    <div className='w-full px-0 px-md-4'>
                        <h1 className="title">
                            Financiamiento
                        </h1>
                        <p className="description px-3 px-md-5 px-lg-3 ">
                            Una de las principales ventajas que te ofrecemos es poder pagar tu terreno en <b>cómodos pagos mensuales sin intereses</b> por hasta 10 años.
                        </p>
                    </div>
                </div>
                <div className='px-2 px-md-5'>
                    <img src={terraza} alt="" className="img-fund lg:w-11/12 w-full " />
                </div>
            </div>

            <div className='w-full pt-4'>
                <Example />
            </div>
            {/* <div className="flex flex-col mt-16 lg:mx-40 mx-3">
                <h2 className="subtitle text-center">Ejemplo:</h2>
                <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-16 gap-2 text-center justify-center">
                    <div className="card-item">
                        <img src={persona} alt="" className="image-item" />
                        <p className="item-title">Pedro compró 1 terreno de 500 m2 a un precio de <span className='u-line'>$45,000 USD.</span></p>
                    </div>
                    <div className="card-item">
                        <img src={enganche} alt="" className="image-item" />
                        <p className="item-title">Enganche o pago inicial: <span className='u-line'>$9,000 USD.</span></p>
                    </div>
                    <div className="card-item">
                        <img src={calendario} alt="" className="image-item" />
                        <p className="item-title"><span className='u-line'>$75 USD </span> al mes durante los próximos 10 años.</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col lg:mt-16 mt-8 lg:w-11/12 w-full mx-auto">
            <img src={beach} alt="" className="lg:w-10/12 w-full m-auto" />
                <h1 className="heading-image lg:w-8/12 w-10/12 lg:my-8 my-3 mx-auto">
                Una vez que termine de pagar su terreno, este se escriturará a tu nombre y podrá comenzar a construir o venderlo y obtener una importante ganancia. 
                </h1>
            </div> */}
        </div>
      );
}