export const SentIcon = () => {
    return (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="70" cy="70" r="70" fill="#2BBFDC" fillOpacity="0.12"/>
    <path d="M99.9707 75.091V51.778C99.9634 51.0053 99.8187 50.2402 99.5434 49.5187L72.2186 67.8192C71.1123 68.5575 69.8136 68.9514 68.4854 68.9514C67.1571 68.9514 65.8584 68.5575 64.7521 67.8192L37.4273 49.5187C37.152 50.2402 37.0073 51.0053 37 51.778V83.4085C37 85.2061 37.7108 86.9301 38.9761 88.2013C40.2414 89.4724 41.9575 90.1865 43.7469 90.1865H81.9161C80.8755 88.4104 80.2787 86.3404 80.2787 84.1304C80.2787 77.527 85.6072 72.1739 92.1803 72.1739C95.1595 72.1739 97.8831 73.2736 99.9707 75.091Z" fill="url(#paint0_linear_615_757)" fillOpacity="0.6"/>
    <path d="M96.7097 45.9715L69.7223 64.0461C69.3596 64.2877 68.935 64.4184 68.4999 64.4224C68.0648 64.4265 67.6379 64.3036 67.2709 64.0687L40.2835 45.9941C41.3269 45.3535 42.5241 45.0099 43.7469 45H93.2238C94.4522 44.999 95.6575 45.3349 96.7097 45.9715Z" fill="url(#paint1_linear_615_757)" fillOpacity="0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M92.7213 95C98.3979 95 103 90.3773 103 84.6739C103 78.9706 98.3979 74.3478 92.7213 74.3478C87.0447 74.3478 82.4426 78.9706 82.4426 84.6739C82.4426 90.3773 87.0447 95 92.7213 95ZM97.0053 84.7174L92.5301 80.221C92.2553 79.9461 91.81 79.9461 91.5353 80.221C91.2606 80.497 91.2606 80.9449 91.5353 81.2209L94.8102 84.5104H87.8525V85.9243H94.8102L91.5353 89.2139C91.2606 89.4899 91.2606 89.9378 91.5353 90.2138C91.81 90.4887 92.2553 90.4887 92.5301 90.2138L97.0053 85.7173C97.2801 85.4414 97.2801 84.9934 97.0053 84.7174Z" fill="url(#paint2_linear_615_757)" fillOpacity="0.6"/>
    <defs>
    <linearGradient id="paint0_linear_615_757" x1="37.0019" y1="69.9963" x2="103.007" y2="69.9963" gradientUnits="userSpaceOnUse">
    <stop stopColor="#024E82"/>
    <stop offset="1" stopColor="#0096B3"/>
    </linearGradient>
    <linearGradient id="paint1_linear_615_757" x1="37.0019" y1="69.9963" x2="103.007" y2="69.9963" gradientUnits="userSpaceOnUse">
    <stop stopColor="#024E82"/>
    <stop offset="1" stopColor="#0096B3"/>
    </linearGradient>
    <linearGradient id="paint2_linear_615_757" x1="37.0019" y1="69.9963" x2="103.007" y2="69.9963" gradientUnits="userSpaceOnUse">
    <stop stopColor="#024E82"/>
    <stop offset="1" stopColor="#0096B3"/>
    </linearGradient>
    </defs>
    </svg>
    );
}