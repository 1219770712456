import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import "./DashboardAdmin.css"
import { useTranslation } from 'react-i18next';
import UsersTable from '../usersTable/UsersTable';
import { roles } from '../../../../../constants/roles';
import LineChart from '../../../../../components/charts/lineChart/LineChart';
import Modal from '../../../../../components/modal/Modal';
import Register from '../../../register/Register';
import { PrimaryButton } from '../../../../../components/primary/PrimaryButton';
import { Selector } from '../../../../../components/selector/Selector';
import { useForm } from 'react-hook-form';
import { getClientsChart, getRegionSales, getSalesChart, getSellerSignUp } from '../../../../../services/dashboardService';

export default function DashboardAdmin() {

  const [showForm, setShowForm] = useState(false);
  const [random, setRandom] = useState(0);
  const [type, setType] = useState('');
  const { t, i18n } = useTranslation();
  const [chartValues, setChartValues] = useState({
    sellersSignUp: [],
    totalSales: [],
    regionSales: [],
    totalClients: []
  });
  const ref = useRef(null);
  const currentDate = new Date();

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, index) => {
      const monthValue = index + 1;
      return {
        label: new Date(2023, index, 1).toLocaleString(i18n.language === 'es' ? i18n.language : 'default', { month: 'long' }),
        value: monthValue,
      };
    });
  }, []);

  const {
    control,
    handleSubmit,
    watch
  } = useForm({defaultValues: {month: currentDate.getMonth() + 1}});

  const selectedMonth = watch('month');

  const submit = useCallback(async (data) => {
    // Handle form submission here
    try {
      const params = {month: data.month?.value ? data.month?.value : data.month};

      const respSellersSignUp = await getSellerSignUp(params);
      const respTotalSales = await getSalesChart(params);
      const respClients = await getClientsChart(params);
      const respRegionSales = await getRegionSales(params);

      setChartValues({
        ...chartValues,
        sellersSignUp: respSellersSignUp.data,
        totalSales: respTotalSales.data,
        totalClients: respClients.data,
        regionSales: respRegionSales.data
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

   /**
   * Handle add user to show form
   */
   const handleAdd = useCallback((theType) => {
    // setShowForm(true);
    setType(theType);
  }, []);

   /**
   * Handle close modal and reload users list
   */
   const handleCloseModal = useCallback(() => {
    // setShowForm(false);
    setType('');
  }, []);

  /**
   * Handle success in form
   */
  const handleSuccess = useCallback(() => {
    setType('');
  }, []);

  useEffect(() => {
    setRandom(Math.random());
  }, []);

  useEffect(() => {
    if (selectedMonth) {
      handleSubmit(submit)();
    }
  }, [selectedMonth, handleSubmit, submit]);

  return (
    <div className='DashboardAdmin' ref={ref}>
      <h3 className='mt-8 title'>
        { t('dashboardPage.admin.title') }
      </h3>
      <div className='pl-4 mt-5'>
        {/* Month selector and create seller */}
        <div className='flex items-center justify-start gap-3'>
          <div className='w-1/5'>
          <form onSubmit={handleSubmit(submit)}>
            <Selector label='' name={'month'} defaultValue={months.filter(r => r.value === currentDate.getMonth() + 1)[0]} control={control} options={months} />
          </form>
          </div>
          {/* Buttons */}
          <div className='flex gap-2'>
          <PrimaryButton
            onClick={() => handleAdd('seller')}
          >
            { t('dashboardPage.admin.newSeller') }
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleAdd('coordinator')}
          >
            { t('dashboardPage.admin.newCoordinator') }
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleAdd('organization')}
          >
            { t('dashboardPage.admin.newOrganization') }
          </PrimaryButton>
        </div>
        </div>
        <div className='flex flex-col justify-center gap-10 mt-5 lg:flex-row'>
          <div className='flex justify-center w-4/5 '>
            <LineChart
              title={<div className='subtitle'>Ventas Totales</div>}
              bgColor1={'rgba(146, 224, 235, 1)'}
              bgColor2={'rgba(34, 159, 176, 1)'}
              chartData={chartValues.totalSales}
            />
          </div>
          <div className='flex justify-center w-4/5 '>
            <LineChart
              title={<div className='subtitle'>Ventas por Región</div>}
              bgColor1={'rgba(252, 227, 138, 0.69)'}
              bgColor2={'rgba(255, 147, 47, 0.8)'}
              chartData={chartValues.regionSales}
            />
          </div>
        </div>
        <div className='flex flex-col justify-center gap-10 mt-2 lg:flex-row'>
          <div className='flex justify-center w-4/5 '>
            <LineChart
              title={<div className='subtitle'>Clientes Totales</div>}
              bgColor1={'rgba(146, 224, 235, 1)'}
              bgColor2={'rgba(34, 159, 176, 1)'}
              chartData={chartValues.totalClients}
            />
          </div>
          <div className='flex justify-center w-4/5 '>
            <LineChart
              title={<div className='subtitle'>Vendedores Registrados</div>}
              bgColor1={'rgba(252, 227, 138, 0.69)'}
              bgColor2={'rgba(255, 147, 47, 0.8)'}
              chartData={chartValues.sellersSignUp}
            />
          </div>
        </div>
        <div className='pr-4'>
          <UsersTable
            role={roles.SELLER}
            random={random}
            onScroll={scrollToTop}
          />
        </div>
      </div>
      {
        type !== '' &&
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
          title={t('registerPage.add')}
        >
          <Register
            onSuccess={handleSuccess}
            type={type}
          />
        </Modal>
      }
    </div>
  )
}
