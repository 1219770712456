import React from 'react'
import './Contact.css'
import ContactForm from '../contactForm/ContactForm'

export default function Contact() {
  return (
    <div className='Contact flex justify-center'>
      <div className='w-8/12'>
        <div className='' id='contact-form'>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}
