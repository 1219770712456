import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../src/translations/en/en.json"
import es from "../src/translations/es/es.json"

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es",
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
  