import React, { useCallback, useEffect, useState } from 'react'
import './RegisterPublic.css'
import { ToastContainer, toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { saveUserPublic, getOrganizationsPublic } from '../../../services/UsersService';
import Loading from '../../../components/loading/Loading';
import { useTranslation } from 'react-i18next';
import { Selector } from '../../../components/selector/Selector';
import logoFuerzaMigrante from '../../../assets/home/images/logo-fuerza-migrante.png'
import { Link } from 'react-router-dom';
import { values } from 'lodash';

const countryCodes = [
  {value: '+1', label: 'America'},
  {value: '+52', label: 'México'},
];

export default function RegisterPublic() {
  

  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(true);
  const [organizations, setOrganizations] = useState([]);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    reset,
    control
  } = useForm();

  /**
   * Handle submit form
   */
  const onSubmit = handleSubmit(async (data) => {
    console.log('onSubmit data: ', data);
    setLoading(true);
    const params = {
      name: data.name,
      last_name: data.lastName,
      country_code: data?.countryCode?.value ? data.countryCode.value : data.countryCode,
      phone: data.phone,
      email: data.email,
      organization_name: data.organization
    };
    try {
      let response = null;
      response = await saveUserPublic(params);
      setLoading(false);
      if (response.data && response.data.status === 'success') {
        console.log('response.data: ', response.data);
        toast.success(t('registerPage.saveFuerzaMigrante'));
        reset(
          {
            name: '',
            lastName: '',
            countryCode: '',
            phone: '',
            email: '',
            organization_name: ''
          }
        );
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        // validation error
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.message
        );
      }
    }
  });

  const getOrganizations = useCallback(async () => {
    const results = await getOrganizationsPublic();
    let theOrganizations = [];
    if (results.data) {
      results.data.data.forEach(org => {
        theOrganizations.push(
          {
            value: org.id,
            label: `${org.name} ${org.last_name}`
          }
        );
      });
      setOrganizations(theOrganizations);
    }
  }, []);

  useEffect(() => {
    
    if (first) {
      setFirst(false);
      getOrganizations();
    }
  }, [first, getOrganizations]);

  return (
    <div className='RegisterPublic p-8'>
      {/* <div className='flex justify-center w-full'>
        <div className='flex justify-between w-full lg:w-10/12'>
          <div className='w-12 lg:w-24'>
            
          </div>
        </div>
      </div> */}

      <div className='flex justify-center'>
        <div className='form-title w-11/12 md:w-8/12 py-5'>
          Si eres miembro de Fuerza Migrante regístrate aquí
        </div>
      </div>
      <div className='flex justify-center'>
        <div className='form-subtitle pb-5'>
          Agrega tus datos y nos pondremos en contacto contigo
        </div>
      </div>

      <div
        className='flex justify-center Register'
      >
        { loading && <Loading /> }
        <div className='w-full lg:w-10/12 px-2 lg:px-8 mt-1 rounded-md text-dark'>
          <form onSubmit={onSubmit}>
            <div className='grid w-full grid-cols-1 lg:grid-cols-2 lg:gap-4'>
              <div className='input-div'>
                <label>
                  { t('homePage.contact.name') }
                </label>
                <input
                  type='text'
                  id="name"
                  className='w-full'
                  {
                    ...register(
                      'name', {
                        required: {
                          value: true,
                          message: t('homePage.contact.errors.name.required')
                        },
                        minLength: {
                          value: 3,
                          message: t('homePage.contact.errors.name.minLength')
                        }
                      }
                    )
                  }
                />
                {
                  errors.name &&
                  <div className='error-message'>
                    {errors.name.message}
                  </div>
                }
              </div>
              <div className='input-div'>
                <label>
                  { t('homePage.contact.lastName') }
                </label>
                <input
                  type='text'
                  id="lastName"
                  className='w-full'
                  {
                    ...register(
                      'lastName', {
                        required: {
                          value: true,
                          message: t('homePage.contact.errors.lastName.required')
                        },
                        minLength: {
                          value: 3,
                          message: t('homePage.contact.errors.lastName.minLength')
                        }
                      }
                    )
                  }
                />
                {
                  errors.lastName &&
                  <div className='error-message'>
                    {errors.lastName.message}
                  </div>
                }
              </div>
            </div>
            <div className='grid w-full grid-cols-1 lg:grid-cols-2 lg:gap-4'>
              <div className='input-div'>
                <label>
                  { t('homePage.contact.countryCode') }
                </label>
                <Selector
                  name={"countryCode"} 
                  id="countryCode" 
                  options={countryCodes} 
                  control={control}
                  isArrowGreen={true}
                  {
                    ...register(
                      'countryCode',
                      {
                        required: {
                          value: true,
                          message: t('registerPage.errors.countryCode.required')
                        }
                      }
                    )
                  }
                />
                {
                  errors.countryCode && 
                  <div className='error-message'>
                    {errors.countryCode.message}
                  </div>
                }
              </div>
              <div className='input-div'>
                <label>
                  { t('homePage.contact.phone') }
                </label>
                <input
                  type='text'
                  id="phone"
                  className='w-full'
                  {
                    ...register(
                      'phone',
                      {
                        required: {
                          value: true,
                          message: t('homePage.contact.errors.phone.required')
                        },
                        minLength: {
                          value: 10,
                          message: t('homePage.contact.errors.phone.minLength')
                        },
                        maxLength: {
                          value: 15,
                          message: t('homePage.contact.errors.phone.maxLength')
                        }
                      }
                    )
                  }
                />
                {
                  errors.phone && 
                  <div className='error-message'>
                    {errors.phone.message}
                  </div>
                }
              </div>
            </div>
            <div className='grid w-full grid-cols-1 lg:grid-cols-2 lg:gap-4'>
              <div className='w-full input-div'>
                <label>
                  { t('homePage.contact.email') }
                </label>
                <input
                  type='text'
                  id="email"
                  className='w-full'
                  {
                    ...register(
                      'email', {
                        required: {
                          value: true,
                          message: t('homePage.contact.errors.email.required')
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,4}$/,
                          message: t('homePage.contact.errors.email.pattern')
                        }
                      }
                    )
                  }
                />
                {
                  errors.email && 
                  <div className='error-message'>
                    {errors.email.message}
                  </div>
                }
              </div>
              <div className='w-full input-div'>
                <label>
                  { t('homePage.contact.organization') }
                </label>
                {/* <Selector
                  name={"organization"} 
                  id="organization" 
                  options={organizations} 
                  control={control}
                  isArrowGreen={true}
                  {
                    ...register(
                      'organization',
                      {}
                    )
                  }
                /> */}
                <input
                  type='text'
                  id="organization"
                  className='w-full'
                  {
                    ...register(
                      'organization', {
                      }
                    )
                  }
                />
                {
                  errors.organization && 
                  <div className='error-message'>
                    {errors.organization.message}
                  </div>
                }
              </div>
            </div>
            <div className='flex justify-center mt-4'>
              <button className='px-10 py-2 text-sm text-white uppercase rounded-md bg-gradient-custom'>
                { t('global.send') }
              </button>
            </div>
          </form>
        </div>

        <ToastContainer
          position="top-right"
          theme="light"
          autoClose={2000}
          style={{ width: '500px' }}
        />
      </div>

      <div className='flex justify-center pt-8 pb-5 px-6'>
        <Link to={'/'} >
          <img src={logoFuerzaMigrante} alt='Logo Fuerza Migrante' className='w-full cursor-pointer'  />  
        </Link>
      </div>
    </div>
  )
}
