import api from "../api/axios";
import { endpoints } from "../api/endpoints";


export const getSections = async (page, download = undefined) => {
    try {
        let theEndpoint = endpoints.sections + `?page=${page}`;
        if (download) {
            theEndpoint += `&download=${download}`;
            return await api.get(theEndpoint, { responseType: 'blob' });
        }
        return await api.get(theEndpoint);
    } catch (error) {
        return error.message;
    }
};

export const getSectionsPublic = async (page) => {
    try {
        return await api.get(endpoints.sectionsPublic);
    } catch (error) {
        return error.message;
    }
};

export const saveSection = async (params) => {
    try {
        return await api.post(endpoints.sections, params);
    } catch (error) {
        return error.message;
    }
};

export const updateSection = async (id, params) => {
    try {
        return await api.put(endpoints.sections + `/${id}`, params);
    } catch (error) {
        return error.message;
    }
};

export const deleteSection = async (id) => {
    try {
        return await api.delete(endpoints.sections + `/${id}`);
    } catch (error) {
        return error.message;
    }
};