import api from '../api/axios'

export const createPayment = async ({client, data = {}}) => {
    return await api.post(`api/pay/${client}`, data);
};

export const updatePaymentMenthod = async ({cardId, card}) => {
    try {
        return await api.put(`api/card/${cardId}`, card)
    } catch (error) {
        return error.message;
    }
}

export const savePaymentMethod = async (card) => {
    try {
        return await api.post(`api/card`, card)
    } catch (error) {
        return error.message;
    }
}

export const getPaymentMethod = async () => {
    try {
        return await api.get('api/card');
    } catch (error) {
        return error;
    }
}