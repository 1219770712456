export const UploadFile = () => {
    return (
    <svg width="169" height="169" viewBox="0 0 169 169" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="84.5" cy="84.5" r="84.5" fill="#2BBFDC" fillOpacity="0.12"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M89.6 62.1667V51H73.92C64.023 51 56 58.9992 56 68.8667V100.133C56 110.001 64.023 118 73.92 118H94.08C103.977 118 112 110.001 112 100.133V71.7782C112 71.551 111.991 71.3248 111.974 71.1H98.56C93.6115 71.1 89.6 67.1004 89.6 62.1667ZM74.6449 83.3336C73.7336 84.1679 73.6734 85.5807 74.5101 86.4892C75.347 87.3976 76.764 87.4578 77.6751 86.6235L81.76 82.8833V93.9C81.76 95.1335 82.7628 96.1333 84 96.1333C85.2372 96.1333 86.24 95.1335 86.24 93.9V82.8833L90.3249 86.6235C91.236 87.4578 92.653 87.3976 93.4899 86.4892C94.3266 85.5807 94.2664 84.1679 93.3551 83.3336L85.5151 76.155C84.6583 75.3706 83.3417 75.3706 82.4849 76.155L74.6449 83.3336Z" fill="url(#paint0_linear_622_9250)" fillOpacity="0.6"/>
    <path d="M94.08 62.1667V51.8287C94.933 52.223 95.7256 52.7519 96.4248 53.4026L109.154 65.2474C109.608 65.6701 110.013 66.135 110.365 66.6333H98.56C96.0858 66.6333 94.08 64.6335 94.08 62.1667Z" fill="url(#paint1_linear_622_9250)" fillOpacity="0.6"/>
    <defs>
    <linearGradient id="paint0_linear_622_9250" x1="56.0016" y1="84.4951" x2="112.006" y2="84.4951" gradientUnits="userSpaceOnUse">
    <stop stopColor="#024E82"/>
    <stop offset="1" stopColor="#0096B3"/>
    </linearGradient>
    <linearGradient id="paint1_linear_622_9250" x1="56.0016" y1="84.4951" x2="112.006" y2="84.4951" gradientUnits="userSpaceOnUse">
    <stop stopColor="#024E82"/>
    <stop offset="1" stopColor="#0096B3"/>
    </linearGradient>
    </defs>
    </svg>
    )
}