import React, { useCallback, useEffect, useState } from 'react'
import "./AmortizationSchedule.css"
import { useTranslation } from 'react-i18next'
import _ from "lodash"
import format from 'date-fns/format'
import { getAmortizationSchedule } from '../../../../../services/AmortizationsService'
import Pagination from '../../../../../components/pagination/Pagination'
import Loading from '../../../../../components/loading/Loading'

export default function AmortizationSchedule(
  {amortization}
) {

  const [amortizations, setAmortizations] = useState([]);
  const [meta, setMeta] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const getAmortizations = useCallback(async () => {
    setLoading(true);
    const schedule = await getAmortizationSchedule(amortization.client_id, amortization.land_id, page);
    setAmortizations(schedule.data.data);
    setMeta({
      last_page: schedule.data.last_page,
      current_page: schedule.data.current_page
    });
    setLoading(false);
  }, [amortization.client_id, amortization.land_id, page]);

  /**
   * Handle page selected on pagination
   */
  const onPageSelected = useCallback((pageSelected) => {
    if (meta && meta.current_page !== pageSelected) {
      getAmortizations(pageSelected);
      setPage(pageSelected);
    }
  }, [getAmortizations, meta]);

  useEffect(() => {
    getAmortizations();
  }, [getAmortizations]);

  return (
    <div className='AmortizationSchedule'>
      { loading && <Loading /> }
      <div className='m-6 bg-white p-4 rounded-md'>
        <div className='text-primary text-xl'>
          {amortizations[0]?.client?.user.name} {amortizations[0]?.client?.user.last_name}
        </div>
        <div className='text-primary text-xl mt-2'>
          {amortizations[0]?.land?.identifying_label}
        </div>
        <div className='mt-6'>
          <table className='w-full mt-5 table-auto'>
            <thead className='text-black bg-header'>
              <tr className='text-left'>
                <th className='p-2'>
                  { t('amortizationSchedulePage.period') }
                </th>
                <th className='p-2'>
                  { t('amortizationSchedulePage.paymentDate') }
                </th>
                <th className='p-2'>
                  { t('amortizationSchedulePage.amount') }
                </th>
                <th className='p-2'>
                  { t('amortizationSchedulePage.paymentAmount') }
                </th>
                <th className='p-2'>
                  { t('amortizationSchedulePage.balance') }
                </th>
                <th className='p-2'>
                  { t('amortizationSchedulePage.status') }
                </th>
                <th>
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody className='text-cell'>
              {
                _.map(amortizations, (item, index) => (
                  <tr
                    className={`border-b border-white text-sm ${index % 2 === 0 ? 'bg-white' : 'bg-header'}`}
                    key={index}
                  >
                    <td className='p-2'>
                      { item.period }
                    </td>
                    <td className='p-2'>
                      { format(new Date(item.payment_date), 'dd/MM/yyyy') }
                    </td>
                    <td className='p-2'>
                      { item.amount }
                    </td>
                    <td className='p-2'>
                      { item.payment_amount }
                    </td>
                    <td className='p-2'>
                      { item.balance }
                    </td>
                    <td className='p-2'>
                      { item.status }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className='flex justify-center w-full mt-4 mb-4'>
            <Pagination
              meta={meta}
              onPageSelected={onPageSelected}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
