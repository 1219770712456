import { t } from "i18next";
import Card from "../../../components/card/Card";
import "./Success.css";
import RoundedX from "../../../assets/icons/RoundedX";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components/primary/PrimaryButton";

const PaymentCancel = () => {

    const navigate = useNavigate();

    return (
        <div className="justify-center p-10">
            <Card className="p-20 m-20">
                <div className="flex justify-center">
                    <RoundedX/>
                </div>
                <div className="flex justify-center">
                    <h1>{t('errorPage.title')}</h1>
                </div>
                <div className="flex justify-center">
                    {t('errorPage.description')}
                </div>
                <div className="flex justify-center link" onClick={() => navigate('/admin/home')}>
                    {t('errorPage.tryAgain')}
                </div>
            </Card>
            <div className="">
                <div className="flex justify-center">
                    <PrimaryButton onClick={() => navigate('/admin/home')}>
                        {t('errorPage.continue')}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default PaymentCancel;