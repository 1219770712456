import React from 'react'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Location from './components/location/Location'
import Contact from './components/contact/Contact'
import Amenities from './components/amenities/Amenities'
import Phases from './components/phases/Phases'
import Buy from './components/buy/Buy'
import Map from './components/map/Map'
import About from './components/about/About'
import Sopnow from './components/shopnow/Shopnow'
import Shopnow from './components/shopnow/Shopnow'
import FuerzaMigrante from './components/FuerzaMigrante/FuerzaMigrante'

export default function Home() {

  return (
    <div
      className='Home'
    >
      <div className="container-fluid g-0">
        <section id='home' className='section-home'>
          <Header />
          <FuerzaMigrante />
        </section>
        <section id='about'>
          <About />
        </section>
        <section id='amenities'>
          <Amenities />
        </section>
        <section>
          <Shopnow />
        </section>
        <section id='location'>
          <Location />
        </section>
        <section id='contact'>
          <Contact />
        </section>
        <section>
          <Footer />
        </section>
      </div>
    </div>
  )
}
