const Calculator = ({disabled, color="#0096B3", size, className}) => {

    if (disabled) {
        color = '#939598';
    }

    return (
        <div className={className}>
        <svg width={size} height={size} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 2.66309V10.6553H10.3555V0.299805H2.36328C1.73671 0.300487 1.136 0.549694 0.692943 0.992747C0.249889 1.4358 0.000682417 2.03651 0 2.66309ZM2.83594 5.06934H4.76953V3.13574C4.76953 2.9648 4.83744 2.80086 4.95831 2.67999C5.07918 2.55912 5.24312 2.49121 5.41406 2.49121C5.585 2.49121 5.74894 2.55912 5.86981 2.67999C5.99069 2.80086 6.05859 2.9648 6.05859 3.13574V5.06934H7.99219C8.16313 5.06934 8.32707 5.13724 8.44794 5.25811C8.56881 5.37899 8.63672 5.54293 8.63672 5.71387C8.63672 5.88481 8.56881 6.04875 8.44794 6.16962C8.32707 6.29049 8.16313 6.3584 7.99219 6.3584H6.05859V8.29199C6.05859 8.46293 5.99069 8.62687 5.86981 8.74774C5.74894 8.86862 5.585 8.93652 5.41406 8.93652C5.24312 8.93652 5.07918 8.86862 4.95831 8.74774C4.83744 8.62687 4.76953 8.46293 4.76953 8.29199V6.3584H2.83594C2.665 6.3584 2.50106 6.29049 2.38019 6.16962C2.25931 6.04875 2.19141 5.88481 2.19141 5.71387C2.19141 5.54293 2.25931 5.37899 2.38019 5.25811C2.50106 5.13724 2.665 5.06934 2.83594 5.06934Z" fill={color}/>
        <path d="M0 19.9365C0.000682417 20.5631 0.249889 21.1638 0.692943 21.6069C1.136 22.0499 1.73671 22.2991 2.36328 22.2998H10.3555V11.9443H0V19.9365ZM2.78824 15.1932C2.66733 15.0723 2.5994 14.9083 2.5994 14.7373C2.5994 14.5663 2.66733 14.4023 2.78824 14.2814C2.90915 14.1605 3.07315 14.0926 3.24414 14.0926C3.41514 14.0926 3.57913 14.1605 3.70004 14.2814L5.39258 15.9744L7.08512 14.2814C7.20603 14.1605 7.37002 14.0926 7.54102 14.0926C7.71201 14.0926 7.876 14.1605 7.99691 14.2814C8.11783 14.4023 8.18575 14.5663 8.18575 14.7373C8.18575 14.9083 8.11783 15.0723 7.99691 15.1932L6.30395 16.8857L7.99691 18.5783C8.11783 18.6992 8.18575 18.8632 8.18575 19.0342C8.18575 19.2052 8.11783 19.3692 7.99691 19.4901C7.876 19.611 7.71201 19.6789 7.54102 19.6789C7.37002 19.6789 7.20603 19.611 7.08512 19.4901L5.39258 17.7971L3.70004 19.4901C3.57913 19.611 3.41514 19.6789 3.24414 19.6789C3.07315 19.6789 2.90915 19.611 2.78824 19.4901C2.66733 19.3692 2.5994 19.2052 2.5994 19.0342C2.5994 18.8632 2.66733 18.6992 2.78824 18.5783L4.48121 16.8857L2.78824 15.1932Z" fill={color}/>
        <path d="M19.6367 0.299805H11.6445V10.6553H22V2.66309C21.9993 2.03651 21.7501 1.4358 21.3071 0.992747C20.864 0.549694 20.2633 0.300487 19.6367 0.299805ZM19.1641 6.3584H14.0078C13.8369 6.3584 13.6729 6.29049 13.5521 6.16962C13.4312 6.04875 13.3633 5.88481 13.3633 5.71387C13.3633 5.54293 13.4312 5.37899 13.5521 5.25811C13.6729 5.13724 13.8369 5.06934 14.0078 5.06934H19.1641C19.335 5.06934 19.4989 5.13724 19.6198 5.25811C19.7407 5.37899 19.8086 5.54293 19.8086 5.71387C19.8086 5.88481 19.7407 6.04875 19.6198 6.16962C19.4989 6.29049 19.335 6.3584 19.1641 6.3584Z" fill={color}/>
        <path d="M11.6445 22.2998H19.6367C20.2633 22.2991 20.864 22.0499 21.3071 21.6069C21.7501 21.1638 21.9993 20.5631 22 19.9365V11.9443H11.6445V22.2998ZM17.252 19.4854C17.252 19.6563 17.184 19.8202 17.0632 19.9411C16.9423 20.062 16.7784 20.1299 16.6074 20.1299C16.4365 20.1299 16.2725 20.062 16.1517 19.9411C16.0308 19.8202 15.9629 19.6563 15.9629 19.4854V19.4424C15.9629 19.2714 16.0308 19.1075 16.1517 18.9866C16.2725 18.8658 16.4365 18.7979 16.6074 18.7979C16.7784 18.7979 16.9423 18.8658 17.0632 18.9866C17.184 19.1075 17.252 19.2714 17.252 19.4424V19.4854ZM15.9629 14.3291C15.9629 14.1582 16.0308 13.9942 16.1517 13.8733C16.2725 13.7525 16.4365 13.6846 16.6074 13.6846C16.7784 13.6846 16.9423 13.7525 17.0632 13.8733C17.184 13.9942 17.252 14.1582 17.252 14.3291V14.3721C17.252 14.543 17.184 14.7069 17.0632 14.8278C16.9423 14.9487 16.7784 15.0166 16.6074 15.0166C16.4365 15.0166 16.2725 14.9487 16.1517 14.8278C16.0308 14.7069 15.9629 14.543 15.9629 14.3721V14.3291ZM14.0293 16.2627H19.1855C19.3565 16.2627 19.5204 16.3306 19.6413 16.4515C19.7622 16.5723 19.8301 16.7363 19.8301 16.9072C19.8301 17.0782 19.7622 17.2421 19.6413 17.363C19.5204 17.4839 19.3565 17.5518 19.1855 17.5518H14.0293C13.8584 17.5518 13.6944 17.4839 13.5735 17.363C13.4527 17.2421 13.3848 17.0782 13.3848 16.9072C13.3848 16.7363 13.4527 16.5723 13.5735 16.4515C13.6944 16.3306 13.8584 16.2627 14.0293 16.2627Z" fill={color}/>
        </svg>
        </div>
    )
}

export default Calculator;