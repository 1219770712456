import React from 'react'
import "./PaymentsPage.css"

export default function PaymentsPage() {
  return (
    <div
      className='PaymentsPage'
    >
      PaymentsPage
    </div>
  )
}
