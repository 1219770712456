export const SandClock = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="81" viewBox="0 0 49 81" fill="none">
        <path d="M45.2443 72.7739H45.2309V61.2249C45.2309 57.3182 42.7446 52.4415 39.6239 50.1233L27.6408 41.2544V39.7462L39.6232 30.8773C42.7446 28.5592 45.2303 23.6824 45.2303 19.7757V8.22671H45.2437C47.3222 8.22671 48.9994 6.52377 48.9994 4.42996V3.79674C48.9994 1.70294 47.3222 0 45.2437 0H3.77164C1.69309 0 0 1.70294 0 3.79674V4.42932C0 6.52312 1.69181 8.22606 3.77036 8.22606L3.78759 19.7751C3.78759 23.6824 6.22926 28.5559 9.35698 30.869L21.3592 39.7527V41.2467L9.3474 50.131C6.22097 52.4441 3.76908 57.3176 3.76908 61.2249V72.7739H3.77164C1.69309 72.7739 0 74.4769 0 76.5707V77.2033C0 79.2971 1.69309 81 3.77164 81H45.2443C47.3229 81 49 79.2971 49 77.2033V76.5707C49 74.4769 47.3229 72.7739 45.2443 72.7739ZM42.7178 72.7746H39.5767V62.8488C39.5767 59.9643 37.7795 56.3629 35.4732 54.6517L25.2763 47.0762C24.8341 46.7464 24.2323 46.7457 23.7894 47.0742L13.539 54.6568C11.23 56.3655 9.42271 59.9636 9.42271 62.8488V72.7739H6.28159V61.2249C6.28159 58.1424 8.37546 53.9966 10.842 52.1715L23.3612 42.9085C23.6835 42.67 23.8711 42.2913 23.8711 41.8883V39.1124C23.8711 38.7093 23.6879 38.3307 23.3663 38.0922L10.8388 28.8292C8.37163 27.0041 6.28159 22.8583 6.28159 19.7757V8.22671H42.7178V19.7757C42.7178 22.8595 40.5965 27.0105 38.1318 28.8407L25.6586 38.0883C25.3376 38.3268 25.1277 38.7042 25.1277 39.1066V41.894C25.1277 42.2958 25.3172 42.6738 25.6382 42.9123L38.1325 52.1606C40.5958 53.9908 42.7178 58.1417 42.7178 61.2256V72.7746Z" fill="url(#paint0_linear_1099_124)" fillOpacity="0.6"/>
        <path d="M35.9302 24.935C35.7605 24.4149 35.2787 24.0645 34.7362 24.0645L14.2839 24.0581C13.7408 24.0581 13.2596 24.4097 13.0898 24.9292C12.9207 25.4492 13.1026 26.0201 13.5404 26.3441L23.7914 33.926C24.0129 34.09 24.2739 34.1716 24.5349 34.1716C24.7966 34.1716 25.0589 34.0893 25.2803 33.9241L35.4822 26.3486C35.9188 26.0246 36.1 25.4544 35.9302 24.935Z" fill="url(#paint1_linear_1099_124)" fillOpacity="0.6"/>
        <defs>
            <linearGradient id="paint0_linear_1099_124" x1="0.00138669" y1="40.4941" x2="49.0054" y2="40.4941" gradientUnits="userSpaceOnUse">
            <stop stopColor="#024E82"/>
            <stop offset="1" stopColor="#0096B3"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1099_124" x1="0.00138669" y1="40.4941" x2="49.0054" y2="40.4941" gradientUnits="userSpaceOnUse">
            <stop stopColor="#024E82"/>
            <stop offset="1" stopColor="#0096B3"/>
            </linearGradient>
        </defs>
        </svg>
    )
}