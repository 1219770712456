export const Manzana23Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1878_6608)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M475.419 538.386L514.5 527.136L542.923 617.733L503.25 629.576L475.419 538.386Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M542.925 617.74L580.822 605.897L553.584 515.3L514.502 527.143L542.925 617.74Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M576.67 692.938L621.08 679.319L596.21 601.156L552.392 614.775L576.67 692.938Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M553.583 515.292L593.256 503.449L620.494 594.047L580.821 605.889L553.583 515.292Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M621.083 679.319L665.493 665.107L641.216 587.537L596.213 601.156L621.083 679.319Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M593.253 503.452L631.742 492.201L658.98 582.206L620.491 593.457L593.253 503.452Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M665.494 665.105L704.575 653.262L688.588 573.323L641.217 587.535L665.494 665.105Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M679.109 476.803L688.583 573.321L658.976 582.204L631.738 492.198L679.109 476.803Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M532.262 705.961L576.672 692.934L552.394 614.771L507.984 628.391L532.262 705.961Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M486.662 718.396L532.849 705.369L507.979 628.391L464.753 640.826L486.662 718.396Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M465.349 640.826L503.246 629.575L475.415 538.386L437.518 550.821L465.349 640.826Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1878_6608" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1878_6608" transform="matrix(0.00166555 0 0 0.00215869 -3.68901 -2.28196)"/>
        </pattern>
        <image id="image0_1878_6608" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}