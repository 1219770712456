import SelectLand from "./components/SelectLand";
import { Wizard } from 'react-use-wizard';
import UploadContracts from "./components/UploadContracts";
import LinkPayment from "./components/LinkPayment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reset } from "../../../features/userSlice/paymentSlice";

const SalesWizard = ({toggle, toggleHandler}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reset);
    }, [dispatch, toggle])

    return (
        <Wizard>
            <SelectLand toggle={toggle}/>
            <LinkPayment/>
            <UploadContracts />
        </Wizard>
    )
}

export default SalesWizard