import React from 'react'
import './FuerzaMigrante.css'
import logoFuerzaMigrante from '../../../../../assets/home/images/logo-fuerza-migrante.png'

export default function FuerzaMigrante() {
  return (
    <div className='FuerzaMigrante p-10'>
      <div className='flex flex-col justify-center w-full'>
        <div className='text-center title'>
          Un proyecto en colaboración con:
        </div>
        <div className='flex justify-center pt-4'>
          <img src={logoFuerzaMigrante} alt='Logo Fuerza Migrante' />
        </div>
        <div className='flex justify-center pt-5'>
          <a href='/public-register'>
            <button 
              className='px-10 py-2 text-sm font-bold text-white uppercase rounded-md bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo'
            >
              REGISTRATE AQUÍ
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}
