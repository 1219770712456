import Select from 'react-select';
import { components } from 'react-select';
import row from "../../../src/assets/images/row.svg";
import { Controller } from "react-hook-form";
import './Selector.css';

// Custom dropdown arrow component with row image
const CustomDropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <div style={{
      backgroundImage: 'linear-gradient(90deg, #058CBF 0%, #41CFE3 100.01%)',
      width: '44px',
      height: '40px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <img
      src={row}
      alt="Custom Arrow"
      style={{ width: '20px', height: '20px'}}
    />
    </div>
  </components.DropdownIndicator>
);

// Custom dropdown arrow component with row image
const CustomDropdownIndicatorGreen = (props) => (
  <components.DropdownIndicator {...props}>
    <div style={{
      backgroundImage: 'linear-gradient(90deg, #438D5D 0%, #69D890 100.01%)',
      width: '44px',
      height: '40px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <img
      src={row}
      alt="Custom Arrow"
      style={{ width: '20px', height: '20px'}}
    />
    </div>
  </components.DropdownIndicator>
);

export const Selector = (
  { label, name, options, control, defaultValue, className, disabled, placeholder = null, isArrowGreen = false}
) => {
    const colourStyles = {
      control: (provided, state) => ({
        ...provided,
        padding: '0px',
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
          ...styles,
          backgroundImage: isArrowGreen
            ? isFocused ? 'linear-gradient(90deg, #438D5D 0%, #69D890 100.01%)' : null
            : isFocused ? 'linear-gradient(90deg, #058CBF 0%, #41CFE3 100.01%)' : null,
          color: '#000'
        }
      },
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: null,
      })
    };

    return (
      <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select
          components={{ DropdownIndicator: isArrowGreen ? CustomDropdownIndicatorGreen : CustomDropdownIndicator }}
          name={name}
          styles={colourStyles}
          options={options}
          defaultValue={defaultValue}
          className={`select-text ${className}`}
          isClearable={!disabled}
          isSearchable
          onInputChange={(inputValue) => inputValue}
          onChange={(selectedOption) => {
            field.onChange(selectedOption);
          }}
          isOptionDisabled={disabled}
          isDisabled={disabled}
          placeholder={placeholder}
        />
      )}
      />
    )
}