import React, { useState } from 'react'
import "./ResetPassword.css"
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Loading from '../../../../../components/loading/Loading';
import zihuaLogo from '../../../../../assets/images/zihua-logo.svg'
import { useAuth } from '../../../../../hooks/useAuth';
import { ToastContainer, toast } from "react-toastify";
import LangButtons from '../../../../../components/langButtons/LangButtons';

export default function ResetPassword() {

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { resetPassword } = useAuth();
  const navigate = useNavigate();
 
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({});

  // Handle login submit
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const params = {
      token: searchParams.get('token'),
      phone: searchParams.get('phone'),
      password: data.password,
      password_confirmation: data.passwordConfirm
    };
    const response = await resetPassword(params);
    setLoading(false);
    if (response.data && response.data.success === 0) {
      toast.error(t('resetPasswordPage.errorMessage'));
      return;
    }
    toast.success(t('resetPasswordPage.successMessage'));
    setTimeout(() => {
      navigate('/login');  
    }, 1500);
  });

  return (
    <div className='ResetPassword'>
      { loading && <Loading /> }
      <div className='flex justify-end pt-10 pr-10'>
        <LangButtons />
      </div>
      <div className='flex justify-center items-center w-screen h-screen bg-secondary'>
        <div className='w-8/12 md:6/12 p-8'>
          <div className='w-full flex justify-center'>
            <img src={zihuaLogo} alt='Zihua Logo' className='w-24' />
          </div>
          <form
            onSubmit={onSubmit}
            className='mt-10'
          >
            <div>
              <label className='text-light'>
                { t('resetPasswordPage.newPassword') }
              </label>
              <br />
              <input
                type='password'
                className='border border-tertiary p-2 rounded-md w-full'
                {
                  ...register(
                    'password', {
                      required: {
                        value: true,
                        message: t('resetPasswordPage.errors.newPassword.required')
                      }
                    }
                  )
                }
              />
              {
                errors.password && 
                <div className='error-message'>
                  {errors.password.message}
                </div>
              }
            </div>
            <div className='mt-2'>
              <label className='text-light'>
                { t('resetPasswordPage.passwordConfirm') }
              </label>
              <br />
              <input
                type='password'
                className='border border-tertiary p-2 rounded-md w-full'
                {
                  ...register(
                    'passwordConfirm', {
                      required: {
                        value: true,
                        message: t('resetPasswordPage.errors.passwordConfirm.required')
                      }
                    }
                  )
                }
              />
              {
                errors.passwordConfirm && 
                <div className='error-message'>
                  {errors.passwordConfirm.message}
                </div>
              }
            </div>
            <div className='mt-4 flex justify-center w-full'>
              <div className='w-2/5'>
                <button
                  className='rounded-md text-white p-2 w-full bg-gradient-to-r from-btnPrimaryFrom to-btnPrimaryTo'
                  type='submit'
                >
                  {t('global.send')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
