import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import "./DashboardSuperAdmin.css"
import { useTranslation } from 'react-i18next';
import UsersTable from '../usersTable/UsersTable';
import { roles } from '../../../../../constants/roles';
import LineChart from '../../../../../components/charts/lineChart/LineChart';
import Modal from '../../../../../components/modal/Modal';
import Register from '../../../register/Register';
import { getChartsInfo, getClientsChart, getLandSales, getPaymentsDashboard, getSalesChart } from '../../../../../services/dashboardService';
import { PrimaryButton } from '../../../../../components/primary/PrimaryButton';
import { Selector } from '../../../../../components/selector/Selector';
import { useForm } from 'react-hook-form';

export default function DashboardSuperAdmin() {

  const [showForm, setShowForm] = useState(false);
  const [random, setRandom] = useState(0);
  const [chartsInfo, setChartsInfo] = useState([]);
  const [first, setFirst] = useState(true);

  const { t, i18n } = useTranslation();
  const [chartValues, setChartValues] = useState({
    sales: [],
    lands: [],
    clients: [],
    payments: []
  });
  const ref = useRef(null);
  const currentDate = new Date();

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, index) => {
      const monthValue = index + 1;
      return {
        label: new Date(2023, index, 1).toLocaleString(i18n.language === 'es' ? i18n.language : 'default', { month: 'long' }),
        value: monthValue,
      };
    });
  }, []);

  const {
    control,
    handleSubmit,
    watch
  } = useForm({defaultValues: {month: currentDate.getMonth() + 1}});

  const selectedMonth = watch('month');

  const submit = useCallback(async (data) => {
    // Handle form submission here
    try {
      const params = {month: data.month?.value ? data.month?.value : data.month};

      const responseSales = await getSalesChart(params);
      const responseLands = await getLandSales(params);
      const responseClients = await getClientsChart(params);
      const responsePayments = await getPaymentsDashboard(params);

      setChartValues({
        ...chartValues,
        sales: responseSales.data,
        lands: responseLands.data,
        clients: responseClients.data,
        payments: responsePayments.data
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  /**
   * Handle add user to show form
   */
  const handleAdd = useCallback(() => {
    setShowForm(true);
  }, []);

  /**
   * Handle close modal and reload users list
   */
  const handleCloseModal = useCallback(() => {
    setShowForm(false);
  }, []);

  /**
   * Handle success in form
   */
  const handleSuccess = useCallback(() => {
    setShowForm(false);
  }, []);

  const getChartsData = useCallback(async () => {
    const response = await getChartsInfo();
    console.log('response en getChartsData: ', response);
    setChartsInfo(response.data);
  }, []);

  useEffect(() => {
    if (first) {
      setFirst(false);
      setRandom(Math.random());
      getChartsData();
    }
  }, [first, getChartsData]);

  useEffect(() => {
    if (selectedMonth) {
      handleSubmit(submit)();
    }
  }, [selectedMonth, handleSubmit, submit]);

  return (
    <div className='p-5 DashboardSuperAdmin' ref={ref}>
      <h3 className='mt-8 title'>
        { t('dashboardPage.superAdmin.title') }
      </h3>
      <div className='pl-0 pr-0 mt-5 lg:pl-4 lg:pr-4'>
        <div className='flex flex-col lg:flex-row items-center justify-center gap-3 lg:justify-start'>
        <div className='w-full lg:w-1/5'>
          <form>
            <Selector label='' name={'month'} defaultValue={months.filter(r => r.value === currentDate.getMonth() + 1)[0]} control={control} options={months} />
          </form>
        </div>
          <PrimaryButton onClick={handleAdd} >
            { t('dashboardPage.superAdmin.newAdmin') }
          </PrimaryButton>
        </div>
        <div className='grid grid-cols-1 mt-5 lg:grid-cols-3 lg:gap-2'>
          <div className='mt-4 lg:mt-0'>
            <LineChart
              title={'Ventas totales'}
              bgColor1={'rgba(146, 224, 235, 1)'}
              bgColor2={'rgba(34, 159, 176, 1)'}
              chartData={chartValues?.sales}
            />
          </div>
          <div className='mt-4 lg:mt-0'>
            <LineChart
              title={'Terrenos vendidos'}
              bgColor1={'rgba(252, 227, 138, 0.69)'}
              bgColor2={'rgba(255, 147, 47, 0.80)'}
              chartData={chartValues?.lands}
            />
          </div>
        </div>
        <div className='grid grid-cols-1 mt-5 lg:grid-cols-3 lg:gap-2'>
          <div className='mt-4 lg:mt-0'>
            <LineChart
              title={'Clientes Totales'}
              bgColor1={'rgba(146, 224, 235, 1)'}
              bgColor2={'rgba(34, 159, 176, 1)'}
              chartData={chartValues?.clients}
            />
          </div>
          <div className='mt-4 lg:mt-0'>
            <LineChart
              title={'Pagos'}
              bgColor1={'rgba(252, 227, 138, 0.69)'}
              bgColor2={'rgba(255, 147, 47, 0.8)'}
              chartData={chartValues?.payments}
            />
          </div>
        </div>
        <div className='pr-4'>
          <UsersTable
            role={roles.ADMIN}
            random={random}
            onScroll={scrollToTop}
          />
        </div>
      </div>
      {
        showForm &&
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
          title={t('registerPage.add')}
        >
          <Register
            onSuccess={handleSuccess}
          />
        </Modal>
      }
    </div>
  )
}
