export const Manzana27Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1218" height="941" viewBox="0 0 1218 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1218" height="941" fill="url(#pattern0_1881_9573)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('15'))} d="M358.645 592.158L419.372 602.734L427.219 567.936L374.338 579.535L358.645 592.158Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M530.252 580.559L557.886 585.676L568.463 536.207L538.781 542.689L530.252 580.559Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M514.217 620.475L545.263 626.616L554.474 584.994L523.428 579.194L514.217 620.475Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M445.641 563.843L530.25 580.56L538.779 542.69L445.641 563.843Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('12'))} d="M483.511 613.994L514.216 620.476L523.427 579.195L492.381 573.054L483.511 613.994Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('13'))} d="M451.784 608.192L483.513 613.992L492.383 573.052L460.996 566.911L451.784 608.192Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('14'))} d="M419.372 602.394L451.783 608.193L460.994 566.912L445.983 563.842L427.219 567.936L419.372 602.394Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M639.083 646.062L647.954 605.805L616.907 598.981L608.037 638.557L639.083 646.062Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M592.003 545.76L619.637 563.842L612.473 597.958L582.45 591.135L592.003 545.76Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M577.331 632.074L608.036 638.556L616.906 598.981L585.519 591.816L577.331 632.074Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M574.26 534.501L592 545.759L582.107 591.135L557.884 585.676L568.46 536.207L574.26 534.501Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M545.262 626.615L577.673 632.415L585.86 592.158L554.473 584.993L545.262 626.615Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M670.127 652.203L725.737 665.509C720.278 657.048 692.075 626.729 678.656 612.628L670.127 652.203Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M639.083 645.721L670.129 652.203L678.658 612.287L647.612 605.805L639.083 645.721Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M619.634 563.842L678.656 612.287L612.47 597.958L619.634 563.842Z" fill="#0096B3" fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1881_9573" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1881_9573" transform="matrix(0.000960409 0 0 0.00124375 -1.82711 -0.604353)"/>
        </pattern>
        <image id="image0_1881_9573" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}