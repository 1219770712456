export const Manzana7Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <rect width="1219" height="941" fill="url(#pattern0_1868_442)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M479.673 426.861L518.305 436.958L496.355 489.638L459.918 479.541L479.673 426.861Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('3'))}
            d="M557.815 447.935L595.129 461.982L572.741 514.662L534.548 501.492L557.815 447.935Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M518.304 436.521L557.813 447.934L534.547 501.053L496.354 489.639L518.304 436.521Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('4'))}
            d="M595.129 461.538L631.565 476.903L609.615 530.46L572.74 514.657L595.129 461.538Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('6'))}
            d="M678.978 502.811L704.879 582.708L646.492 548.466L668.003 496.226L678.978 502.811Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('5'))}
            d="M609.618 530.46L646.493 548.459L668.443 496.657L631.567 476.902L609.618 530.46Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('12'))}
            d="M441.921 523.441L488.016 534.416L505.136 492.272L459.481 479.541L441.921 523.441Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('11'))}
            d="M488.017 534.417L534.112 548.465L552.989 506.76L505.138 492.273L488.017 534.417Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('7'))}
            d="M704.439 582.707L726.828 657.776L667.125 613.876L686.001 571.732L704.439 582.707Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('8'))}
            d="M624.982 588.85L667.565 613.872L686.002 571.729L643.42 546.706L624.982 588.85Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('9'))}
            d="M579.766 566.901L624.983 588.851L643.421 546.707L598.643 525.196L579.766 566.901Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('10'))}
            d="M552.987 506.76L598.642 525.198L579.765 566.902L534.11 548.464L552.987 506.76Z" 
            fill="#0096B3" 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1868_442" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1868_442" transform="matrix(0.00123479 0 0 0.00160039 -3.09766 -2.16525)"/>
        </pattern>
        <image id="image0_1868_442" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}