import api from '../api/axios'
import { endpoints } from '../api/endpoints'

export const getNotifications = async (params) => {
    try {
        return await api.get(endpoints.notification, {params});
    } catch (error) {
        return error.message;
    }
};

export const getAllNotifications = async (page, sort, order, status) => {
    try {
        return await api.post(endpoints.allNotifications + `?page=${page}&sort=${sort}&order=${order}`, {status});
    } catch (error) {
        return error.message;
    }
};
